<template>

    <v-card :loading="loading" flat class="main-color" dark>

        <v-card-title  class="subtitle-2">
            <v-row>
                <v-col>Reporte Maestro</v-col>
            </v-row>
        </v-card-title>

        <v-card-text class="custom-scroll row">
            <v-col cols="12" md="2">
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-col cols="12">
                        <v-select
                            dense
                            outlined
                            filled
                            label="Tipo Reporte"
                            v-model="form.reportType"
                            :items="reports"
                            :rules="[rules.required]"
                            hide-details
                            return-object
                        ></v-select>
                    </v-col>

                    <v-col cols="12">
                        <PresenceCalendar @onUpdateDate="setDay($event)"></PresenceCalendar>
                    </v-col>

                    <v-col cols="12">
                        <v-btn color="info" @click="getReport()"><v-icon left>mdi-clipboard-text-outline</v-icon> Ver</v-btn>
                    </v-col>

                </v-form>
            </v-col>
            <v-col cols="12" md="10">

                <v-text-field
                    v-if="items.length > 0"
                    v-model="search"
                    append-icon="search"
                    label="Buscar"
                    hide-details
                    clearable
                    dense
                ></v-text-field>

                <v-data-table
                    v-if="items.length > 0"
                    :headers="headers"
                    :items  ="items"
                    :search ="search"
                    item-key="id"
                    :loading="loading"
                    width="100%"
                >
    
                    <template v-slot:item="{ item }">
                        <tr>
                            <td v-for="(header, index) in headers" v-bind:key="index" :class="item[header.value].classes">
                                {{ item[header.value].value }}
                            </td>
                        </tr>
                    </template>
    
                </v-data-table>
            </v-col>
        
        </v-card-text>

        <pre v-if="$App.debug">{{ $data }}</pre>

    </v-card>
    
  
</template>

<script>
import AppData from '@mixins/AppData'
import PresenceCalendar from './components/PresenceCalendar.vue'
export default {

    mixins: [AppData],
    components: { 
        PresenceCalendar
    },

    data() {
        return {
            start_at: new Date().toISOString().substr(0, 10),
            end_at:  new Date().toISOString().substr(0, 10),
            compare_start_at: new Date().toISOString().substr(0, 10),
            compare_end_at:  new Date().toISOString().substr(0, 10),
            form: {
                reportType: null,
                date:       null,
                date2:       null,
                isDdetail:  null,
                type:       'summary',
            },
            reports: [
                { value: 'profile', text: 'Rentabilidad por Perfil',  url: 'profileProfitability'  },
            ],
            headers: [],
        }
    },

    methods:
    {
        getReport()
        {
            if (!this.$refs.form.validate())  return 
            this.getResource(`${this.form.reportType.url}?start_at=${this.form.date}&end_at=${this.form.date2}`).then( data => {
                this.items = data.items
                this.headers = data.headers
                this.test = data.test
            })
        },
        setDay(days)
        {
            if(Array.isArray(days))
            {
                this.start_at = days[0]
                this.end_at   = days[1]
            } else {
                this.start_at = days
                this.end_at   = days
            }
            if(this.start_at > this.end_at){
                let a = this.end_at
                this.end_at = this.start_at
                this.start_at = a
            }
            this.form.date = this.start_at
            this.form.date2 = this.end_at
        },

        setCompare(days)
        {          
            if(Array.isArray(days))
            {
                this.compare_start_at = days[0]
                this.compare_end_at   = days[1]
            } else {
                this.compare_start_at = days
                this.compare_end_at   = days
            }
        },

      
    }
}
</script>

<style>

</style>