var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6", lg: "4" } },
            [
              _c(
                "v-card",
                {
                  staticClass: "rounded-lg",
                  attrs: { color: "rgba(0,0,0,0.4)" },
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c("v-row", [_c("v-col", [_c("Userboard")], 1)], 1),
                      _vm._v(" "),
                      _c("v-row", [_c("v-col", [_c("AppDashBoard")], 1)], 1),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6", lg: "4" } },
            [_c("ListProfiles")],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "6", lg: "4" } },
            [_c("TableProfile")],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("div"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }