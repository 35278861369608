<template>


    <v-card dark class="rounded-lg " color="rgba(0,0,0,0.4)" min-height="91vh" v-if="profile">
        <v-card-title primary-title>
            <v-row dense>
                <v-col>{{profile.name}}</v-col>
                <v-col cols="auto"><v-btn icon color="success"><v-icon>mdi-dots-vertical</v-icon></v-btn></v-col>
            </v-row>
        </v-card-title>
        <v-card-text>

            <v-row>
                <v-col cols="auto">
                    <v-avatar color="blue" size="100" class="elevation-2">
                        <v-img :src="`/storage/photo/profile/${profile.photo || 'nophoto.png'}`" ></v-img>
                    </v-avatar>
                </v-col>
                <v-col>
                        <v-row no-gutters>
                        <v-col cols="5" class="subtitle-2">Edad</v-col>
                        <v-col cols="7" class="font-weight-light">{{profile.age}}</v-col>
                        <v-col cols="5"  class="subtitle-2">Cumpleaños</v-col>
                        <v-col cols="7" class="font-weight-light">{{profile.birthday}}</v-col>
                        <v-col cols="5"  class="subtitle-2">Ciudad</v-col>
                        <v-col cols="7" class="font-weight-light">{{profile.city}}</v-col>
                        <v-col cols="5"  class="subtitle-2">País</v-col>
                        <v-col cols="7" class="font-weight-light">{{profile.country}}</v-col>
                        <v-col cols="5"  class="subtitle-2">Género</v-col>
                        <v-col cols="7" class="font-weight-light">{{profile.gender}}</v-col>
                        <v-col cols="5"  class="subtitle-2">Comentarios</v-col>
                        <v-col cols="7" class="font-weight-light">{{profile.comments}}</v-col>
                        <v-col cols="5" class="subtitle-2">Amolatina ID</v-col>
                        <v-col cols="7" class="font-weight-light">{{profile.amolatina_id}}</v-col>
                    </v-row>
                </v-col>
            </v-row>        

        </v-card-text>
    </v-card>
    
  
</template>

<script>
import AppData from '@mixins/AppData' 
export default {
 mixins:[ AppData],
    
    created()
    {
        this.list()
    },

    data: () => ({
        profile: null,
        isReload: null   
    }),

    methods:
    {
        list()
        {
            this.getResource(`profile/${this.$route.params.profile_id}`).then( data => {
                this.profile = data;
            })
        }
    }

}
</script>

<style>

</style>