var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "main-color", attrs: { flat: "", dark: "" } },
    [
      _c(
        "v-card-title",
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c("v-col", [_vm._v("Conexiones del Dia")]),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", "x-small": "", dark: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closeDialog")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close-circle")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        [
          _c(
            "v-expansion-panels",
            { staticClass: "card-background" },
            [
              _c(
                "v-row",
                { staticClass: "text-center" },
                [
                  _c("v-col", [_vm._v("\n            Inicio\n        ")]),
                  _vm._v(" "),
                  _c("v-col", [_vm._v("\n            Fin\n        ")]),
                  _vm._v(" "),
                  _c("v-col", [_vm._v("\n            Bonus\n        ")]),
                  _vm._v(" "),
                  _c("v-col", [_vm._v("\n            WriteOff\n        ")]),
                  _vm._v(" "),
                  _c("v-col", [_vm._v("\n          Status\n        ")]),
                ],
                1
              ),
              _vm._v(" "),
              _vm._l(_vm.presences, function (presence) {
                return _c(
                  "v-expansion-panel",
                  { key: presence.id },
                  [
                    _c(
                      "v-expansion-panel-header",
                      [
                        _c(
                          "v-row",
                          [
                            _c("v-col", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    _vm.UTCToLocalDate(presence.start_at)
                                  ) +
                                  " \n            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("v-col", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(
                                    presence.status_id == 4
                                      ? _vm.UTCToLocalDate(presence.end_at)
                                      : "activo"
                                  ) +
                                  "\n            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("v-col", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(_vm.formatNumber(presence.bonus)) +
                                  "\n            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c("v-col", [
                              _vm._v(
                                "\n                " +
                                  _vm._s(parseInt(presence.writeoff)) +
                                  "\n            "
                              ),
                            ]),
                            _vm._v(" "),
                            _c(
                              "v-col",
                              [
                                presence.status_id == 4
                                  ? _c(
                                      "v-icon",
                                      { attrs: { color: "green" } },
                                      [_vm._v("mdi-checkbox-marked-outline")]
                                    )
                                  : _c(
                                      "v-icon",
                                      {
                                        staticClass: "mdi-spin pointer",
                                        attrs: { color: "amber" },
                                      },
                                      [_vm._v("mdi-cog-clockwise")]
                                    ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-expansion-panel-content",
                      [
                        _c("ComissionPresence", {
                          attrs: { presence: presence },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }