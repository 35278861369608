<template>
    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="6" lg="4">
                <v-card color="rgba(0,0,0,0.4)" class="rounded-lg ">
                    <v-card-text>
                        <v-row>
                            <v-col>
                                <Userboard></Userboard>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <AppDashBoard></AppDashBoard>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>

            </v-col>
            <v-col cols="12" md="6" lg="4">
                <ListProfiles></ListProfiles>
            </v-col>
            <v-col cols="12" md="6" lg="4">
                <TableProfile></TableProfile>
            </v-col>
        </v-row>
        <!-- vue-advanced-chat v-if="toggle" class="test" theme="dark" :single-room="true" :current-user-id="currentUserId"
            :rooms="JSON.stringify(rooms)" :rooms-loaded="true" :room-actions="roomactions" :room-info-enabled="true"
            @room-info="(e) => roomInfoHandler(e)"/> -->
        <div></div>
    </v-container>



</template>

<script>
import AppData from '@mixins/AppData'
import Userboard from './components/UserBoard'
import ListProfiles from './components/ListProfiles'
import TableProfile from './components/TableProfile'
import AppDashBoard from './components/AppDashBoard'
export default {

    name: 'operator-board',

    mixins: [AppData],

    components: {
        Userboard,
        AppDashBoard,
        ListProfiles,
        TableProfile
    },


    data: () => ({
        /* rooms: [{
            roomId: 123,
            roomName: 'Admin',
            users: [{ _id: 123, username: 'Administrador' }],
            avatar: '/storage/photo/operator/nophoto.png',
            messages: [],
            messagesLoaded: false
        }],
        roomactions: [
            {
                name: 'minimize',
                title: 'Minimizar'
            }
        ],
        currentUserId: 123,
        toggle: false */

    }),
    methods: {
        /* roomInfoHandler(e) {
            console.log(e);
        },
        setToggle() {
            this.toggle = true
        } */
    }
}
</script>

<style>
.test {
    position: absolute;
    z-index: 9999;
    bottom: 10px;
}
</style>