<template>

    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="6" lg="4">
                <ClientBoard></ClientBoard>
            </v-col>
            <v-col cols="12" md="6" lg="4">
                <Profileboard></Profileboard>
            </v-col>
            <v-col cols="12" md="6" lg="4">
                <Chat />
            </v-col>
        </v-row>
    </v-container>
  
</template>

<script>
import AppData from '@mixins/AppData' 
import ClientBoard from './components/ClientBoard'
import Profileboard from './components/ProfileBoard'
import Chat from './components/ChatTest'
export default {

    name: 'profile-board',

    mixins:[ AppData],

    components:{
        ClientBoard,
        Profileboard,
        Chat
    },


    data: () => ({
        
    })
}
</script>

<style>

</style>