var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-autocomplete", {
                        attrs: {
                          items: _vm.countries,
                          rules: [_vm.rules.select],
                          "item-value": "code",
                          "item-text": "name",
                          label: "Country",
                          "return-object": "",
                          "hide-details": "",
                          outlined: "",
                          filled: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.country,
                          callback: function ($$v) {
                            _vm.country = $$v
                          },
                          expression: "country",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.sexOptions,
                          label: "Sex",
                          "item-text": "name",
                          "hide-details": "",
                          rules: [_vm.rules.select],
                          "return-object": "",
                          outlined: "",
                          filled: "",
                          dense: "",
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function ({ item }) {
                              return [
                                _c(
                                  "v-row",
                                  {
                                    staticClass: "grey lighten-5 subtitle-1",
                                    attrs: { dense: "" },
                                  },
                                  [
                                    _c("v-col", [
                                      _vm._v(
                                        "\n                        " +
                                          _vm._s(item.name) +
                                          "\n                    "
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "auto" } },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: item.color } },
                                          [_vm._v(_vm._s(item.icon))]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "v-col",
                                      { attrs: { cols: "auto" } },
                                      [
                                        _c(
                                          "v-icon",
                                          { attrs: { color: item.color_alt } },
                                          [_vm._v(_vm._s(item.icon_alt))]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                        model: {
                          value: _vm.sex,
                          callback: function ($$v) {
                            _vm.sex = $$v
                          },
                          expression: "sex",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", { staticClass: "mt-1 ml-1 title" }, [
                    _vm._v("\n              Edad:\n            "),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.agesFrom,
                          rules: [_vm.rules.select],
                          "hide-details": "",
                          outlined: "",
                          filled: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.ageFrom,
                          callback: function ($$v) {
                            _vm.ageFrom = $$v
                          },
                          expression: "ageFrom",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "mt-2", attrs: { cols: "auto" } },
                    [_vm._v("\n              -\n            ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "4" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.agesTo,
                          rules: [_vm.rules.select],
                          "hide-details": "",
                          outlined: "",
                          filled: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.ageTo,
                          callback: function ($$v) {
                            _vm.ageTo = $$v
                          },
                          expression: "ageTo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-select", {
                        attrs: {
                          items: _vm.resultsSel,
                          label: "Resultados",
                          "hide-details": "",
                          rules: [_vm.rules.select],
                          outlined: "",
                          filled: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.results,
                          callback: function ($$v) {
                            _vm.results = $$v
                          },
                          expression: "results",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            depressed: "",
                            block: "",
                            dark: "",
                            color: "blue darken-4",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.searchPeople()
                            },
                          },
                        },
                        [_vm._v("Buscar Clientes")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }