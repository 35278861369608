var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "rounded-lg main-color", attrs: { dark: "" } },
    [
      _c(
        "v-subheader",
        { staticClass: "subtitle-1" },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "auto" } }, [
                _vm._v("\n              Asignar Perfiles\n          "),
              ]),
              _vm._v(" "),
              _c("v-col", [
                _vm._v(
                  "\n              Operador: " +
                    _vm._s(_vm.user.full_name) +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", "x-small": "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closeDialog")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close-circle")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-card-text",
        { staticClass: "pt-2 accounts-container custom-scroll" },
        [
          _c("v-autocomplete", {
            attrs: {
              label: "Perfiles",
              "item-text": "name",
              "no-data-text": "No existen perfiles Disponibles",
              items: _vm.profiles,
              disabled: _vm.loading,
              loading: _vm.loading,
              "hide-details": "",
              outlined: "",
              filled: "",
              dense: "",
              clearable: "",
              "open-on-clear": "",
              "item-value": "id",
              "return-object": "",
            },
            on: {
              change: function ($event) {
                return _vm.assing($event)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-row",
                      {
                        staticClass: "grey lighten-5 subtitle-1",
                        attrs: { dense: "" },
                      },
                      [
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c(
                              "v-avatar",
                              {
                                staticClass: "elevation-3",
                                attrs: { color: "grey", size: 30 },
                              },
                              [
                                _c("v-img", {
                                  attrs: {
                                    src: `/storage/photo/profile/${
                                      item.photo || "nophoto"
                                    }.jpg`,
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("v-col", [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.name) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c("v-col", [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.agency.name) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("list-icon", {
                              attrs: { data: _vm.sexIcons, value: item.gender },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("list-simple-icon", {
                              attrs: {
                                icon: "mdi-plus-circle",
                                label: "Agregar",
                                color: "success",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.profile,
              callback: function ($$v) {
                _vm.profile = $$v
              },
              expression: "profile",
            },
          }),
          _vm._v(" "),
          _c(
            "v-list",
            {
              staticClass: "rounded-lg mt-1",
              attrs: { dense: "", color: "rgba(0,0,0,0.4)" },
            },
            _vm._l(_vm.userProfiles, function (userProfile) {
              return _c(
                "v-list-item",
                { key: userProfile.id },
                [
                  _c(
                    "v-list-item-avatar",
                    { attrs: { color: "blue", size: "30" } },
                    [
                      _c("v-img", {
                        attrs: {
                          src: `/storage/photo/profile/${
                            userProfile.photo || "nophoto"
                          }.jpg`,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(userProfile.name) +
                                    "\n                  "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      rules: [_vm.rules.minNum(1)],
                                      label: "Meta Dia",
                                      type: "number",
                                      dense: "",
                                      filled: "",
                                      outlined: "",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: userProfile.goal_day,
                                      callback: function ($$v) {
                                        _vm.$set(userProfile, "goal_day", $$v)
                                      },
                                      expression: "userProfile.goal_day",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      rules: [_vm.rules.minNum(1)],
                                      label: "Meta Mes",
                                      type: "number",
                                      dense: "",
                                      filled: "",
                                      outlined: "",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: userProfile.goal_month,
                                      callback: function ($$v) {
                                        _vm.$set(userProfile, "goal_month", $$v)
                                      },
                                      expression: "userProfile.goal_month",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    icon: "",
                                    small: "",
                                    loading: _vm.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.update(userProfile)
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { color: "amber" } }, [
                                    _vm._v("mdi-lead-pencil"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    icon: "",
                                    small: "",
                                    loading: _vm.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.remove(userProfile)
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { color: "red" } }, [
                                    _vm._v("mdi-delete"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }