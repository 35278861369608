var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "holder-404 d-flex justify-center align-center white--text",
      on: {
        click: function ($event) {
          return _vm.$router.replace("/")
        },
      },
    },
    [
      _c(
        "div",
        { staticClass: "text-center" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c(
            "v-btn",
            { attrs: { icon: "", fab: "", "x-large": "", color: "white" } },
            [
              _c("v-icon", { attrs: { size: "80" } }, [
                _vm._v("mdi-home-export-outline"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("h3", { staticClass: "my-3" }, [
      _c("span", { staticClass: "display-4 mr-5" }, [_vm._v("404")]),
      _c("span", { staticClass: "display-1" }, [
        _vm._v("PAGINA NO ENCONTRADA"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }