var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c("v-date-picker", {
        staticClass: "rounded-lg",
        attrs: {
          "no-title": "",
          scrollable: "",
          "full-width": "",
          color: "indigo",
          elevation: "3",
          range: _vm.range,
          "first-day-of-week": 1,
          max: _vm.maxDay,
          light: "",
        },
        on: {
          change: function ($event) {
            return _vm.$emit("onUpdateDate", $event)
          },
        },
        model: {
          value: _vm.today,
          callback: function ($$v) {
            _vm.today = $$v
          },
          expression: "today",
        },
      }),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { "no-gutters": "", justify: "center" } },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto" } },
            [
              _c("v-switch", {
                attrs: {
                  color: "blue",
                  dense: "",
                  "hide-details": "",
                  label: "Seleccionar Rango",
                },
                on: {
                  change: function ($event) {
                    _vm.days = null
                  },
                },
                model: {
                  value: _vm.range,
                  callback: function ($$v) {
                    _vm.range = $$v
                  },
                  expression: "range",
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }