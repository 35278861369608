var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-avatar",
    { attrs: { color: "deep-orange", size: "38" } },
    [
      _c("v-file-input", {
        staticClass: "pl-2 mt-n3",
        attrs: {
          dark: "",
          accept: "image/*",
          capture: _vm.capture,
          "hide-input": "",
          "prepend-icon": "mdi-image-plus",
          loading: _vm.loading,
          disabled: _vm.disabled,
        },
        on: {
          change: function ($event) {
            return _vm.setImage()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "selection",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.imageUpload,
          callback: function ($$v) {
            _vm.imageUpload = $$v
          },
          expression: "imageUpload",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }