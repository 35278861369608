var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.confirm,
        persistent: "",
        "max-width": "400",
        "content-class": "rounded-xl",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("app-simple-toolbar", {
            attrs: { title: _vm.title },
            on: {
              closeModal: function ($event) {
                return _vm.$emit("closeConfirm", false)
              },
            },
          }),
          _vm._v(" "),
          _c(
            "v-alert",
            {
              staticClass: "mt-3 mx-3",
              attrs: {
                type: "info",
                icon: "mdi-comment-question",
                prominent: "",
                outlined: "",
              },
            },
            [_vm._v("\n        " + _vm._s(_vm.message) + "\n    ")]
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "error",
                                attrs: { fab: "", "x-small": "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("closeConfirm", false)
                                  },
                                },
                              },
                              on
                            ),
                            [_c("v-icon", [_vm._v("reply")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_vm._v(" "), _c("span", [_vm._v("Cancelar")])]
              ),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "success ml-2",
                                attrs: { fab: "", small: "" },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("closeConfirm", true)
                                  },
                                },
                              },
                              on
                            ),
                            [_c("v-icon", [_vm._v("mdi-check-bold")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_vm._v(" "), _c("span", [_vm._v("Confirmar")])]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }