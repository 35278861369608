var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: { bottom: "", left: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    staticClass: "ml-3",
                    attrs: {
                      small: "",
                      color: _vm.iconColor,
                      fab: "",
                      depressed: "",
                    },
                  },
                  on
                ),
                [
                  _c("v-icon", { attrs: { color: "white" } }, [
                    _vm._v("mdi-dots-vertical"),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "v-list",
        { attrs: { dense: "", nav: "" } },
        _vm._l(_vm.itemsMenu, function (menu, i) {
          return _c(
            "v-list-item",
            {
              key: i,
              class: menu.class,
              attrs: { tile: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("onMenu", {
                    action: menu.action,
                    item: _vm.item,
                  })
                },
              },
            },
            [
              _c(
                "v-list-item-action",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "grey lighten-4",
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("onMenu", {
                            action: menu.action,
                            item: _vm.item,
                          })
                        },
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { attrs: { color: menu.iconColor || "indigo" } },
                        [_vm._v(_vm._s(menu.icon))]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-list-item-title", [
                _vm._v(
                  "\n                " + _vm._s(menu.label) + "\n            "
                ),
              ]),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }