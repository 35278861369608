var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "book-container", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { staticClass: "fill-height", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "grey lighten-4 text-center rounded-lg" },
            [
              _c("v-slider", {
                staticClass: "mb-n7 mt-n3 px-2 pointer",
                attrs: {
                  step: "2",
                  max: _vm.lastPage >= 2 ? _vm.lastPage - 2 : _vm.lastPage,
                  min: "0",
                  ticks: "",
                  "tick-size": "24",
                },
                model: {
                  value: _vm.currentPage,
                  callback: function ($$v) {
                    _vm.currentPage = $$v
                  },
                  expression: "currentPage",
                },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "book-holder" },
                [
                  _c("canvas", {
                    ref: "book",
                    staticClass: "rounded-lg",
                    class: _vm.cursor,
                    attrs: { id: "book" },
                    on: {
                      click: function ($event) {
                        return _vm.setZoom($event)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-speed-dial",
                    {
                      staticClass: "mr-5 mt-5",
                      attrs: {
                        top: "",
                        right: "",
                        direction: "bottom",
                        transition: "scale-transition",
                        fixed: "",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function () {
                            return [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    dark: "",
                                    color: "blue",
                                    small: "",
                                    fab: "",
                                  },
                                  model: {
                                    value: _vm.tools,
                                    callback: function ($$v) {
                                      _vm.tools = $$v
                                    },
                                    expression: "tools",
                                  },
                                },
                                [
                                  _vm.tools
                                    ? _c("v-icon", [_vm._v("mdi-close")])
                                    : _c("v-icon", [_vm._v("mdi-tools")]),
                                ],
                                1
                              ),
                            ]
                          },
                          proxy: true,
                        },
                      ]),
                      model: {
                        value: _vm.tools,
                        callback: function ($$v) {
                          _vm.tools = $$v
                        },
                        expression: "tools",
                      },
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            fab: "",
                            dark: "",
                            small: "",
                            color: "orange",
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-bookmark-plus")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            fab: "",
                            dark: "",
                            small: "",
                            color: "green",
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-lead-pencil")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            fab: "",
                            dark: "",
                            small: "",
                            color: "indigo",
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-format-annotation-plus")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-btn",
                        {
                          attrs: { fab: "", dark: "", small: "", color: "red" },
                        },
                        [_c("v-icon", [_vm._v("mdi-eraser")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-14 mt-2",
                      attrs: {
                        color: "success lighten-5",
                        icon: "",
                        fixed: "",
                        left: "",
                        dark: "",
                      },
                    },
                    [
                      _vm.cursor == "pointer"
                        ? _c(
                            "v-icon",
                            {
                              attrs: { size: "42", color: "green" },
                              on: {
                                click: function ($event) {
                                  return _vm.activeZoom()
                                },
                              },
                            },
                            [_vm._v("mdi-magnify-plus-outline")]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.cursor == "zoom-out"
                        ? _c(
                            "v-icon",
                            {
                              attrs: { size: "42", color: "green" },
                              on: {
                                click: function ($event) {
                                  return _vm.zomOut()
                                },
                              },
                            },
                            [_vm._v("mdi-magnify-minus-outline")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "ml-14 mb-16",
                      attrs: {
                        color: "success",
                        icon: "",
                        fixed: "",
                        left: "",
                        bottom: "",
                        disabled: _vm.loading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.prev()
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { size: "48", color: "green" } }, [
                        _vm._v("mdi-menu-left"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-5 mb-16",
                      attrs: {
                        color: "success",
                        icon: "",
                        fixed: "",
                        right: "",
                        bottom: "",
                        disabled: _vm.loading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.netx()
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { size: "48", color: "green" } }, [
                        _vm._v("mdi-menu-right"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-overlay",
                    {
                      attrs: {
                        absolute: "",
                        color: "amber",
                        opacity: 0.3,
                        value: _vm.loading,
                        "z-index": 10,
                      },
                    },
                    [
                      _c(
                        "v-icon",
                        { staticClass: "mdi-spin", attrs: { size: "40" } },
                        [_vm._v("mdi-loading")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }