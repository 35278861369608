var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "transparent" },
    [
      _c(
        "v-main",
        [
          _c(
            "v-container",
            { staticClass: "mt-n12", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6", lg: "3" } },
                    [
                      _c(
                        "v-card",
                        {
                          staticClass: "rounded-lg",
                          attrs: {
                            color: "rgba(0,0,0,0.4)",
                            "min-height": "90vh",
                          },
                        },
                        [
                          _c(
                            "v-card-text",
                            [
                              _c("v-row", [_c("v-col", [_c("AppUser")], 1)], 1),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [_c("v-col", [_c("AppAgencyTotals")], 1)],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                [_c("v-col", [_c("AppDashBoard")], 1)],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6", lg: "3" } },
                    [_c("AppOperatos")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6", lg: "3" } },
                    [_c("ListProfiles")],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6", lg: "3" } },
                    [_c("AppProfile")],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }