var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "list-container",
    {
      attrs: { title: _vm.title, "head-color": _vm.$App.theme.headList },
      on: {
        onMenu: function ($event) {
          return _vm.onMenu($event)
        },
      },
    },
    [
      _c(
        "template",
        { slot: "HeadTools" },
        [
          _c("add-button", {
            on: {
              insItem: function ($event) {
                return _vm.insertForm()
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-col",
        { attrs: { cols: "12", md: "6" } },
        [
          _c("v-text-field", {
            attrs: {
              "append-icon": "search",
              label: "Buscar",
              "hide-details": "",
              clearable: "",
              dense: "",
            },
            model: {
              value: _vm.search,
              callback: function ($$v) {
                _vm.search = $$v
              },
              expression: "search",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.items,
          search: _vm.search,
          "item-key": "id",
          loading: _vm.loading,
          "sort-by": "",
        },
        scopedSlots: _vm._u([
          {
            key: "item",
            fn: function ({ item }) {
              return [
                _c("tr", [
                  _c("td", [_vm._v(_vm._s(item.name))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(item.code))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(item.traslate))]),
                  _vm._v(" "),
                  _c("td", [_vm._v(_vm._s(item.comments))]),
                  _vm._v(" "),
                  _c(
                    "td",
                    [
                      _c("status-switch", {
                        attrs: {
                          loading: _vm.loading,
                          resource: _vm.resource,
                          item: item,
                        },
                        on: {
                          onChangeStatus: function ($event) {
                            return _vm.changeStatus($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "text-xs-left" },
                    [
                      _c("list-buttons", {
                        on: {
                          update: function ($event) {
                            return _vm.updateForm(item)
                          },
                          delete: function ($event) {
                            return _vm.deleteForm(item)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        "app-modal",
        {
          attrs: {
            modal: _vm.modal,
            "head-color": _vm.$App.theme.headModal,
            title: _vm.title,
          },
          on: {
            closeModal: function ($event) {
              return _vm.closeModal()
            },
          },
        },
        [
          _c("country-form", {
            attrs: { action: _vm.action, item: _vm.item },
            on: {
              closeModal: function ($event) {
                return _vm.closeModal()
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("form-delete", {
        attrs: {
          dialog: _vm.dialog,
          loading: _vm.loading,
          message: "Desea eliminar el Registro Seleccionado?",
        },
        on: {
          deleteItem: function ($event) {
            return _vm.deleteItem()
          },
          deleteCancel: function ($event) {
            return _vm.deleteCancel()
          },
        },
      }),
      _vm._v(" "),
      _vm.$App.debug ? _c("pre", [_vm._v(_vm._s(_vm.$data))]) : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }