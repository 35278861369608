var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list-item",
    { attrs: { value: _vm.client.id } },
    [
      _c(
        "v-list-item-avatar",
        { attrs: { color: "blue", size: "55" } },
        [
          _c("v-img", {
            attrs: {
              src: `/storage/photo/client/${_vm.client.photo || "nophoto"}.jpg`,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-list-item-content",
        [
          _c(
            "v-list-item-title",
            [
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c("v-col", { staticClass: "caption" }, [
                    _vm._v(_vm._s(_vm.client.name)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "caption", attrs: { cols: "auto" } },
                    [_vm._v(_vm._s(_vm.client.amolatina_id))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item-subtitle",
            { staticClass: "pt-2" },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("v-icon", { attrs: { color: "yellow darken-1" } }, [
                        _vm._v("mdi-crown"),
                      ]),
                      _vm._v(_vm._s(_vm.client.crown)),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c("list-icon", {
                        attrs: { data: _vm.sexIcons, value: _vm.client.gender },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-col", { staticClass: "text-left" }, [
                    _vm._v(_vm._s(_vm.formatNumber(_vm.client.points))),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.hasMenu
        ? _c(
            "v-list-item-icon",
            [
              _c("v-icon", { staticClass: "mt-6" }, [
                _vm._v("mdi-dots-vertical"),
              ]),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }