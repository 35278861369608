<template>

    <v-card :loading="loading" flat class="main-color" dark>

        <v-card-title  class="subtitle-2">
            <v-row>
                <v-col>Reporte de Facturación</v-col>
            </v-row>
        </v-card-title>

        <v-card-text class="custom-scroll row">
            <v-col cols="auto" md="2">
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-col cols="12">
                        <v-select
                            dense
                            outlined
                            filled
                            label="Tipo Reporte"
                            v-model="form.reportType"
                            @change="changeReport"
                            :items="reports"
                            :rules="[rules.required]"
                            hide-details
                            return-object
                        ></v-select>
                    </v-col>

                    <v-col cols="12" v-if="hidden">
                        <PresenceCalendar @onUpdateDate="setDay($event)"></PresenceCalendar>
                    </v-col>
                    <v-col cols="12" v-if="!hidden"> Mes Principal </v-col>
                    <v-col cols="12" v-if="!hidden">
                        <v-menu
                            ref="menu"
                            v-model="menu"
                            :close-on-content-click="false"
                            :return-value.sync="form.date"
                            transition="scale-transition"
                            offset-y
                            width="180px"
                            height="100px"
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="form.date"
                                    label="Mes"
                                    prepend-icon="mdi-calendar"
                                    :rules="[rules.required]"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    dense
                                    outlined
                                    filled
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="form.date"
                                type="month"
                                no-title
                                scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                small
                                color="primary"
                                @click="menu = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                small
                                color="primary"
                                @click="$refs.menu.save(form.date)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>
                    <v-col cols="12" v-if="!hidden"> comparar con Mes Anterior</v-col>
                    <v-col cols="12" v-if="!hidden">
                        <v-menu
                            ref="menu2"
                            v-model="menu2"
                            :close-on-content-click="false"
                            :return-value.sync="form.date2"
                            transition="scale-transition"
                            offset-y
                            width="180px"
                            height="100px"
                            min-width="auto"
                        >
                            <template v-slot:activator="{ on, attrs }">
                                <v-text-field
                                    v-model="form.date2"
                                    label="Mes"
                                    prepend-icon="mdi-calendar"
                                    readonly
                                    v-bind="attrs"
                                    v-on="on"
                                    dense
                                    outlined
                                    filled
                                ></v-text-field>
                            </template>
                            <v-date-picker
                                v-model="form.date2"
                                type="month"
                                no-title
                                scrollable
                            >
                            <v-spacer></v-spacer>
                            <v-btn
                                text
                                small
                                color="primary"
                                @click="menu2 = false"
                            >
                                Cancel
                            </v-btn>
                            <v-btn
                                text
                                small
                                color="primary"
                                @click="$refs.menu2.save(form.date2)"
                            >
                                OK
                            </v-btn>
                            </v-date-picker>
                        </v-menu>
                    </v-col>

                   <!--  <v-col cols="12">
                        <v-radio-group
                            v-model="form.type"
                            row
                        >
                            <v-radio
                                label="Resumen"
                                value="summary"
                            />
                            <v-radio
                                label="Detallado"
                                value="detail"
                            />
                        </v-radio-group>
                    </v-col>

                    <v-col cols="12">
                        <v-checkbox label="Comparar con" v-model="compare"></v-checkbox>
                    </v-col>

                    <v-col cols="12">
                        <PresenceCalendar v-if="compare" @onUpdateDate="setCompare($event)"></PresenceCalendar>
                    </v-col> -->

                    <v-col cols="12">
                        <v-btn color="info" @click="getReport()"><v-icon left>mdi-clipboard-text-outline</v-icon> Ver</v-btn>
                    </v-col>

                </v-form>
            </v-col>
            <v-col cols="auto" md="10">

                <v-text-field
                    v-model="search"
                    append-icon="search"
                    label="Buscar"
                    hide-details
                    clearable
                    dense
                ></v-text-field>

                <v-data-table
                    v-if="items.length > 0"
                    :headers="headers"
                    :items  ="items"
                    :search ="search"
                    item-key="id"
                    :loading="loading"
                    :items-per-page="size"
                    :sort-by="sortBy"
                    :sort-desc="sortDesc"
                    width="100%"
                >
    
                    <template v-slot:item="{ item }">
                        <tr>
                            <td v-for="(header, index) in headers" v-bind:key="index" class="text-xs-left">
                                <div v-if="form.reportType.value == 'agency-totals'">
                                    <span>{{ item[header.value]?.split('&')[0] }}</span>
                                    <span v-if="item[header.value]?.split('&')[1]" class="red--text">{{ item[header.value]?.split('&')[1] }}</span>
                                </div>
                                <div v-if="form.reportType.value != 'agency-totals' ">
                                    <span :class="(header.value ==='rate' && item[header.value] <= 95) ? 'red--text' : ''">
                                        {{ item[header.value] }}
                                    </span>
                                </div>
                            </td>
                        </tr>
                    </template>
    
                </v-data-table>
            </v-col>
        
        </v-card-text>

        <pre v-if="$App.debug">{{ $data }}</pre>

    </v-card>
    
  
</template>

<script>
import AppData from '@mixins/AppData'
import PresenceCalendar from './components/PresenceCalendar.vue'
export default {

    mixins: [AppData],
    components: { 
        PresenceCalendar
    },

    data() {
        return {
            start_at: new Date().toISOString().substr(0, 10),
            end_at:  new Date().toISOString().substr(0, 10),
            compare_start_at: new Date().toISOString().substr(0, 10),
            compare_end_at:  new Date().toISOString().substr(0, 10),
            menu: false,
            menu2: false,
            compare: false,
            hidden: false,
            form: {
                reportType: null,
                date:       null,
                date2:       null,
                isDdetail:  null,
                type:       'summary',
            },
            reports: [
                { value: 'operator', text: 'Por Operador',  url: 'user/pay/operator/'  },
                { value: 'coordinator', text: 'Por Coordinador',  url: 'user/pay/coordinator/' },
                { value: 'agency', text: 'Por Agencia',  url: 'user/pay/agency/' },
                { value: 'profile-month', text: 'Por Perfil',  url: 'user/pay/profile/' },
                { value: 'agency-totals', text: 'Totales Por Día Por Agencia',  url: 'user/pay/agency-totals/' },
                { value: 'loss-chats', text: 'Chats en línea (pérdidas)',  url: 'agency/online/statistics/' },
                { value: 'loss-messages', text: 'Mensajes en Chat (pérdidas)',  url: 'agency/offline/statistics/' },
                { value: 'loss', text: 'Pérdidas por Operador',  url: 'agency/loss/statistics/' }
            ],
            size: 10,
            sortBy: '',
            sortDesc: false,
            headers: [],
        }
    },

    methods:
    {
        getReport()
        {
            if (!this.$refs.form.validate())  return 
            this.getResource(`${this.form.reportType.url}${this.form.date}/${this.form.date2}/1`).then( data => {
                this.size = (this.form.reportType.value == 'agency-totals') ? 32 : 10
                this.items = data.items
                this.headers = data.headers
                this.size = data.extra.size ?? 10
                this.sortBy = data.extra.sort ?? ''
                this.sortDesc = data.extra.desc ?? false 
                console.log(data)
            })
        },
        setDay(days)
        {
            if(Array.isArray(days))
            {
                this.start_at = days[0]
                this.end_at   = days[1]
            } else {
                this.start_at = days
                this.end_at   = days
            }
            if(this.start_at > this.end_at){
                let a = this.end_at
                this.end_at = this.start_at
                this.start_at = a
            }
            this.form.date = this.start_at
            this.form.date2 = this.end_at
        },

        setCompare(days)
        {          
            if(Array.isArray(days))
            {
                this.compare_start_at = days[0]
                this.compare_end_at   = days[1]
            } else {
                this.compare_start_at = days
                this.compare_end_at   = days
            }
        },

        changeReport(){
            ['loss-chats','loss-messages', 'loss'].includes(this.form.reportType.value)
                ? this.hidden = true
                : this.hidden = false
        }

      
    }
}
</script>

<style>

</style>