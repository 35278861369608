var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { color: "main-color", dark: "" } },
    [
      _c(
        "v-card-title",
        { staticClass: "py-1 px-3" },
        [
          _c(
            "v-row",
            [
              _c("v-col", [_vm._v("Meta Mensual Agencias")]),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", dark: "", "x-small": "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closeDialog")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close-circle-outline")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        [
          _vm._l(_vm.items, function (item) {
            return _c(
              "v-row",
              { key: item.id, staticClass: "mt-4" },
              [
                _c("v-col", { staticClass: "headline", attrs: { cols: "3" } }, [
                  _vm._v(_vm._s(item.name)),
                ]),
                _vm._v(" "),
                _c(
                  "v-col",
                  { attrs: { cols: "auto" } },
                  [
                    _c("list-simple-icon", {
                      attrs: {
                        icon: item.icon,
                        label: item.name,
                        color: item.color,
                        size: 34,
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-col",
                  [
                    _c("v-text-field", {
                      staticClass: "my-0",
                      attrs: {
                        rules: [_vm.rules.required, _vm.rules.minNum(1)],
                        type: "number",
                        label: "Value",
                        dense: "",
                        outlined: "",
                        filled: "",
                        "hide-details": "",
                        loading: _vm.loading,
                      },
                      model: {
                        value: item.amount,
                        callback: function ($$v) {
                          _vm.$set(item, "amount", $$v)
                        },
                        expression: "item.amount",
                      },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-col",
                  { attrs: { cols: "auto" } },
                  [
                    _c(
                      "v-btn",
                      {
                        attrs: {
                          fab: "",
                          dark: "",
                          color: "warning",
                          "x-small": "",
                          loading: _vm.loading,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.updateItem(item)
                          },
                        },
                      },
                      [_c("v-icon", [_vm._v("mdi-lead-pencil")])],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm.$App.debug ? _c("pre", [_vm._v(_vm._s(_vm.$data))]) : _vm._e(),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }