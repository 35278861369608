var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "rounded-lg",
      attrs: { dark: "", color: "rgba(0,0,0,0.4)" },
    },
    [
      _c(
        "v-subheader",
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", { staticClass: "ml-2 title" }, [
                _vm._v("\n        Usuarios\n      "),
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", icon: "", loading: _vm.loading },
                      on: {
                        click: function ($event) {
                          return _vm.list()
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { size: "26" } }, [
                        _vm._v("mdi-reload"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { staticClass: "mx-5 my-2 subtitle-2", attrs: { "no-gutters": "" } },
        [
          _c("v-col", { attrs: { cols: "4" } }, [
            _vm._v("\n         Dia/Mes\n      "),
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "", color: "blue" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-progress-linear",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    color: "blue darken-1",
                                    height: "15",
                                    value: _vm.getPercent(
                                      _vm.totals.bonus_day / _vm.totals.goal_day
                                    ),
                                    indeterminate: _vm.loading,
                                  },
                                },
                                "v-progress-linear",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.formatNumber(_vm.totals.bonus_day)
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_vm._v(" "), _c("span", [_vm._v("Total Bonus Dia")])]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "", color: "green" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-progress-linear",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    color: "green darken-1",
                                    height: "15",
                                    value: _vm.getPercent(
                                      _vm.totals.bonus_day /
                                        _vm.totals.goal_month
                                    ),
                                    indeterminate: _vm.loading,
                                  },
                                },
                                "v-progress-linear",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.formatNumber(_vm.totals.bonus_month)
                                  ) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_vm._v(" "), _c("span", [_vm._v("Total Bonus Mes")])]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "4" } }, [
            _vm._v("\n        WriteOff\n      "),
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "", color: "orange" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-progress-linear",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    color: "orange darken-1",
                                    height: "15",
                                    value: _vm.totals.writeoff_day,
                                    indeterminate: _vm.loading,
                                  },
                                },
                                "v-progress-linear",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(parseInt(_vm.totals.writeoff_day)) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_vm._v(" "), _c("span", [_vm._v("Total Writeoff Dia")])]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "4" } },
            [
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "", color: "red" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on, attrs }) {
                        return [
                          _c(
                            "v-progress-linear",
                            _vm._g(
                              _vm._b(
                                {
                                  attrs: {
                                    color: "red darken-1",
                                    height: "15",
                                    value: _vm.totals.writeoff_month,
                                    indeterminate: _vm.loading,
                                  },
                                },
                                "v-progress-linear",
                                attrs,
                                false
                              ),
                              on
                            ),
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(parseInt(_vm.totals.writeoff_month)) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_vm._v(" "), _c("span", [_vm._v("Total Writeoff Mes")])]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        { staticClass: "pt-0 accounts-container custom-scroll" },
        [
          _c(
            "v-list",
            {
              staticClass: "rounded-lg",
              attrs: {
                subheader: "",
                "two-line": "",
                dense: "",
                color: "rgba(0,0,0,0.4)",
              },
            },
            _vm._l(_vm.operatos, function (operator) {
              return _c(
                "v-list-item",
                { key: operator.id },
                [
                  _c(
                    "v-badge",
                    {
                      attrs: {
                        "offset-x": "25",
                        "offset-y": "25",
                        overlap: "",
                        color:
                          operator.role_id == 2
                            ? "rgba(255,10,0,0.6)"
                            : "rgba(0,255,0,0.6)",
                        value: [2, 3].includes(operator.role_id),
                        icon: "mdi-shield-account",
                      },
                    },
                    [
                      _c(
                        "v-list-item-avatar",
                        {
                          staticClass: "elevation-2",
                          attrs: { color: "blue", size: "60" },
                        },
                        [
                          _c("v-img", {
                            attrs: {
                              src: `/storage/photo/operator/${
                                operator.photo || "nophoto.png"
                              }`,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "", color: "blue" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "span",
                                                  _vm._g(
                                                    _vm._b(
                                                      {},
                                                      "span",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatNumber(
                                                          operator.presence_day_sum_bonus ||
                                                            0
                                                        )
                                                      ) +
                                                        "  /  " +
                                                        _vm._s(
                                                          operator.goal_day || 0
                                                        )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Meta Dia")]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-spacer"),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "", color: "green" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "span",
                                                  _vm._g(
                                                    _vm._b(
                                                      {},
                                                      "span",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.formatNumber(
                                                          operator.presence_month_sum_bonus ||
                                                            0
                                                        )
                                                      ) +
                                                        "  /  " +
                                                        _vm._s(
                                                          operator.goal_month ||
                                                            0
                                                        )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Meta Mes")]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-progress-linear", {
                                    staticClass: "mb-2 mt-1",
                                    attrs: {
                                      value: _vm.formatNumber(
                                        (operator.presence_day_sum_bonus *
                                          100) /
                                          operator.goal_day || 0
                                      ),
                                      color: "blue",
                                      height: "8",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                [
                                  _c("v-progress-linear", {
                                    staticClass: "mb-2 mt-1",
                                    attrs: {
                                      value: _vm.formatNumber(
                                        (operator.presence_month_sum_bonus *
                                          100) /
                                          operator.goal_month || 0
                                      ),
                                      color: "green",
                                      height: "8",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-subtitle",
                        { staticClass: "pt-2" },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { staticClass: "caption" },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "", color: "blue" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "span",
                                                  _vm._g(
                                                    _vm._b(
                                                      {},
                                                      "span",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      _vm._s(operator.full_name)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        true
                                      ),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [
                                        _vm._v(_vm._s(operator.username)),
                                      ]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pr-1",
                                  attrs: { cols: "auto" },
                                },
                                [
                                  operator.penalty_month.length > 0
                                    ? _c(
                                        "v-badge",
                                        {
                                          attrs: {
                                            "offset-x": "16",
                                            "offset-y": "12",
                                            color: "rgba(0,0,0,0.15)",
                                            content:
                                              operator.penalty_month.length,
                                          },
                                        },
                                        [
                                          _c("list-simple-icon", {
                                            attrs: {
                                              icon: "mdi-account-alert",
                                              label: "Multas del Mes",
                                              color: "red",
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pr-1",
                                  attrs: { cols: "auto" },
                                },
                                [
                                  operator.work_time
                                    ? _c(
                                        "v-badge",
                                        {
                                          attrs: {
                                            "offset-x": "15",
                                            "offset-y": "12",
                                            color: "rgba(0,0,0,0.15)",
                                            content: operator.work_time,
                                          },
                                        },
                                        [
                                          operator.turn
                                            ? _c("list-simple-icon", {
                                                attrs: {
                                                  icon: operator.turn.icon,
                                                  label: `${operator.turn.name} ${operator.work_time} H`,
                                                  color: operator.turn.color,
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                {
                                  staticClass: "pr-1",
                                  attrs: { cols: "auto" },
                                },
                                [
                                  operator.table
                                    ? _c(
                                        "v-badge",
                                        {
                                          attrs: {
                                            "offset-x": "10",
                                            "offset-y": "12",
                                            color: "rgba(0,0,0,0.15)",
                                            content: operator.table.value,
                                          },
                                        },
                                        [
                                          operator.table
                                            ? _c("list-simple-icon", {
                                                attrs: {
                                                  icon: "mdi-table-furniture",
                                                  label: operator.table.name,
                                                  color: "amber",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              operator.profile.length > 0
                                ? _c(
                                    "v-col",
                                    {
                                      staticClass: "pr-1",
                                      attrs: { cols: "auto" },
                                    },
                                    [
                                      _c(
                                        "v-menu",
                                        {
                                          attrs: {
                                            right: "",
                                            bottom: "",
                                            "offset-x": "",
                                            disabled:
                                              operator.profile.length < 1,
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    _c(
                                                      "v-badge",
                                                      {
                                                        attrs: {
                                                          value:
                                                            operator.profile
                                                              .length > 0,
                                                          "offset-x": "10",
                                                          "offset-y": "12",
                                                          color:
                                                            "rgba(0,0,0,0.15)",
                                                          content:
                                                            operator.profile
                                                              .length,
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "list-simple-icon",
                                                          _vm._g(
                                                            {
                                                              attrs: {
                                                                icon: "mdi-account-multiple-outline",
                                                                label:
                                                                  "perfiles",
                                                                color: "green",
                                                              },
                                                            },
                                                            on
                                                          )
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _vm._v(" "),
                                          _c(
                                            "v-card",
                                            [
                                              _c(
                                                "v-card-text",
                                                _vm._l(
                                                  operator.profile,
                                                  function (profile) {
                                                    return _c("h4", {
                                                      key: profile.id,
                                                      domProps: {
                                                        textContent: _vm._s(
                                                          profile.name
                                                        ),
                                                      },
                                                    })
                                                  }
                                                ),
                                                0
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-icon",
                    { staticClass: "mt-3" },
                    [
                      _c("item-menu", {
                        attrs: {
                          menus: _vm.itemsMenu,
                          iconColor: "white",
                          btnColor: "transparent",
                          item: operator,
                        },
                        on: {
                          onItemMenu: function ($event) {
                            return _vm.onItemMenu($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", persistent: "", width: "600" },
          model: {
            value: _vm.addProfileDialog,
            callback: function ($$v) {
              _vm.addProfileDialog = $$v
            },
            expression: "addProfileDialog",
          },
        },
        [
          _vm.addProfileDialog
            ? _c("AppUserProfile", {
                attrs: { user: _vm.user },
                on: {
                  closeDialog: function ($event) {
                    return _vm.closeDialog($event)
                  },
                  onUpdateData: function ($event) {
                    return _vm.list()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", width: "300" },
          model: {
            value: _vm.addGoalsDialog,
            callback: function ($$v) {
              _vm.addGoalsDialog = $$v
            },
            expression: "addGoalsDialog",
          },
        },
        [
          _vm.addGoalsDialog
            ? _c("UserGoals", {
                attrs: { action: "upd", item: _vm.user },
                on: {
                  closeModal: function ($event) {
                    return _vm.closeDialog($event)
                  },
                  onUpdateData: function ($event) {
                    return _vm.list()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", width: "80vw" },
          model: {
            value: _vm.addPenaltyDialog,
            callback: function ($$v) {
              _vm.addPenaltyDialog = $$v
            },
            expression: "addPenaltyDialog",
          },
        },
        [
          _vm.addPenaltyDialog
            ? _c("UserPenalty", {
                attrs: { user: _vm.user },
                on: {
                  closeModal: function ($event) {
                    return _vm.closeDialog($event)
                  },
                  onUpdateData: function ($event) {
                    return _vm.list()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", width: "80vw" },
          model: {
            value: _vm.addAbsenceDialog,
            callback: function ($$v) {
              _vm.addAbsenceDialog = $$v
            },
            expression: "addAbsenceDialog",
          },
        },
        [
          _vm.addAbsenceDialog
            ? _c("UserAbsence", {
                attrs: { user: _vm.user },
                on: {
                  closeModal: function ($event) {
                    return _vm.closeDialog($event)
                  },
                  onUpdateData: function ($event) {
                    return _vm.list()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }