var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "rounded-lg",
      attrs: { dark: "", color: "rgba(0,0,0,0.4)" },
    },
    [
      _c(
        "v-card-title",
        { staticClass: "px-3 py-1" },
        [
          _c(
            "v-row",
            { staticClass: "title", attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("v-icon", { attrs: { left: "", size: "20" } }, [
                    _vm._v("mdi-podium-silver"),
                  ]),
                  _vm._v("  Top Clientes\n            "),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: { small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.list()
                        },
                      },
                    },
                    [_vm._v("mdi-reload")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: { color: "red", small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.detailClient()
                        },
                      },
                    },
                    [_vm._v("mdi-reload")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: { color: "blue", small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.clientPhoto()
                        },
                      },
                    },
                    [_vm._v("mdi-reload")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c(
                "v-col",
                [
                  _c("v-select", {
                    staticClass: "mt-2",
                    attrs: {
                      label: "Agencias",
                      "item-text": "name",
                      "item-value": "id",
                      items: _vm.agencies,
                      loading: _vm.loading,
                      "hide-details": "",
                      outlined: "",
                      filled: "",
                      dense: "",
                    },
                    model: {
                      value: _vm.agency,
                      callback: function ($$v) {
                        _vm.agency = $$v
                      },
                      expression: "agency",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        { staticClass: "pa-0 accounts-container custom-scroll" },
        [
          _c(
            "v-window",
            {
              staticClass: "elevation-0",
              attrs: { reverse: "" },
              model: {
                value: _vm.agency,
                callback: function ($$v) {
                  _vm.agency = $$v
                },
                expression: "agency",
              },
            },
            _vm._l(_vm.agencyClientsTop, function (agency) {
              return _c(
                "v-window-item",
                {
                  key: agency.id,
                  staticClass: "elevation-0",
                  attrs: { value: agency.id },
                },
                [
                  _c(
                    "v-row",
                    { attrs: { "no-gutters": "" } },
                    _vm._l(agency.clients_captured, function (client) {
                      return _c(
                        "v-col",
                        {
                          key: client.id,
                          staticClass: "d-flex align-center",
                          attrs: { cols: "12" },
                        },
                        [_c("ClientCard", { attrs: { client: client } })],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }