<template>

    <v-container fluid>
        <v-row dense>
            <v-col cols="12" md="6" lg="4">
                <Profileboard v-if="done"></Profileboard>
            </v-col>
            <v-col cols="12" md="6" lg="4">
                <ListClients v-if="done"></ListClients>
            </v-col>
            <v-col cols="12" md="6" lg="4">
                <Chat v-if="done"/>
            </v-col>
        </v-row>
    </v-container>
  
</template>

<script>
import AppData from '@mixins/AppData' 
import Profileboard from './components/ProfileBoard'
import ListClients from './components/ListClients'
import Chat from './components/ChatTest'
export default {

    name: 'profile-board',

    mixins:[ AppData],

    components:{
        Profileboard,
        ListClients,
        Chat
    },


    created() {
        this.login()
    },

    methods:
    {
        login()
        {
            this.getResource(`profile/${this.$route.params.profile_id}/login`).then( data => {
                this.done = true
                console.log(data)
            })
        }
        
    },
    data: () => ({
        done: false
    })
}
</script>

<style>

</style>