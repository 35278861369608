import { render, staticRenderFns } from "./tableTurnForm.vue?vue&type=template&id=fde50008&"
import script from "./tableTurnForm.vue?vue&type=script&lang=js&"
export * from "./tableTurnForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/eugenio/www/socialmedia-soporte/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('fde50008')) {
      api.createRecord('fde50008', component.options)
    } else {
      api.reload('fde50008', component.options)
    }
    module.hot.accept("./tableTurnForm.vue?vue&type=template&id=fde50008&", function () {
      api.rerender('fde50008', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/views/tableTurn/tableTurnForm.vue"
export default component.exports