import { render, staticRenderFns } from "./UserPenaltyForm.vue?vue&type=template&id=21f082cd&"
import script from "./UserPenaltyForm.vue?vue&type=script&lang=js&"
export * from "./UserPenaltyForm.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/eugenio/www/socialmedia-soporte/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('21f082cd')) {
      api.createRecord('21f082cd', component.options)
    } else {
      api.reload('21f082cd', component.options)
    }
    module.hot.accept("./UserPenaltyForm.vue?vue&type=template&id=21f082cd&", function () {
      api.rerender('21f082cd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/views/penalty/UserPenaltyForm.vue"
export default component.exports