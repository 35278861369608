var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "rounded-lg",
      attrs: { dark: "", color: "rgba(0,0,0,0.4)" },
    },
    [
      _c(
        "v-subheader",
        [
          _c(
            "v-row",
            { staticClass: "title" },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-chart-bar"),
                  ]),
                  _vm._v("  Estadisticas\n            "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-icon",
                    {
                      attrs: { small: "" },
                      on: {
                        click: function ($event) {
                          return _vm.list()
                        },
                      },
                    },
                    [_vm._v("mdi-reload")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-col"),
              _vm._v(" "),
              _c("v-col", { attrs: { cols: "auto" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        { staticClass: "pt-0 accounts-container custom-scroll" },
        [
          _vm._l(_vm.agencies, function (agency) {
            return _c(
              "v-card",
              { key: agency.id, attrs: { color: "rgba(0,0,0,0.2)" } },
              [
                _c("v-card-title", { staticClass: "pa-2" }, [
                  _vm._v(
                    "\n                " +
                      _vm._s(agency.name) +
                      "\n            "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "v-card-text",
                  [
                    _c("v-subheader", [_vm._v("Clientes")]),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        _c("v-col", { attrs: { cols: "6" } }, [
                          _vm._v("Total"),
                        ]),
                        _vm._v(" "),
                        _c("v-col", { attrs: { cols: "5" } }, [
                          _vm._v(_vm._s(agency.clients_count)),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        _c("v-col", { attrs: { cols: "6" } }, [
                          _vm._v("Sin Contacto"),
                        ]),
                        _vm._v(" "),
                        _c("v-col", { attrs: { cols: "5" } }, [
                          _vm._v(_vm._s(agency.clients_pending_count)),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        _c("v-col", { attrs: { cols: "6" } }, [
                          _vm._v("Contactados"),
                        ]),
                        _vm._v(" "),
                        _c("v-col", { attrs: { cols: "5" } }, [
                          _vm._v(_vm._s(agency.clients_contacted_count)),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        _c("v-col", { attrs: { cols: "6" } }, [
                          _vm._v("Captados"),
                        ]),
                        _vm._v(" "),
                        _c("v-col", { attrs: { cols: "5" } }, [
                          _vm._v(_vm._s(agency.clients_captured_count)),
                        ]),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        _c("v-col", { attrs: { cols: "6" } }, [
                          _vm._v("Nuevo Dia"),
                        ]),
                        _vm._v(" "),
                        _c("v-col", { attrs: { cols: "5" } }, [
                          _vm._v(_vm._s(agency.clients_day_count)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("list-simple-icon", {
                              attrs: {
                                icon: "mdi-magnify-scan",
                                label: "Detalle Dia",
                                color: "amber darken-3",
                                size: 16,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.detailContacted("day", agency)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        _c("v-col", { attrs: { cols: "6" } }, [
                          _vm._v("Nuevo Semana"),
                        ]),
                        _vm._v(" "),
                        _c("v-col", { attrs: { cols: "5" } }, [
                          _vm._v(_vm._s(agency.clients_week_count)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("list-simple-icon", {
                              attrs: {
                                icon: "mdi-magnify-scan",
                                label: "Detalle Semana",
                                color: "amber darken-3",
                                size: 16,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.detailContacted("week", agency)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "v-row",
                      { attrs: { "no-gutters": "" } },
                      [
                        _c("v-col", { attrs: { cols: "6" } }, [
                          _vm._v("Nuevo Mes"),
                        ]),
                        _vm._v(" "),
                        _c("v-col", { attrs: { cols: "5" } }, [
                          _vm._v(_vm._s(agency.clients_month_count)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("list-simple-icon", {
                              attrs: {
                                icon: "mdi-magnify-scan",
                                label: "Detalle Mes",
                                color: "amber darken-3",
                                size: 16,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.detailContacted("month", agency)
                                },
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "v-dialog",
            {
              attrs: { scrollable: "", width: "95vw", "content-class": "" },
              model: {
                value: _vm.dialogDetail,
                callback: function ($$v) {
                  _vm.dialogDetail = $$v
                },
                expression: "dialogDetail",
              },
            },
            [
              _vm.dialogDetail
                ? _c("DetailClientContacted", {
                    attrs: { agency: _vm.agency, type: _vm.type },
                    on: {
                      closeDialog: function ($event) {
                        return _vm.closeDialog($event)
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }