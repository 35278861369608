var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "rounded-lg",
      attrs: { dark: "", color: "rgba(0,0,0,0.4)", "min-height": "91vh" },
    },
    [
      _c(
        "v-card-text",
        { staticClass: "pt-0 accounts-container custom-scroll" },
        [
          _c(
            "v-list",
            {
              staticClass: "rounded-lg",
              attrs: {
                subheader: "",
                "two-line": "",
                dense: "",
                color: "rgba(0,0,0,0.4)",
              },
            },
            _vm._l(_vm.messages, function (message) {
              return _c(
                "v-container",
                { key: message.id },
                [
                  _c(
                    "v-list-item",
                    {
                      class:
                        message.sender == _vm.$route.params.client_id
                          ? "client"
                          : "profile",
                      style: {
                        background: message.meta.hasOwnProperty("cover")
                          ? "#" + message.meta.cover
                          : "",
                      },
                    },
                    [
                      _c(
                        "v-list-item-content",
                        {
                          class: message.meta.hasOwnProperty("letter")
                            ? "is-letter"
                            : "",
                        },
                        [
                          _c(
                            "v-list-item-title",
                            { staticClass: "text-wrap" },
                            [_vm._v(_vm._s(message.text))]
                          ),
                          _vm._v(" "),
                          message.meta.letter
                            ? _c(
                                "v-icon",
                                { attrs: { color: "info", size: "large" } },
                                [_vm._v("mdi-email")]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        { staticClass: "d-flex" },
        [
          _c("v-text-field", {
            attrs: { label: "Message", outlined: "" },
            model: {
              value: _vm.newMessage,
              callback: function ($$v) {
                _vm.newMessage = $$v
              },
              expression: "newMessage",
            },
          }),
          _vm._v(" "),
          _c(
            "v-btn",
            { attrs: { color: "primary" }, on: { click: _vm.sendMessage } },
            [_vm._v("Send")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }