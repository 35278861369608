var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-switch", {
    attrs: {
      inset: "",
      "hide-details": "",
      readonly: _vm.readOnly,
      loading: _vm.loading,
      "true-value": _vm.trueValue,
      "false-value": _vm.falseValue,
      color: _vm.color,
      "input-value": _vm.item.status_id,
    },
    on: {
      change: function ($event) {
        return _vm.changeStatus($event)
      },
    },
    model: {
      value: _vm.status,
      callback: function ($$v) {
        _vm.status = $$v
      },
      expression: "status",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }