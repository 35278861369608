var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { dark: "", color: "transparent" } },
    [
      _c(
        "v-card-title",
        { staticClass: "py-0" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", [
                _c("span", [
                  _vm._v(
                    "Online: " +
                      _vm._s(_vm.totalEvents.online) +
                      " / " +
                      _vm._s(_vm.profiles.length)
                  ),
                ]),
                _c("span", [
                  _vm._v(
                    " - Pendiente: Cartas " + _vm._s(_vm.totalEvents.letter)
                  ),
                ]),
                _c("span", [
                  _vm._v(" - Mensajes " + _vm._s(_vm.totalEvents.introductory)),
                ]),
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        small: "",
                        icon: "",
                        color: "success",
                        loading: _vm.loading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.list()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-reload")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        small: "",
                        icon: "",
                        color: "red",
                        loading: _vm.loading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.profileEvents()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-reload")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        { staticClass: "pt-2" },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            _vm._l(_vm.profiles, function (profile) {
              return _c(
                "v-col",
                {
                  key: profile.id,
                  attrs: { cols: "12", lg: "3", md: "4", sm: "6" },
                },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        color: "rgba(0,0,0,0.4)",
                        "min-height": "7.9rem",
                      },
                    },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-tooltip",
                            {
                              attrs: { bottom: "", color: "rgba(0,0,0,0.8)" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-btn",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass: "mt-4 ml-n4",
                                                attrs: {
                                                  "x-small": "",
                                                  fab: "",
                                                  color: "rgba(0,0,0,0.4)",
                                                  absolute: "",
                                                  top: "",
                                                  left: "",
                                                },
                                              },
                                              "v-btn",
                                              attrs,
                                              false
                                            ),
                                            on
                                          ),
                                          [
                                            _vm._v(
                                              _vm._s(
                                                profile.agency.name.substr(0, 2)
                                              )
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("span", [_vm._v(_vm._s(profile.agency.name))]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0 ma-0 text-center",
                                      attrs: { cols: "12" },
                                    },
                                    [_vm._v(_vm._s(profile.name))]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-badge",
                                    {
                                      attrs: {
                                        "offset-x": "9",
                                        "offset-y": "7",
                                        overlap: "",
                                        color:
                                          profile.events.online == 1
                                            ? "green"
                                            : "red",
                                        value: true,
                                        dot: "",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-avatar",
                                        { attrs: { size: "80" } },
                                        [
                                          _c("v-img", {
                                            attrs: {
                                              src: `/storage/photo/profile/${
                                                profile.photo || "nophoto"
                                              }.jpg`,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "pa-0 ma-0 caption",
                                      attrs: { cols: "12" },
                                    },
                                    [_vm._v(_vm._s(profile.amolatina_id))]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                bottom: "",
                                                color: "blue",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-progress-linear",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "mb-2 mt-1",
                                                                attrs: {
                                                                  value:
                                                                    (profile.presence_day_sum_bonus *
                                                                      100) /
                                                                      400 || 0,
                                                                  color: "blue",
                                                                  height: "14",
                                                                },
                                                              },
                                                              "v-progress-linear",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.formatNumber(
                                                                    profile.presence_day_sum_bonus
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [_vm._v("DIA")]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                bottom: "",
                                                color: "green",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-progress-linear",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "mb-2 mt-1",
                                                                attrs: {
                                                                  value:
                                                                    (profile.presence_month_sum_bonus *
                                                                      100) /
                                                                      2000 || 0,
                                                                  color:
                                                                    "green",
                                                                  height: "14",
                                                                },
                                                              },
                                                              "v-progress-linear",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  _vm.formatNumber(
                                                                    profile.presence_month_sum_bonus
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [_vm._v("MES")]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                bottom: "",
                                                color: "amber darken-2",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-progress-linear",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "mb-2 mt-1",
                                                                attrs: {
                                                                  value:
                                                                    profile.presence_day_sum_writeoff ||
                                                                    0,
                                                                  color:
                                                                    "amber darken-2",
                                                                  height: "14",
                                                                },
                                                              },
                                                              "v-progress-linear",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  parseInt(
                                                                    profile.presence_day_sum_writeoff ||
                                                                      0
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [_vm._v("DIA")]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        { attrs: { cols: "6" } },
                                        [
                                          _c(
                                            "v-tooltip",
                                            {
                                              attrs: {
                                                bottom: "",
                                                color: "red",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({
                                                      on,
                                                      attrs,
                                                    }) {
                                                      return [
                                                        _c(
                                                          "v-progress-linear",
                                                          _vm._g(
                                                            _vm._b(
                                                              {
                                                                staticClass:
                                                                  "mb-2 mt-1",
                                                                attrs: {
                                                                  value:
                                                                    profile.presence_month_sum_writeoff ||
                                                                    0,
                                                                  color: "red",
                                                                  height: "14",
                                                                },
                                                              },
                                                              "v-progress-linear",
                                                              attrs,
                                                              false
                                                            ),
                                                            on
                                                          ),
                                                          [
                                                            _c("span", [
                                                              _vm._v(
                                                                _vm._s(
                                                                  parseInt(
                                                                    profile.presence_month_sum_writeoff ||
                                                                      0
                                                                  )
                                                                )
                                                              ),
                                                            ]),
                                                          ]
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                true
                                              ),
                                            },
                                            [
                                              _vm._v(" "),
                                              _c("span", [_vm._v("MES")]),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    [
                                      _c(
                                        "v-col",
                                        [
                                          _vm._l(
                                            profile.user_has_presence_day,
                                            function (user) {
                                              return _c(
                                                "v-tooltip",
                                                {
                                                  key: user.id,
                                                  attrs: {
                                                    bottom: "",
                                                    color: "rgba(0,0,0,0.6)",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          on,
                                                          attrs,
                                                        }) {
                                                          return [
                                                            _c(
                                                              "v-badge",
                                                              {
                                                                attrs: {
                                                                  "offset-x":
                                                                    "9",
                                                                  "offset-y":
                                                                    "7",
                                                                  overlap: "",
                                                                  color:
                                                                    _vm.colorAssignedPresence(
                                                                      profile,
                                                                      user.id
                                                                    ),
                                                                  value:
                                                                    _vm.isUserPresence(
                                                                      profile,
                                                                      user.id
                                                                    ),
                                                                  dot: "",
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "v-avatar",
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        staticClass:
                                                                          "mx-1",
                                                                        attrs: {
                                                                          size: "40",
                                                                          color:
                                                                            "rgba(0,0,0,0.4)",
                                                                        },
                                                                      },
                                                                      "v-avatar",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      "v-img",
                                                                      {
                                                                        attrs: {
                                                                          src: `/storage/photo/operator/${
                                                                            user.photo ||
                                                                            "nophoto.jpg"
                                                                          }`,
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    [
                                                      _vm._v(
                                                        "\n                        " +
                                                          _vm._s(
                                                            user.full_name
                                                          ) +
                                                          " \n                        "
                                                      ),
                                                      user.turn
                                                        ? _c(
                                                            "v-badge",
                                                            {
                                                              attrs: {
                                                                "offset-x":
                                                                  "15",
                                                                "offset-y":
                                                                  "12",
                                                                color:
                                                                  "rgba(0,0,0,0.15)",
                                                                content:
                                                                  user.work_time,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "list-simple-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: user
                                                                      .turn
                                                                      .icon,
                                                                    label: ` ${user.turn.name} ${user.work_time} H`,
                                                                    color:
                                                                      user.turn
                                                                        .color,
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                      _vm._v(" "),
                                                      user.table
                                                        ? _c(
                                                            "v-badge",
                                                            {
                                                              attrs: {
                                                                "offset-x":
                                                                  "10",
                                                                "offset-y":
                                                                  "12",
                                                                color:
                                                                  "rgba(0,0,0,0.15)",
                                                                content:
                                                                  user.table
                                                                    .value,
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "list-simple-icon",
                                                                {
                                                                  attrs: {
                                                                    icon: "mdi-table-furniture",
                                                                    label:
                                                                      user.table
                                                                        .name,
                                                                    color:
                                                                      "amber",
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          _vm._v(" "),
                                          profile.user_has_presence_day < 1
                                            ? _c(
                                                "v-avatar",
                                                {
                                                  staticClass: "mx-1",
                                                  attrs: {
                                                    size: "30",
                                                    color: "transparent",
                                                  },
                                                },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      src: `/storage/photo/operator/nophoto.jpg'}`,
                                                    },
                                                  }),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-row",
                                    { staticClass: "mt-1" },
                                    [
                                      _c("v-spacer"),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "pa-0",
                                          attrs: { cols: "6" },
                                        },
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              _c(
                                                "v-col",
                                                [
                                                  _c(
                                                    "v-badge",
                                                    {
                                                      attrs: {
                                                        color: "red",
                                                        overlap: "",
                                                        "offset-x": "-3",
                                                        left: "",
                                                        value:
                                                          profile.events
                                                            .introductory > 0,
                                                        content:
                                                          profile.events
                                                            .introductory,
                                                      },
                                                    },
                                                    [
                                                      _c("list-simple-icon", {
                                                        attrs: {
                                                          label: "Chat",
                                                          icon: "mdi-comment-text-outline",
                                                          color: "orange",
                                                          size: 18,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                [
                                                  _c(
                                                    "v-badge",
                                                    {
                                                      attrs: {
                                                        color: "red",
                                                        overlap: "",
                                                        "offset-x": "-3",
                                                        left: "",
                                                        value:
                                                          profile.events
                                                            .letter > 0,
                                                        content:
                                                          profile.events.letter,
                                                      },
                                                    },
                                                    [
                                                      _c("list-simple-icon", {
                                                        attrs: {
                                                          label: "Carta",
                                                          icon: "mdi-email-outline",
                                                          color: "amber",
                                                          size: 18,
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }