var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "transparent" },
    [
      _c(
        "v-main",
        [
          _c(
            "v-container",
            { staticClass: "mt-n12", attrs: { fluid: "" } },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "v-card",
                        {
                          attrs: {
                            "min-height": "90vh",
                            color: "rgba(0,0,0,0.4)",
                            dark: "",
                          },
                        },
                        [
                          _c(
                            "v-card-title",
                            [
                              _c(
                                "v-row",
                                [
                                  _c(
                                    "v-col",
                                    {
                                      staticClass: "display-2",
                                      attrs: { cols: "12" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                  " +
                                          _vm._s(_vm.$App.title) +
                                          "\n                "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }