var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "button-container" },
    [
      _vm.upd
        ? _c(
            "v-tooltip",
            {
              attrs: { bottom: "", color: _vm.$App.theme.button.update },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "my-1 mr-1",
                              attrs: {
                                fab: "",
                                dark: "",
                                "x-small": "",
                                color: _vm.$App.theme.button.update,
                                loading: _vm.loading,
                                depressed: "",
                              },
                              on: { click: _vm.update },
                            },
                            on
                          ),
                          [_c("v-icon", [_vm._v("mdi-lead-pencil")])],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                1245596077
              ),
            },
            [_vm._v("\n            Editar\n        ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.del
        ? _c(
            "v-tooltip",
            {
              attrs: { bottom: "", color: _vm.$App.theme.button.delete },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              staticClass: "my-1 mr-1",
                              attrs: {
                                fab: "",
                                dark: "",
                                "x-small": "",
                                color: _vm.$App.theme.button.delete,
                                loading: _vm.loading,
                                depressed: "",
                              },
                              on: { click: _vm.deleter },
                            },
                            on
                          ),
                          [_c("v-icon", [_vm._v("delete")])],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                2994518839
              ),
            },
            [_vm._v("\n            Eliminar\n        ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm._t("default"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }