var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { persistent: "", "max-width": "400" },
      model: {
        value: _vm.dialog,
        callback: function ($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog",
      },
    },
    [
      _c(
        "v-card",
        [
          _c("v-card-title", { staticClass: "red white--text" }, [
            _vm._v("Atencion"),
          ]),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c("v-icon", { attrs: { color: "info" } }, [_vm._v("error")]),
              _vm._v(_vm._s(_vm.mensaje)),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "error",
                      attrs: { slot: "activator", fab: "", small: "" },
                      on: { click: _vm.delItem },
                      slot: "activator",
                    },
                    [_c("v-icon", [_vm._v("delete")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Eliminar")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-tooltip",
                { attrs: { bottom: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "warning",
                      attrs: { slot: "activator", fab: "", small: "" },
                      on: { click: _vm.delCancel },
                      slot: "activator",
                    },
                    [_c("v-icon", [_vm._v("reply")])],
                    1
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("Regresar")]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }