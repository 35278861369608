<template>
<v-form ref="form" v-model="valid" lazy-validation>

    <v-card :loading="loading" flat class="main-color" dark>

        <v-card-title  class="subtitle-2">
            <v-row>
                <v-col>Perfil</v-col>
                <v-col cols="auto"><v-btn icon x-small dark @click="cancel()"><v-icon>mdi-close-circle</v-icon></v-btn></v-col>
            </v-row>
        </v-card-title>

        <v-card-text>
        <v-row dense class="mt-1"> 

            <v-row>
                <v-col >

                    <v-row dense>
                        <v-col cols="12">
                            <v-text-field
                                v-model="form.name"
                                label="Perfil"
                                dense
                                filled
                                outlined
                                hide-details
                                readonly
                                prepend-icon="mdi-account"
                            ></v-text-field>
                        </v-col>

                        <v-col cols="4">
                            <v-select
                                dense
                                outlined
                                filled
                                label="Tipo Comisión"
                                v-model="form.active_profile_arrangement.arrangement_type_id"   
                                :items="arrangementTypes"
                                :rules="[rules.required, rules.select]"
                                item-value="id"
                                item-text="symbol"
                                hide-details
                            ></v-select>
                        </v-col>
                        <v-col cols="8">
                            <v-text-field
                                :rules="[rules.required, rules.digits]"
                                v-model="form.active_profile_arrangement.value"
                                label="Comisión"
                                dense
                                filled
                                outlined
                                hide-details
                            ></v-text-field> 
                        </v-col>
                    </v-row> 
                </v-col>
                      
                <v-col cols="auto" class="mt-4 text-center">
                    <v-avatar  width="100" height="100" tile color="blue" class="ml-1 rounded-circle" >
                        <v-img :src="`/storage/photo/profile/${form.photo || 'nophoto'}.jpg`" ></v-img>
                    </v-avatar>

                    <form-buttons
                        v-if="form.active_profile_arrangement.id != null"
                        @update="update()"
                        @cancel="cancel()"
                        action="upd"
                        :valid="valid"
                        :loading="loading"
                    ></form-buttons>
                    <form-buttons
                         v-if="form.active_profile_arrangement.id == null"
                        @store="store()"
                        @cancel="cancel()"
                        action="ins"
                        :valid="valid"
                        :loading="loading"
                    ></form-buttons>

                </v-col>
            </v-row>

        </v-row>

        </v-card-text>

        <pre v-if="$App.debug">{{ $data }}</pre>

    </v-card>
    
    </v-form>
  
</template>

<script>
import AppForm from '@mixins/AppForm'
export default {

  mixins: [AppForm],

  created()
  {
    this.mapForm()
    this.setArrangement()
    this.getArrangementTypes()
  },

    data() {
        return {
            resource: 'profileArrangements',
            form:
            {
                id:    null,
                name:  null,
                photo: null,
                active_profile_arrangement: {
                    id: null,
                    profile_id: null,
                    arrangement_type_id: null,
                    value: null,
                },
            },
            arrangementTypes: [],
            
        }
    },

    methods:
    {

        getArrangementTypes(){
            this.getResource(`arrangementTypes`).then( data =>{
                this.arrangementTypes = data
            })
        },

        setArrangement() {
            if(this.form.active_profile_arrangement == null) {
                this.form.active_profile_arrangement = {
                    id: null,
                    profile_id: null,
                    arrangement_type_id: null,
                    value: null,
                }
            
            }
        },
    }
}
</script>

<style>

</style>