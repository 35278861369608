var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { staticClass: "white", attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        [
          _c(
            "v-flex",
            { attrs: { xs12: "" } },
            [
              _c(
                "v-card",
                {
                  class: `mx-1 mt-1 list-container ${
                    _vm.inDialog ? "rounded-xl" : null
                  }`,
                  attrs: { "min-height": "92vh", flat: _vm.inDialog },
                },
                [
                  !_vm.inDialog
                    ? _c(
                        "v-toolbar",
                        {
                          staticClass: "main-color",
                          attrs: {
                            flat: "",
                            dense: "",
                            height: "40",
                            dark: "",
                          },
                        },
                        [
                          _c("v-toolbar-title", {
                            domProps: { textContent: _vm._s(_vm.title) },
                          }),
                          _vm._v(" "),
                          _c("v-spacer"),
                          _vm._v(" "),
                          _vm._t("HeadTools"),
                          _vm._v(" "),
                          _c("list-menu", {
                            attrs: { color: "white", itemsMenu: _vm.itemsMenu },
                            on: {
                              onMenu: function ($event) {
                                return _vm.$emit("onMenu", $event)
                              },
                            },
                          }),
                        ],
                        2
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    { staticClass: "my-0" },
                    [_vm._t("default")],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }