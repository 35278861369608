var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "rounded-lg",
      attrs: { dark: "", color: "rgba(0,0,0,0.4)" },
    },
    [
      _c("v-card-title", { attrs: { "primary-title": "" } }, [
        _vm._v("\n    DashBoard\n  "),
      ]),
      _vm._v(" "),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    {
                      directives: [{ name: "ripple", rawName: "v-ripple" }],
                      attrs: { color: "green" },
                      on: {
                        click: function ($event) {
                          return _vm.navigateToName("table")
                        },
                      },
                    },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-icon", { attrs: { size: "34" } }, [
                                    _vm._v("mdi-table-furniture"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-col", { staticClass: "pt-4" }, [
                                _vm._v("Mesas"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    {
                      directives: [{ name: "ripple", rawName: "v-ripple" }],
                      attrs: { color: "amber" },
                      on: {
                        click: function ($event) {
                          return _vm.navigateToName("turn")
                        },
                      },
                    },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-icon", { attrs: { size: "34" } }, [
                                    _vm._v("mdi-clock"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-col", { staticClass: "pt-4" }, [
                                _vm._v("Turno"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    {
                      directives: [{ name: "ripple", rawName: "v-ripple" }],
                      attrs: { color: "brown" },
                      on: {
                        click: function ($event) {
                          return _vm.navigateToName("group")
                        },
                      },
                    },
                    [
                      _c(
                        "v-card-text",
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _c("v-icon", { attrs: { size: "34" } }, [
                                    _vm._v("mdi-account-group"),
                                  ]),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("v-col", { staticClass: "pt-4" }, [
                                _vm._v("Grupo"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }