var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-form",
        {
          ref: "form",
          attrs: { "lazy-validation": "" },
          model: {
            value: _vm.valido,
            callback: function ($$v) {
              _vm.valido = $$v
            },
            expression: "valido",
          },
        },
        [
          _c(
            "v-layout",
            { attrs: { wrap: "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs1: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", color: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.listsLoader()
                        },
                      },
                    },
                    [_vm._v("schema")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                { attrs: { "xs1*": "" } },
                [
                  _c("v-select", {
                    attrs: {
                      label: "Schema",
                      items: _vm.lists.schemas,
                      rules: [_vm.rules.required],
                    },
                    on: {
                      change: function ($event) {
                        return _vm.getTables()
                      },
                    },
                    model: {
                      value: _vm.form.schema,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "schema", $$v)
                      },
                      expression: "form.schema",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                { attrs: { xs1: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { small: "", color: "success" },
                      on: {
                        click: function ($event) {
                          return _vm.getTables()
                        },
                      },
                    },
                    [_vm._v("table")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-list",
                    { attrs: { dense: "" } },
                    _vm._l(_vm.tables, function (table, nbTable) {
                      return _c(
                        "v-list-item",
                        { key: nbTable, attrs: { dense: "" } },
                        [
                          _c("v-checkbox", {
                            staticClass: "ma-0",
                            attrs: {
                              label: nbTable,
                              value: table,
                              multiple: "",
                            },
                            model: {
                              value: _vm.form.tables,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "tables", $$v)
                              },
                              expression: "form.tables",
                            },
                          }),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-flex", { attrs: { xs12: "" } }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("form-buttons", {
        attrs: { btnAccion: false, valid: true },
        on: {
          store: function ($event) {
            return _vm.generate()
          },
          clear: function ($event) {
            return _vm.clear()
          },
          cancel: function ($event) {
            return _vm.cancel()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }