import { render, staticRenderFns } from "./StatusSwitch.vue?vue&type=template&id=588b457c&scoped=true&"
import script from "./StatusSwitch.vue?vue&type=script&lang=js&"
export * from "./StatusSwitch.vue?vue&type=script&lang=js&"
import style0 from "./StatusSwitch.vue?vue&type=style&index=0&id=588b457c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "588b457c",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/eugenio/www/socialmedia-soporte/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('588b457c')) {
      api.createRecord('588b457c', component.options)
    } else {
      api.reload('588b457c', component.options)
    }
    module.hot.accept("./StatusSwitch.vue?vue&type=template&id=588b457c&scoped=true&", function () {
      api.rerender('588b457c', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/list/StatusSwitch.vue"
export default component.exports