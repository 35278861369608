var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { loading: _vm.loading, flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [_vm.rules.required],
                          label: "Username",
                          placeholder: "Indique Username",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.username,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "username", $$v)
                          },
                          expression: "form.username",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [_vm.rules.required],
                          label: "Password",
                          placeholder: "Indique Password",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.password,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "password", $$v)
                          },
                          expression: "form.password",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [_vm.rules.required],
                          label: "Name",
                          placeholder: "Indique Name",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.name,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "name", $$v)
                          },
                          expression: "form.name",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [_vm.rules.required],
                          label: "Surname",
                          placeholder: "Indique Surname",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.surname,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "surname", $$v)
                          },
                          expression: "form.surname",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [_vm.rules.required],
                          label: "Role Id",
                          placeholder: "Indique Role Id",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.role_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "role_id", $$v)
                          },
                          expression: "form.role_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [_vm.rules.required],
                          label: "Agency Id",
                          placeholder: "Indique Agency Id",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.agency_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "agency_id", $$v)
                          },
                          expression: "form.agency_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [_vm.rules.required],
                          label: "Group Id",
                          placeholder: "Indique Group Id",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.group_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "group_id", $$v)
                          },
                          expression: "form.group_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [_vm.rules.required],
                          label: "Photo",
                          placeholder: "Indique Photo",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.photo,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "photo", $$v)
                          },
                          expression: "form.photo",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [_vm.rules.required],
                          label: "Email",
                          placeholder: "Indique Email",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.email,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "email", $$v)
                          },
                          expression: "form.email",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [_vm.rules.required],
                          label: "Verification",
                          placeholder: "Indique Verification",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.verification,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "verification", $$v)
                          },
                          expression: "form.verification",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "min-width": "290px",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      {
                                        attrs: {
                                          rules: [_vm.rules.fecha],
                                          label: "Email Verified At",
                                          "prepend-icon": "event",
                                          readonly: "",
                                          dense: "",
                                        },
                                        model: {
                                          value: _vm.dates.email_verified_at,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              _vm.dates,
                                              "email_verified_at",
                                              $$v
                                            )
                                          },
                                          expression: "dates.email_verified_at",
                                        },
                                      },
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.pickers.email_verified_at,
                            callback: function ($$v) {
                              _vm.$set(_vm.pickers, "email_verified_at", $$v)
                            },
                            expression: "pickers.email_verified_at",
                          },
                        },
                        [
                          _vm._v(" "),
                          _c("v-date-picker", {
                            on: {
                              input: function ($event) {
                                _vm.dates.email_verified_at = _vm.formatPicker(
                                  _vm.form.email_verified_at,
                                  "email_verified_at"
                                )
                              },
                            },
                            model: {
                              value: _vm.form.email_verified_at,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "email_verified_at", $$v)
                              },
                              expression: "form.email_verified_at",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [_vm.rules.required],
                          label: "Remember Token",
                          placeholder: "Indique Remember Token",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.remember_token,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "remember_token", $$v)
                          },
                          expression: "form.remember_token",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [_vm.rules.required],
                          label: "Api Token",
                          placeholder: "Indique Api Token",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.api_token,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "api_token", $$v)
                          },
                          expression: "form.api_token",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [_vm.rules.max(80)],
                          label: "Comments",
                          placeholder: "Indique Comments",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.comments,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "comments", $$v)
                          },
                          expression: "form.comments",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c("form-buttons", {
                attrs: {
                  action: _vm.action,
                  valid: _vm.valid,
                  loading: _vm.loading,
                },
                on: {
                  update: function ($event) {
                    return _vm.update()
                  },
                  store: function ($event) {
                    return _vm.store()
                  },
                  clear: function ($event) {
                    return _vm.clear()
                  },
                  cancel: function ($event) {
                    return _vm.cancel()
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.$App.debug ? _c("pre", [_vm._v(_vm._s(_vm.$data))]) : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }