<template>
  <v-card dark class="rounded-lg" color="rgba(0,0,0,0.4)">
    <!-- <v-card-title primary-title>
      DashBoard
    </v-card-title> -->
    <v-card-title primary-title>
      <v-tabs v-model="tab" background-color="transparent">
        <v-tab>Reportes</v-tab>
        <v-tab>Configuraciones</v-tab>
      </v-tabs>
    </v-card-title>
   

      <v-tabs-items v-model="tab" class="transparent">
        <v-tab-item>
          <v-card dark class="rounded-lg" color="rgba(0,0,0,0)">
            <v-card-text>
              <v-row dense>
                <v-col cols="4" v-for="(report, index) in reports" :key="index">
                  <v-card :color="report.color" v-ripple @click="navigateToName(report.url)">
                    <v-card-text>
                      <v-row dense>
                        <v-col>
                          <v-icon size="34">{{ report.icon }}</v-icon>
                        </v-col>
                        <v-col class="pt-4">{{ report.title }}</v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item>
          <v-card dark class="rounded-lg" color="rgba(0,0,0,0)">
            <v-card-text>
              <v-row dense>
                <v-col cols="4" v-for="(config, index) in configs" :key="index">
                  <v-card :color="config.color" v-ripple @click="navigateToName(config.url)">
                    <v-card-text>
                      <v-row dense>
                        <v-col>
                          <v-icon size="34">{{ config.icon }}</v-icon>
                        </v-col>
                        <v-col class="pt-4">{{ config.title }}</v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
  </v-card>
</template>

<script>
export default {
  data() {
    return {
      tab: 0,
      reports: [
        {
          url: 'present',
          icon: 'mdi-gift',
          color: 'deep-orange',
          title: 'Regalos',
        },
        {
          url: 'online',
          icon: 'mdi-eye-outline',
          color: 'pink',
          title: 'Online',
        },
        {
          url: 'client',
          icon: 'mdi-account-cash',
          color: 'blue',
          title: 'Clientes',
        },
        {
          url: 'reports',
          icon: 'mdi-chart-line',
          color: 'indigo',
          title: 'Reportes',
        },
        {
          url: 'user-presence',
          icon: 'mdi-face-agent',
          color: 'red',
          title: 'Progreso',
        },
        {
          url: 'reports_master',
          icon: 'mdi-finance',
          color: 'teal',
          title: 'Renta',
        },
        {
          url: 'comission',
          icon: 'mdi-sack',
          color: 'purple',
          title: 'Facturas',
        }
      ],
      configs: [
        {
          url: 'table',
          icon: 'mdi-table-furniture',
          color: 'green',
          title: 'Mesas',
        },
        {
          url: 'turn',
          icon: 'mdi-clock',
          color: 'amber',
          title: 'Turno',
        },
        {
          url: 'group',
          icon: 'mdi-account-group',
          color: 'brown',
          title: 'Grupo',
        },
        {
          url: 'profiles',
          icon: 'mdi-account',
          color: 'blue',
          title: 'Perfiles',
        },
        {
          url: 'locations',
          icon: 'mdi-map-marker',
          color: 'teal',
          title: 'Sedes',
        },
        {
          url: 'agency',
          icon: 'mdi-office-building',
          color: 'teal',
          title: 'Agencias',
        }
      ],
    }
  },
}
</script>

<style></style>