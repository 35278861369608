var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "", loading: _vm.loading, "max-height": "80vh" } },
    [
      _c(
        "v-card-title",
        { staticClass: "pa-0" },
        [
          _c("app-simple-toolbar", {
            attrs: { title: "Editar Cuenta" },
            on: {
              closeModal: function ($event) {
                return _vm.$emit("closeDialog", true)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        { staticClass: "pa-0" },
        [
          _c(
            "v-tabs",
            {
              attrs: { grow: "", centered: "" },
              model: {
                value: _vm.seccion,
                callback: function ($$v) {
                  _vm.seccion = $$v
                },
                expression: "seccion",
              },
            },
            _vm._l(_vm.secciones, function (seccion, idx) {
              return _c(
                "v-tab",
                { key: idx, attrs: { href: `#tab-${idx}` } },
                [
                  _c("v-icon", {
                    attrs: { left: "" },
                    domProps: { innerHTML: _vm._s(seccion.icon) },
                  }),
                  _vm._v(
                    "\n                " +
                      _vm._s(seccion.label) +
                      "\n            "
                  ),
                ],
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _c(
            "v-tabs-items",
            {
              model: {
                value: _vm.seccion,
                callback: function ($$v) {
                  _vm.seccion = $$v
                },
                expression: "seccion",
              },
            },
            _vm._l(_vm.secciones, function (seccion, idx) {
              return _c(
                "v-tab-item",
                { key: idx, attrs: { value: `tab-${idx}` } },
                [_c(seccion.component, { tag: "component" })],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }