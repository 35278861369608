var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-avatar",
    {
      staticClass: "ml-1 rounded-circle",
      attrs: { width: _vm.width, height: _vm.height, tile: "", color: "blue" },
    },
    [
      _c(
        "v-img",
        { attrs: { src: _vm.src } },
        [
          _c("v-file-input", {
            staticClass: "ml-10 mt-5",
            attrs: {
              accept: "image/*",
              capture: _vm.capture,
              color: "indigo",
              "prepend-icon": "mdi-image-search",
              loading: _vm.loading,
              disabled: _vm.disabled,
              "hide-details": "",
              "hide-input": "",
            },
            on: {
              change: function ($event) {
                return _vm.setImage($event)
              },
            },
            model: {
              value: _vm.imageUpload,
              callback: function ($$v) {
                _vm.imageUpload = $$v
              },
              expression: "imageUpload",
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: {
            width: "95vw",
            height: "95vh",
            persistent: "",
            transition: "dialog-transition",
          },
          model: {
            value: _vm.crop,
            callback: function ($$v) {
              _vm.crop = $$v
            },
            expression: "crop",
          },
        },
        [
          _c(
            "v-card",
            { staticClass: "col-10 mx-auto" },
            [
              _vm.crop
                ? _c("cropper", {
                    ref: "cropper",
                    attrs: {
                      classname: "cropper",
                      src: _vm.rawImg,
                      "stencil-props": { aspectRatio: _vm.aspectRatio },
                      width: "95vw",
                      height: "95vh",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mt-12",
                  attrs: {
                    fab: "",
                    top: "",
                    right: "",
                    absolute: "",
                    color: "success",
                    loading: _vm.loading,
                  },
                  on: { click: _vm.cropImage },
                },
                [_c("v-icon", [_vm._v("mdi-crop")])],
                1
              ),
              _vm._v(" "),
              _c(
                "v-btn",
                {
                  staticClass: "mt-12",
                  attrs: {
                    fab: "",
                    top: "",
                    left: "",
                    absolute: "",
                    color: "error",
                    loading: _vm.loading,
                  },
                  on: {
                    click: function ($event) {
                      _vm.crop = false
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close")])],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }