var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-color" },
    [
      _c("app-toolbar"),
      _vm._v(" "),
      _c(
        "v-main",
        { staticClass: "fill-height" },
        [
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("app-message"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }