var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      attrs: { color: "transparent", flat: "", dark: "", "min-height": "93vh" },
    },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "pa-2",
                      attrs: { color: "rgba(0,0,0,0.4)", width: "20vw" },
                    },
                    [
                      _c("v-subheader", [_vm._v("Comisiones")]),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-select", {
                                staticClass: "mb-1",
                                attrs: {
                                  label: "Agencias",
                                  "item-text": "name",
                                  items: _vm.agencies,
                                  loading: _vm.loading,
                                  "hide-details": "",
                                  outlined: "",
                                  filled: "",
                                  dense: "",
                                  "return-object": "",
                                  autofocus: "",
                                  rules: [_vm.rules.required],
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.list()
                                  },
                                },
                                model: {
                                  value: _vm.agency,
                                  callback: function ($$v) {
                                    _vm.agency = $$v
                                  },
                                  expression: "agency",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("ComissionCalendar", {
                                on: {
                                  onUpdateDate: function ($event) {
                                    return _vm.setDay($event)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c(
                                "v-radio-group",
                                {
                                  attrs: {
                                    dense: "",
                                    "hide-details": "",
                                    row: "",
                                    color: "primary",
                                  },
                                  on: {
                                    change: function ($event) {
                                      return _vm.list()
                                    },
                                  },
                                  model: {
                                    value: _vm.serviceType,
                                    callback: function ($$v) {
                                      _vm.serviceType = $$v
                                    },
                                    expression: "serviceType",
                                  },
                                },
                                _vm._l(_vm.types, function (type) {
                                  return _c("v-radio", {
                                    key: type,
                                    staticClass: "mb-0 caption",
                                    attrs: { label: type, value: type },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-select", {
                                staticClass: "mt-0 ml-0 mr-0 mb-1",
                                attrs: {
                                  "item-text": "name",
                                  "item-value": "value",
                                  items: _vm.itemsService,
                                  label: "Servicio",
                                  multiple: "",
                                  outlined: "",
                                  filled: "",
                                  dense: "",
                                  chips: "",
                                  "deletable-chips": "",
                                  "single-line": "",
                                  "hide-details": "",
                                  color: "blue",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.list()
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "selection",
                                    fn: function ({ item }) {
                                      return [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: {
                                              bottom: "",
                                              color: "amber",
                                            },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c(
                                                        "v-chip",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "caption",
                                                              attrs: {
                                                                outlined: "",
                                                                small: "",
                                                                close: "",
                                                                color:
                                                                  item.color,
                                                              },
                                                              on: {
                                                                "click:close":
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.remove(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            "v-chip",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [
                                                          _c("v-icon", {
                                                            attrs: { dark: "" },
                                                            domProps: {
                                                              textContent:
                                                                _vm._s(
                                                                  item.icon
                                                                ),
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _vm._v(" "),
                                            _c(
                                              "span",
                                              { staticClass: "caption" },
                                              [_vm._v(_vm._s(item.name))]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.serviceSelected,
                                  callback: function ($$v) {
                                    _vm.serviceSelected = $$v
                                  },
                                  expression: "serviceSelected",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("v-autocomplete", {
                                attrs: {
                                  label: "Perfiles",
                                  "item-text": "name",
                                  "no-data-text":
                                    "No existen perfiles Disponibles",
                                  items: _vm.profilesAgency,
                                  disabled: _vm.loading,
                                  loading: _vm.loading,
                                  "hide-details": "",
                                  outlined: "",
                                  filled: "",
                                  dense: "",
                                  clearable: "",
                                  "return-object": "",
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.list()
                                  },
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "item",
                                    fn: function ({ item }) {
                                      return [
                                        _c(
                                          "v-row",
                                          {
                                            staticClass:
                                              "grey lighten-5 subtitle-1",
                                            attrs: { dense: "" },
                                          },
                                          [
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "auto" } },
                                              [
                                                _c(
                                                  "v-avatar",
                                                  {
                                                    staticClass: "elevation-3",
                                                    attrs: {
                                                      color: "grey",
                                                      size: 30,
                                                    },
                                                  },
                                                  [
                                                    _c("v-img", {
                                                      attrs: {
                                                        src: `/storage/photo/profile/${
                                                          item.photo ||
                                                          "nophoto"
                                                        }.jpg`,
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c("v-col", [
                                              _vm._v(
                                                "\n                             " +
                                                  _vm._s(item.name) +
                                                  "\n                             "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c(
                                              "v-col",
                                              { attrs: { cols: "auto" } },
                                              [
                                                _c("list-icon", {
                                                  attrs: {
                                                    data: _vm.sexIcons,
                                                    value: item.gender,
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.profile,
                                  callback: function ($$v) {
                                    _vm.profile = $$v
                                  },
                                  expression: "profile",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "pa-2",
                      attrs: {
                        color: "rgba(0,0,0,0.4)",
                        "max-height": "92vh",
                        "mix-height": "88vh",
                      },
                    },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "pa-1" },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c("v-col", { attrs: { cols: "auto" } }, [
                                _vm._v("Registros"),
                              ]),
                              _vm._v(" "),
                              _c("v-col", { staticClass: "text-center" }, [
                                _vm._v(
                                  " " + _vm._s(_vm.formatNumber(_vm.total))
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _vm.comissions.prev_page_url
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            loading: _vm.loading,
                                            icon: "",
                                            color: "blue",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goTo(
                                                _vm.comissions.prev_page_url
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              "mdi-arrow-left-drop-circle-outline"
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.comissions.next_page_url
                                    ? _c(
                                        "v-btn",
                                        {
                                          attrs: {
                                            loading: _vm.loading,
                                            icon: "",
                                            color: "blue",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.goTo(
                                                _vm.comissions.next_page_url
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _c("v-icon", [
                                            _vm._v(
                                              "mdi-arrow-right-drop-circle-outline"
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        { staticClass: "comission-container custom-scroll" },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            _vm._l(_vm.comissions.data, function (comission) {
                              return _c(
                                "v-col",
                                { key: comission.id, attrs: { cols: "4" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "ma-1 rounded-lg",
                                      attrs: { color: "rgba(0,0,0,0.4)" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "5" } },
                                            [
                                              _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "caption text-center",
                                                      attrs: { cols: "12" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          comission.client_id
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                      attrs: { cols: "12" },
                                                    },
                                                    [
                                                      _c(
                                                        "v-row",
                                                        {
                                                          attrs: {
                                                            "no-gutters": "",
                                                            justify: "center",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "v-col",
                                                            {
                                                              attrs: {
                                                                cols: "auto",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-avatar",
                                                                {
                                                                  staticClass:
                                                                    "elevation-3",
                                                                  attrs: {
                                                                    color:
                                                                      "blue",
                                                                    size: 30,
                                                                  },
                                                                },
                                                                [
                                                                  _c("v-img", {
                                                                    attrs: {
                                                                      src: `/storage/photo/client/${
                                                                        comission.client
                                                                          ? comission
                                                                              .client
                                                                              .photo ||
                                                                            "nophoto"
                                                                          : "nophoto"
                                                                      }.jpg`,
                                                                    },
                                                                  }),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          comission.client
                                                            ? _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "auto",
                                                                  },
                                                                },
                                                                [
                                                                  comission
                                                                    .client
                                                                    .crown > 0
                                                                    ? _c(
                                                                        "v-badge",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              "offset-x":
                                                                                "6",
                                                                              "offset-y":
                                                                                "12",
                                                                              color:
                                                                                "rgba(0,0,0,0.15)",
                                                                              content:
                                                                                comission
                                                                                  .client
                                                                                  .crown,
                                                                            },
                                                                        },
                                                                        [
                                                                          _c(
                                                                            "list-simple-icon",
                                                                            {
                                                                              attrs:
                                                                                {
                                                                                  icon: "mdi-crown",
                                                                                  label:
                                                                                    _vm.formatNumber(
                                                                                      comission
                                                                                        .client
                                                                                        .points
                                                                                    ),
                                                                                  color:
                                                                                    "amber",
                                                                                  size: 20,
                                                                                },
                                                                            }
                                                                          ),
                                                                        ],
                                                                        1
                                                                      )
                                                                    : _vm._e(),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "caption text-center",
                                                      attrs: { cols: "12" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          comission.client
                                                            ? comission.client
                                                                .name
                                                            : "-"
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { staticClass: "text-center" },
                                            [
                                              _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "text-detail",
                                                      class:
                                                        (comission.positive = 1)
                                                          ? "white--text"
                                                          : "red--text",
                                                      attrs: { cols: "12" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                  " +
                                                          _vm._s(
                                                            _vm.hourFromLocalTime(
                                                              _vm.UTCToLocalDate(
                                                                comission.comission_at
                                                              )
                                                            )
                                                          ) +
                                                          "\n                                             "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      comission.has_service
                                                        ? _c(
                                                            "list-simple-icon",
                                                            {
                                                              attrs: {
                                                                size: 32,
                                                                icon: comission
                                                                  .has_service
                                                                  .icon,
                                                                label:
                                                                  comission
                                                                    .has_service
                                                                    .name,
                                                                color:
                                                                  comission
                                                                    .has_service
                                                                    .color,
                                                              },
                                                            }
                                                          )
                                                        : _c(
                                                            "list-simple-icon",
                                                            {
                                                              attrs: {
                                                                size: 32,
                                                                icon: "mdi-comment-question",
                                                                label:
                                                                  comission.service,
                                                                color: "amber",
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "text-detail",
                                                      class:
                                                        (comission.positive = 1)
                                                          ? "white--text"
                                                          : "red--text",
                                                      attrs: { cols: "12" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                 " +
                                                          _vm._s(
                                                            _vm.formatNumber(
                                                              comission.points
                                                            )
                                                          ) +
                                                          "\n                                             "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "5" } },
                                            [
                                              _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "caption text-center",
                                                      attrs: { cols: "12" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          comission.profile_id
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                      attrs: { cols: "12" },
                                                    },
                                                    [
                                                      _c(
                                                        "v-avatar",
                                                        {
                                                          staticClass:
                                                            "elevation-3",
                                                          attrs: {
                                                            color: "grey",
                                                            size: 30,
                                                          },
                                                        },
                                                        [
                                                          _c("v-img", {
                                                            attrs: {
                                                              src: `/storage/photo/profile/${
                                                                comission.profile
                                                                  ? comission
                                                                      .profile
                                                                      .photo ||
                                                                    "nophoto"
                                                                  : "no-photo"
                                                              }.jpg`,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "caption text-center",
                                                      attrs: { cols: "12" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          comission.profile
                                                            ? comission.profile
                                                                .name
                                                            : ""
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-overlay",
            { attrs: { opacity: 0.4, value: _vm.loading } },
            [
              _c("v-icon", { staticClass: "mdi-spin", attrs: { size: "50" } }, [
                _vm._v("mdi-loading"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }