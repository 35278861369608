var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { bottom: "", color: _vm.color },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    class: _vm.innerClass,
                    attrs: {
                      fab: "",
                      dark: "",
                      "x-small": !_vm.small,
                      small: _vm.small,
                      color: _vm.color,
                      loading: _vm.loading,
                      disabled: _vm.disabled,
                      depressed: _vm.depressed,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("click", $event)
                      },
                    },
                  },
                  on
                ),
                [
                  _c("v-icon", {
                    attrs: { size: _vm.size },
                    domProps: { textContent: _vm._s(_vm.icon) },
                  }),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [_vm._v(" "), _c("span", { domProps: { textContent: _vm._s(_vm.label) } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }