import { render, staticRenderFns } from "./AppHelp.vue?vue&type=template&id=270f0bf9&scoped=true&"
import script from "./AppHelp.vue?vue&type=script&lang=js&"
export * from "./AppHelp.vue?vue&type=script&lang=js&"
import style0 from "./AppHelp.vue?vue&type=style&index=0&id=270f0bf9&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "270f0bf9",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/eugenio/www/socialmedia-soporte/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('270f0bf9')) {
      api.createRecord('270f0bf9', component.options)
    } else {
      api.reload('270f0bf9', component.options)
    }
    module.hot.accept("./AppHelp.vue?vue&type=template&id=270f0bf9&scoped=true&", function () {
      api.rerender('270f0bf9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/app/AppHelp.vue"
export default component.exports