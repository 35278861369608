var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-card",
        {
          staticClass: "main-color",
          attrs: { loading: _vm.loading, flat: "", dark: "" },
        },
        [
          _c(
            "v-card-title",
            { staticClass: "subtitle-2" },
            [
              _c(
                "v-row",
                [
                  _c("v-col", [
                    _vm._v("Definir Metas - " + _vm._s(_vm.item.full_name)),
                  ]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", "x-small": "", dark: "" },
                          on: {
                            click: function ($event) {
                              return _vm.cancel()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-close-circle")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { staticClass: "mt-1", attrs: { dense: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      rules: [_vm.rules.required],
                                      label: "Meta Dia",
                                      type: "number",
                                      dense: "",
                                      filled: "",
                                      outlined: "",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.form.goal_day,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "goal_day", $$v)
                                      },
                                      expression: "form.goal_day",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      rules: [_vm.rules.required],
                                      label: "Meta Mes",
                                      type: "number",
                                      dense: "",
                                      filled: "",
                                      outlined: "",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.form.goal_month,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "goal_month", $$v)
                                      },
                                      expression: "form.goal_month",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            [
                              _c("v-col"),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c("form-buttons", {
                                    attrs: {
                                      action: "upd",
                                      valid: _vm.valid,
                                      loading: _vm.loading,
                                    },
                                    on: {
                                      update: function ($event) {
                                        return _vm.update()
                                      },
                                      cancel: function ($event) {
                                        return _vm.cancel()
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.$App.debug ? _c("pre", [_vm._v(_vm._s(_vm.$data))]) : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }