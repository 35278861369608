var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-row",
    { attrs: { "no-gutters": "" } },
    [
      _c(
        "v-col",
        { attrs: { cols: "12" } },
        [
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.items,
              search: _vm.search,
              "item-key": "id",
              "sort-by": "",
              dense: "",
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function ({ item }) {
                  return [
                    _c(
                      "tr",
                      _vm._l(item, function (valor, campo) {
                        return _c(
                          "td",
                          { key: campo, staticClass: "text-xs-left" },
                          [
                            _vm._v(
                              "\n                        " +
                                _vm._s(_vm.formatValue(valor, campo)) +
                                "   \n                    "
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }