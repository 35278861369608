var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-card",
        {
          staticClass: "main-color",
          attrs: { loading: _vm.loading, flat: "", dark: "" },
        },
        [
          _c(
            "v-card-title",
            { staticClass: "subtitle-2" },
            [
              _c(
                "v-row",
                [
                  _c("v-col", [_vm._v("Usuario")]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", "x-small": "", dark: "" },
                          on: {
                            click: function ($event) {
                              return _vm.cancel()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-close-circle")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { staticClass: "mt-1", attrs: { dense: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Usuario",
                                      dense: "",
                                      filled: "",
                                      outlined: "",
                                      "hide-details": "",
                                      readonly: "",
                                      "prepend-icon": "mdi-account",
                                    },
                                    model: {
                                      value: _vm.form.username,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "username", $$v)
                                      },
                                      expression: "form.username",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      rules: [_vm.rules.required],
                                      label: "Nombre",
                                      dense: "",
                                      filled: "",
                                      outlined: "",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.form.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "name", $$v)
                                      },
                                      expression: "form.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      rules: [_vm.rules.required],
                                      label: "Apellido",
                                      dense: "",
                                      filled: "",
                                      outlined: "",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.form.surname,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "surname", $$v)
                                      },
                                      expression: "form.surname",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      rules: [_vm.rules.required],
                                      label: "Email",
                                      dense: "",
                                      filled: "",
                                      outlined: "",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.form.email,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "email", $$v)
                                      },
                                      expression: "form.email",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      rules: [_vm.rules.max(64)],
                                      label: "Password",
                                      type: "password",
                                      dense: "",
                                      filled: "",
                                      outlined: "",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.form.password,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "password", $$v)
                                      },
                                      expression: "form.password",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      filled: "",
                                      label: "Grupo",
                                      items: _vm.selects.group,
                                      rules: [_vm.rules.required],
                                      "item-value": "id",
                                      "item-text": "name",
                                      "append-icon": "mdi-account-group",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.form.group_id,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "group_id", $$v)
                                      },
                                      expression: "form.group_id",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      filled: "",
                                      label: "Mesa",
                                      items: _vm.selects.table,
                                      rules: [_vm.rules.required],
                                      "item-value": "id",
                                      "item-text": "name",
                                      "append-icon": "mdi-table-furniture",
                                      "hide-details": "",
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.editTurns($event)
                                      },
                                    },
                                    model: {
                                      value: _vm.form.table_id,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "table_id", $$v)
                                      },
                                      expression: "form.table_id",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      filled: "",
                                      label: "Turno",
                                      items: _vm.turns,
                                      rules: [_vm.rules.required],
                                      "item-value": "id",
                                      "item-text": "name",
                                      "append-icon": "mdi-calendar-clock",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.form.table_turn_id,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "table_turn_id", $$v)
                                      },
                                      expression: "form.table_turn_id",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "12", md: "6" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      filled: "",
                                      label: "Rol",
                                      items: _vm.selects.role,
                                      rules: [_vm.rules.required],
                                      "item-value": "id",
                                      "item-text": "name",
                                      "append-icon": "mdi-shield-account",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.form.role_id,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "role_id", $$v)
                                      },
                                      expression: "form.role_id",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "6", md: "4" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "shrink mr-2 mt-0",
                                    attrs: {
                                      label: `In House`,
                                      "prepend-icon": "mdi-home",
                                      "hide-details": "",
                                      color: "amber",
                                    },
                                    model: {
                                      value: _vm.form.in_house,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "in_house", $$v)
                                      },
                                      expression: "form.in_house",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "6", md: "4" } },
                                [
                                  _c("v-checkbox", {
                                    staticClass: "shrink mr-2 mt-0",
                                    attrs: {
                                      label: `Suplente`,
                                      "prepend-icon":
                                        "mdi-account-switch-outline",
                                      "hide-details": "",
                                      color: "green",
                                    },
                                    model: {
                                      value: _vm.form.is_alternate,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "is_alternate", $$v)
                                      },
                                      expression: "form.is_alternate",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "mt-4 text-center",
                          attrs: { cols: "auto" },
                        },
                        [
                          _c(
                            "v-input",
                            {
                              model: {
                                value: _vm.form.photo,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "photo", $$v)
                                },
                                expression: "form.photo",
                              },
                            },
                            [
                              _c("UserPhoto", {
                                attrs: { photo: _vm.form.photo },
                                on: {
                                  onSetPhoto: function ($event) {
                                    return _vm.setPhoto($event)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("form-buttons", {
                            attrs: {
                              action: "upd",
                              valid: _vm.valid,
                              loading: _vm.loading,
                            },
                            on: {
                              update: function ($event) {
                                return _vm.update()
                              },
                              cancel: function ($event) {
                                return _vm.cancel()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.$App.debug ? _c("pre", [_vm._v(_vm._s(_vm.$data))]) : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }