<template>
    <v-card dark class="rounded-lg " color="rgba(0,0,0,0.4)" min-height="91vh">
        <v-card-text class="pt-0 accounts-container custom-scroll">
            <v-list subheader two-line dense class="rounded-lg" color="rgba(0,0,0,0.4)">
                <v-container v-for="message in messages" :key="message.id">
                    <v-list-item :class="message.sender == $route.params.client_id ? 'client' : 'profile'" 
                        :style="{background: message.meta.hasOwnProperty('cover') ? '#'+message.meta.cover : ''}">
                        <v-list-item-content :class="message.meta.hasOwnProperty('letter') ? 'is-letter' : ''">
                            <v-list-item-title class="text-wrap">{{ message.text }}</v-list-item-title>
                            <v-icon v-if="message.meta.letter" color="info" size="large">mdi-email</v-icon>
                        </v-list-item-content>
                    </v-list-item>
                </v-container>
            </v-list>
        </v-card-text>
        <v-card-text class="d-flex">
            <v-text-field v-model="newMessage" label="Message" outlined></v-text-field>
            <v-btn @click="sendMessage" color="primary">Send</v-btn>
        </v-card-text>
    </v-card>
</template>

<script>
import AppData from '@mixins/AppData' 

export default {
    mixins:[ AppData],
    data:() => ({
        messages: [],
        newMessage: '',
        client: '',
        profile: '',
        isReload: null,
    }),
    created() {
        this.getMessages(),
        this.reload()
    },
    methods: {
        getMessages() {
            this.getResource(`client/${this.$route.params.client_id}/profile/${this.$route.params.profile_id}`).then( data => {
                this.client = this.$route.params.client_id
                this.profile = this.$route.params.profile_id
                this.messages = data;
                console.log(data)
            })
        },
        sendMessage() {
            console.log(this.newMessage)
            this.postResource(`client/${this.$route.params.client_id}/profile/${this.$route.params.profile_id}`,  {message: this.newMessage}).then( data => {
                this.newMessage = ''
            })
        },
        reload() {
            clearInterval(this.isReload)
            this.isReload = setInterval( () => {
                this.getMessages();
            }, 10000 )
        },
    },
};
</script>

<style scoped>
    .client {
        background: rgb(75, 75, 92);
        border-radius: 20px;
        color: white;
        width: fit-content;
        max-width: 80%;
    }
    .profile {
        background: rgb(32, 150, 32);
        border-radius: 20px;
        color: white;
        width: fit-content;
        max-width: 80%;
        margin-left: auto;
    }

    .accounts-container{
        height: 75vh;
        overflow-y: auto;
    }

    .is-letter{
        content: "read-more";
    }
</style>