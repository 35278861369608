<template>


    <v-card dark class="rounded-lg " color="rgba(0,0,0,0.4)"  v-if="user">
        <v-card-title primary-title>
            <v-row dense>
                <v-col>{{user.full_name}} ({{user.username}}) <v-btn icon :loading="loading" small @click="list()"><v-icon>mdi-refresh</v-icon></v-btn></v-col>
            <!--     <v-col cols="auto" ><v-btn icon :loading="loading" small @click="estimatePresence()"><v-icon color="red">mdi-refresh</v-icon></v-btn></v-col> -->
                <v-col cols="auto"><v-btn icon color="success"><v-icon>mdi-dots-vertical</v-icon></v-btn></v-col>
            </v-row>
        </v-card-title>
        <v-card-text>

            <v-row>
                <v-col cols="auto">
                    <v-avatar color="blue" size="100" class="elevation-2">
                        <v-img :src="`/storage/photo/operator/${user.photo || 'nophoto.png'}`" ></v-img>
                    </v-avatar>
                </v-col>
                <v-col>
                        <v-row no-gutters>
                        <v-col cols="5" class="subtitle-2">Rol</v-col>
                        <v-col cols="7" class="font-weight-light">{{user.role.name}}</v-col>
                        <v-col cols="5"  class="subtitle-2">Grupo</v-col>
                        <v-col cols="7" class="font-weight-light">{{(user.group) ? user.group.name : 'sin grupo' }}</v-col>
                        <v-col cols="5"  class="subtitle-2">Mesa</v-col>
                        <v-col cols="7" class="font-weight-light">{{(user.table) ? user.table.name : 'sin mesa'}}</v-col>
                        <v-col cols="5"  class="subtitle-2">Turno</v-col>
                        <v-col cols="7" class="font-weight-light">{{(user.turn) ? user.turn.name : 'sin turno'}}</v-col>
                        <v-col cols="5"  class="subtitle-2">Gerente</v-col>
                        <v-col cols="7" class="font-weight-light">{{( user.table && user.table.manager )? user.table.manager.full_name : 'Disponible'}}</v-col>
                        <v-col cols="5"  class="subtitle-2">Coordinador</v-col>
                        <v-col cols="7" class="font-weight-light">{{(user.coordinator) ? user.coordinator.full_name :'Disponible' }}</v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <v-col class="text-center">
                    <v-subheader>
                        <v-row>
                            <v-col>Meta Dia</v-col>
                        </v-row>
                    </v-subheader>
                    <v-progress-circular
                        :rotate="-90"
                        :size="80"
                        :width="8"
                        :value="user.presence_day_sum_bonus * 100 / user.goal_day "
                        color="blue">
                            <v-row no-gutters>
                                <v-col cols="12" class="caption">{{ formatNumber(user.presence_day_sum_bonus) }}</v-col>
                                <v-col cols="12" class="title">{{user.goal_day}}</v-col>
                            </v-row>
                    </v-progress-circular>
                    <v-subheader>
                        <v-row>
                            <v-col class="title blue--text">${{ formatNumber(user.presence_day_sum_bonus*commission.value) }}</v-col>
                        </v-row>
                    </v-subheader>
                </v-col>  
                <v-col class="text-center">
                    <v-subheader>
                        <v-row>
                            <v-col>Perdidas</v-col>
                        </v-row>
                    </v-subheader>
                    <v-progress-circular
                        :rotate="-90"
                        :size="80"
                        :width="8"
                        :value=" (user.presence_day_sum_writeoff) "
                        color="red">
                        <v-row no-gutters>
                            <v-col cols="12" class="caption">
                                <v-tooltip bottom color="red">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-on="on" v-bind="attrs">{{ parseInt(user.presence_day_sum_writeoff || 0) }}</span>
                                    </template>
                                    <span>Perdidas Dia</span>
                                </v-tooltip>
                            </v-col>
                            <v-col cols="12" class="title">
                                <v-tooltip bottom color="red">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-on="on" v-bind="attrs">{{ parseInt(user.presence_month_sum_writeoff || 0) }}</span>
                                    </template>
                                    <span>Perdidas Mes</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-progress-circular>
                </v-col>
                <v-col class="text-center">
                    <v-subheader>
                        <v-row>
                            <v-col>Multas</v-col>
                        </v-row>
                    </v-subheader>
                    <v-progress-circular
                        :rotate="-90"
                        :size="80"
                        :width="8"
                        :value=" (user.presence_day_sum_writeoff) "
                        color="red">
                        <v-row no-gutters>
                            <!-- <v-col cols="12" class="caption">
                                <v-tooltip bottom color="red">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-on="on" v-bind="attrs">{{ parseInt(user.penalty_type_month.length || 0) }}</span>
                                    </template>
                                    <span>Multas Dia</span>
                                </v-tooltip>
                            </v-col> -->
                            <v-col cols="12" class="title">
                                <v-tooltip bottom color="red">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-on="on" v-bind="attrs">{{ parseInt(user.penalty_type_month.length || 0) }}</span>
                                    </template>
                                    <span>Multas Mes</span>
                                </v-tooltip>
                            </v-col>
                        </v-row>
                    </v-progress-circular>
                    <v-subheader>
                        <v-row>
                            <v-col class="title red--text">{{ parseFloat(user.penalty_type_month.reduce((acc,val)=> acc + parseFloat(val.amount), 0) || 0) }}</v-col>
                        </v-row>
                    </v-subheader>
                </v-col>
                <v-col class="text-center">
                    <v-subheader>
                        <v-row>
                            <v-col>Meta Mes</v-col>
                        </v-row>
                    </v-subheader>
                    <v-progress-circular
                        :rotate="-90"
                        :size="80"
                        :width="8"
                        :value="user.presence_month_sum_bonus * 100 / user.goal_month "
                        color="green">
                        <v-row no-gutters>
                            <v-col cols="12" class="caption">{{ formatNumber(user.presence_month_sum_bonus) }}</v-col>
                            <v-col cols="12" class="title">{{user.goal_month}}</v-col>
                        </v-row>
                    </v-progress-circular>
                    <v-subheader :class="penalty[0] > 0 ? 'h-auto' : ''">
                        <v-row>
                            <v-col v-if="penalty[0] > 0" cols="12" class="caption red--text text-decoration-line-through pb-0">${{ formatNumber(user.presence_month_sum_bonus*commission.value) }}</v-col>
                            <v-col v-if="penalty[0] > 0" cols="12" class="title green--text pt-0">${{ formatNumber(user.presence_month_sum_bonus*commission.value - penalty[1]) }}</v-col>
                            <v-col v-else cols="12" class="title green--text">${{ formatNumber(user.presence_month_sum_bonus*commission.value) }}</v-col>
                        </v-row>
                    </v-subheader>
                </v-col>    
            </v-row>

        </v-card-text>
    </v-card>
    
  
</template>

<script>
import AppData from '@mixins/AppData' 
export default {
 mixins:[ AppData],
    
    created()
    {
        this.list()
        this.reload()
    },

    computed:{
      commission() {
        const values = [{id:1, value:0.19},{id:2, value:0.20},{id:3, value:0.21}]
        const colombiaUsers = [122,208,266,294,309,326,338,339,350,368,372,218,386, 347,405,366, 403,404, 242,370, 227, 294,405,309,122,347,192,404,242,90,257,437,218, 326, 442, 439, 370, 446, 440, 449, 448, 306,460,452, 467]
        if(colombiaUsers.includes(this.user.id)){
            return {id:4, value:0.27}
        }
        return values.find((el)=>{ return this.user?.turn?.id === el.id }) ?? 0
      }
    },

    data: () => ({
        user: null,
        penalty: null,
        isReload: null   
    }),

    methods:
    {
        list()
        {
            this.getResource(`user/${this.userId}?with[]=profile&with[]=table&with[]=group&with[]=presenceDay`).then( data => {
                this.user = data;
                this.penalty = [ 
                    data.penalty_type_month.length,
                    parseFloat(data.penalty_type_month.reduce((acc,val)=> acc + parseFloat(val.amount), 0) || 0) 
                ]
            })
        },

        reload()
        {
            this.isReload = setInterval( () => {
                this.list();
            }, 30000 )
        },

        estimatePresence()
        {
           this.getResource(`userPresence/estimate`).then( data => {
               console.log(data)
            }) 
        }
    }

}
</script>

<style>
.h-auto {
    height: auto;
}

</style>