var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        bottom: "",
        left: "",
        "open-on-hover": "",
        "open-on-focus": "",
        "content-class": "rounded-xl",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  { attrs: { text: "", fab: "", depressed: "", small: "" } },
                  on
                ),
                [
                  _c("v-icon", { attrs: { color: _vm.color } }, [
                    _vm._v(_vm._s(_vm.icon)),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "v-list",
        { attrs: { dense: "", nav: "" } },
        _vm._l(_vm.items, function (item, i) {
          return _c(
            "v-list-item",
            { key: i, attrs: { dense: "", tile: "" } },
            [
              _c(
                "v-list-item-title",
                [
                  _c(
                    "v-icon",
                    { staticClass: "mr-3", attrs: { color: _vm.color } },
                    [_vm._v(_vm._s(_vm.icon))]
                  ),
                  _vm._v(" "),
                  _c("span", { staticClass: "my-2" }, [
                    _vm._v(_vm._s(_vm.itemText ? item[_vm.itemText] : item)),
                  ]),
                ],
                1
              ),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }