var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "rounded-lg main-color", attrs: { dark: "" } },
    [
      _c(
        "v-subheader",
        { staticClass: "subtitle-1" },
        [
          _c(
            "v-row",
            [
              _c("v-col", { attrs: { cols: "auto" } }, [
                _vm._v("\n              Asignar Agencias\n          "),
              ]),
              _vm._v(" "),
              _c("v-col", [
                _vm._v(
                  "\n              Usuario: " +
                    _vm._s(_vm.user.full_name) +
                    "\n          "
                ),
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", "x-small": "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closeDialog")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close-circle")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-divider"),
      _vm._v(" "),
      _c(
        "v-card-text",
        { staticClass: "pt-2 accounts-container custom-scroll" },
        [
          _c("v-autocomplete", {
            attrs: {
              label: "Agencias",
              "item-text": "name",
              "no-data-text": "No existen agencias Disponibles",
              items: _vm.agencies,
              disabled: _vm.loading,
              loading: _vm.loading,
              "hide-details": "",
              outlined: "",
              filled: "",
              dense: "",
              clearable: "",
              "open-on-clear": "",
              "return-object": "",
            },
            on: {
              change: function ($event) {
                return _vm.assing($event)
              },
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function ({ item }) {
                  return [
                    _c(
                      "v-row",
                      {
                        staticClass: "grey lighten-5 subtitle-1",
                        attrs: { dense: "" },
                      },
                      [
                        _c("v-col", [
                          _vm._v(
                            "\n            " +
                              _vm._s(item.name) +
                              "\n          "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(
                          "v-col",
                          { attrs: { cols: "auto" } },
                          [
                            _c("list-simple-icon", {
                              attrs: {
                                icon: "mdi-plus-circle",
                                label: "Agregar",
                                color: "success",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ]),
            model: {
              value: _vm.agency,
              callback: function ($$v) {
                _vm.agency = $$v
              },
              expression: "agency",
            },
          }),
          _vm._v(" "),
          _c(
            "v-list",
            {
              staticClass: "rounded-lg mt-1",
              attrs: { dense: "", color: "rgba(0,0,0,0.4)" },
            },
            _vm._l(_vm.userAgencies, function (userAgency) {
              return _c(
                "v-list-item",
                { key: userAgency.id },
                [
                  _c(
                    "v-list-item-avatar",
                    { attrs: { color: "amber", size: "30" } },
                    [_c("v-icon", [_vm._v("mdi-home-circle-outline")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        [
                          _c(
                            "v-row",
                            [
                              _c("v-col", [
                                _vm._v(
                                  "\n                    " +
                                    _vm._s(userAgency.name) +
                                    "\n                  "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-action",
                    [
                      _c(
                        "v-row",
                        [
                          _c(
                            "v-col",
                            [
                              _c(
                                "v-btn",
                                {
                                  attrs: {
                                    icon: "",
                                    small: "",
                                    loading: _vm.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.remove(userAgency)
                                    },
                                  },
                                },
                                [
                                  _c("v-icon", { attrs: { color: "red" } }, [
                                    _vm._v("mdi-delete"),
                                  ]),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }