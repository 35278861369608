import { render, staticRenderFns } from "./UserBoard.vue?vue&type=template&id=77f7644a&"
import script from "./UserBoard.vue?vue&type=script&lang=js&"
export * from "./UserBoard.vue?vue&type=script&lang=js&"
import style0 from "./UserBoard.vue?vue&type=style&index=0&id=77f7644a&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/eugenio/www/socialmedia-soporte/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('77f7644a')) {
      api.createRecord('77f7644a', component.options)
    } else {
      api.reload('77f7644a', component.options)
    }
    module.hot.accept("./UserBoard.vue?vue&type=template&id=77f7644a&", function () {
      api.rerender('77f7644a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/views/boards/operator/components/UserBoard.vue"
export default component.exports