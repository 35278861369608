var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { loading: _vm.loading, flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [_vm.rules.required],
                          label: "Agency Id",
                          placeholder: "Indique Agency Id",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.agency_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "agency_id", $$v)
                          },
                          expression: "form.agency_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [_vm.rules.required],
                          label: "Day Positive",
                          placeholder: "Indique Day Positive",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.day_positive,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "day_positive", $$v)
                          },
                          expression: "form.day_positive",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [_vm.rules.required],
                          label: "Day Negative",
                          placeholder: "Indique Day Negative",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.day_negative,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "day_negative", $$v)
                          },
                          expression: "form.day_negative",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [_vm.rules.required],
                          label: "Month Positive",
                          placeholder: "Indique Month Positive",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.month_positive,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "month_positive", $$v)
                          },
                          expression: "form.month_positive",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [_vm.rules.required],
                          label: "Month Negative",
                          placeholder: "Indique Month Negative",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.month_negative,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "month_negative", $$v)
                          },
                          expression: "form.month_negative",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [_vm.rules.required],
                          label: "Total Positive",
                          placeholder: "Indique Total Positive",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.total_positive,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "total_positive", $$v)
                          },
                          expression: "form.total_positive",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [_vm.rules.required],
                          label: "Total Negative",
                          placeholder: "Indique Total Negative",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.total_negative,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "total_negative", $$v)
                          },
                          expression: "form.total_negative",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [_vm.rules.required],
                          label: "Task Mails",
                          placeholder: "Indique Task Mails",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.task_mails,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "task_mails", $$v)
                          },
                          expression: "form.task_mails",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [_vm.rules.required],
                          label: "Task Photos",
                          placeholder: "Indique Task Photos",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.task_photos,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "task_photos", $$v)
                          },
                          expression: "form.task_photos",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [_vm.rules.required],
                          label: "Task Videos",
                          placeholder: "Indique Task Videos",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.task_videos,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "task_videos", $$v)
                          },
                          expression: "form.task_videos",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [_vm.rules.max(80)],
                          label: "Comments",
                          placeholder: "Indique Comments",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.comments,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "comments", $$v)
                          },
                          expression: "form.comments",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c("form-buttons", {
                attrs: {
                  action: _vm.action,
                  valid: _vm.valid,
                  loading: _vm.loading,
                },
                on: {
                  update: function ($event) {
                    return _vm.update()
                  },
                  store: function ($event) {
                    return _vm.store()
                  },
                  clear: function ($event) {
                    return _vm.clear()
                  },
                  cancel: function ($event) {
                    return _vm.cancel()
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.$App.debug ? _c("pre", [_vm._v(_vm._s(_vm.$data))]) : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }