<template>

    <v-container fluid class="white"  >

            <v-row >

                <v-flex xs12>

                        <v-card min-height="92vh" :class="`mx-1 mt-1 list-container ${(inDialog) ? 'rounded-xl' : null }`" :flat="inDialog">

                            <v-toolbar flat dense v-if="!inDialog" height="40" class="main-color" dark>

                                <v-toolbar-title v-text="title"></v-toolbar-title>

                                <v-spacer></v-spacer>

                                <slot name="HeadTools"></slot>

                                <list-menu color="white" :itemsMenu="itemsMenu" @onMenu="$emit('onMenu', $event)"></list-menu>

                            </v-toolbar>

                            <v-card-text class="my-0">
                                <slot></slot>
                            </v-card-text>

                        </v-card>

                </v-flex>

            </v-row>

    </v-container>

</template>

<script>
import ListMenu from './ListMenu'
export default 
{
    name: 'list-container',
    
    components: {
        'list-menu': ListMenu
    },

    props:{
        title:{
            type: String,
            default: ''
        },
        headColor:{
            type: String,
            default: ''
        },
        textColor:{
            type: String,
            default: ''
        },
        loading:{
            type: Boolean,
            default: false
        },
        help:{
            type: Array,
            default: () => []
        },
        itemsMenu:{
            type: Array,
            default: () => [
                {
                    label: 'Actualizar',
                    icon:  'mdi-table-refresh',
                    action: 'refresh'
                }
            ]
        },
    },

}
</script>
