var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "rounded-lg px-3 py-3",
      attrs: { color: "rgba(0,0,0,0.4)", dark: "" },
    },
    [
      _vm._l(_vm.progress, function (progres) {
        return _c(
          "v-row",
          { key: progres.id, attrs: { dense: "" } },
          [
            _c("v-col", { staticClass: "sm-text" }, [
              _vm._v("\n         " + _vm._s(progres.name) + "\n       "),
            ]),
            _vm._v(" "),
            _c(
              "v-col",
              [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "", color: "blue" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-progress-linear",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "caption",
                                      attrs: {
                                        height: "15",
                                        value: _vm.getPercent(
                                          progres.day.points,
                                          progres.goal_day
                                        ),
                                        indeterminate: _vm.loading,
                                      },
                                    },
                                    "v-progress-linear",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _vm._v(
                                    "\n               " +
                                      _vm._s(
                                        _vm.formatNumber(progres.day.points) ||
                                          0
                                      ) +
                                      "\n             "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _vm._v(" "),
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("Meta Dia " + _vm._s(progres.goal_day)),
                    ]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "v-col",
              [
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "", color: "green" },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-progress-linear",
                                _vm._g(
                                  _vm._b(
                                    {
                                      staticClass: "caption",
                                      attrs: {
                                        color: "green",
                                        height: "15",
                                        value: _vm.getPercent(
                                          progres.month.points,
                                          progres.goal_month
                                        ),
                                        indeterminate: _vm.loading,
                                      },
                                    },
                                    "v-progress-linear",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [
                                  _vm._v(
                                    " \n           " +
                                      _vm._s(
                                        _vm.formatNumber(
                                          progres.month.points
                                        ) || 0
                                      ) +
                                      "\n         "
                                  ),
                                ]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  [
                    _vm._v(" "),
                    _c("span", { staticClass: "font-weight-bold" }, [
                      _vm._v("Meta Mes " + _vm._s(progres.goal_month)),
                    ]),
                  ]
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c("v-col", { staticClass: "caption" }, [
            _vm._v("\n        Total\n       "),
          ]),
          _vm._v(" "),
          _c(
            "v-col",
            {},
            [
              _c(
                "v-progress-linear",
                {
                  staticClass: "caption",
                  attrs: {
                    height: "15",
                    color: "blue darken-1",
                    value: _vm.getPercent(_vm.totals.day.points, 2000),
                    indeterminate: _vm.loading,
                  },
                },
                [
                  _vm._v(
                    "\n           " +
                      _vm._s(_vm.formatNumber(_vm.totals.day.points) || 0) +
                      "\n         "
                  ),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            {},
            [
              _c(
                "v-progress-linear",
                {
                  staticClass: "caption",
                  attrs: {
                    color: "green darken-1",
                    height: "15",
                    value: _vm.getPercent(_vm.totals.month.points, 60000),
                    indeterminate: _vm.loading,
                  },
                },
                [
                  _vm._v(
                    "\n           " +
                      _vm._s(_vm.formatNumber(_vm.totals.month.points) || 0) +
                      "\n         "
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-row",
        { attrs: { "no-gutters": "" } },
        [
          _c("v-col", { attrs: { cols: "4" } }),
          _vm._v(" "),
          _c("v-col", { attrs: { cols: "4" } }),
          _vm._v(" "),
          _c(
            "v-col",
            { staticClass: "pt-n1", attrs: { cols: "4" } },
            _vm._l(_vm.goalType, function (goal) {
              return _c(
                "v-tooltip",
                {
                  key: goal.id,
                  attrs: { bottom: "", color: goal.color },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "v-progress-circular",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "pa-0 my-0 mx-1",
                                    attrs: {
                                      value: _vm.getPercent(
                                        _vm.totals.month.points,
                                        goal.amount
                                      ),
                                      size: 13,
                                      color: goal.color,
                                      width: "4",
                                    },
                                  },
                                  "v-progress-circular",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [
                                _vm.getPercent(
                                  _vm.totals.month.points,
                                  goal.amount
                                ) >= 100
                                  ? _c(
                                      "v-icon",
                                      {
                                        attrs: { color: "success", size: "15" },
                                      },
                                      [_vm._v("mdi-check-circle-outline")]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    true
                  ),
                },
                [
                  _vm._v(" "),
                  _c("span", { staticClass: "indigo--text font-weight-bold" }, [
                    _vm._v(
                      _vm._s(goal.name) +
                        " " +
                        _vm._s(_vm.formatNumber(_vm.totals.month.points)) +
                        " / " +
                        _vm._s(_vm.formatNumber(goal.amount)) +
                        " (" +
                        _vm._s(
                          _vm.formatNumber(
                            _vm.getPercent(_vm.totals.month.points, goal.amount)
                          )
                        ) +
                        "%)"
                    ),
                  ]),
                ]
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }