var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "rounded-lg",
      attrs: { dark: "", color: "rgba(0,0,0,0.4)" },
    },
    [
      _c(
        "v-subheader",
        [
          _c(
            "v-row",
            { staticClass: "title" },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-account-multiple-outline"),
                  ]),
                  _vm._v("  Mis Contactos - Chats Recientes\n          "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    { attrs: { icon: "", loading: _vm.loading } },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { small: "" },
                          on: {
                            click: function ($event) {
                              return _vm.list()
                            },
                          },
                        },
                        [_vm._v("mdi-reload")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        { staticClass: "pt-0 accounts-container custom-scroll" },
        [
          _c(
            "v-list",
            {
              staticClass: "rounded-lg",
              attrs: {
                subheader: "",
                "two-line": "",
                dense: "",
                color: "rgba(0,0,0,0.4)",
              },
            },
            _vm._l(_vm.clients, function (client) {
              return _c(
                "v-list-item",
                {
                  key: client["event-id"],
                  attrs: {
                    value: client,
                    color: client.fresh > 0 ? "red" : "blue",
                  },
                },
                [
                  _c(
                    "v-list-item-avatar",
                    { attrs: { color: "blue", size: "60" } },
                    [
                      _c("v-img", {
                        attrs: {
                          src: `https://api.amolatina.com/users/${client["user-id"]}/photos/${client["user-details"].thumbnail}.190x180.thumb-fd`,
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-content",
                    [
                      _c(
                        "v-list-item-title",
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(client["user-details"].name) +
                                      "\n                "
                                  ),
                                  client.fresh > 0
                                    ? _c(
                                        "v-badge",
                                        {
                                          attrs: {
                                            content: client.fresh,
                                            inline: "",
                                            color: "error",
                                          },
                                        },
                                        [
                                          _c("v-icon", {
                                            attrs: {
                                              icon:
                                                client.type == "message"
                                                  ? "mdi-message-bulleted"
                                                  : "mdi-email-send-outline",
                                              size: 22,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                { class: client.fresh > 0 ? "" : "caption" },
                                [_vm._v(_vm._s(client.payload.text))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-subtitle",
                        { staticClass: "pt-2" },
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c("v-col", [
                                _vm._v("ID: " + _vm._s(client["user-id"])),
                              ]),
                              _vm._v(" "),
                              _c(
                                "router-link",
                                {
                                  attrs: {
                                    to: {
                                      name: "client-chat",
                                      params: {
                                        client_id: client["user-id"],
                                        profile_id: _vm.profile_id,
                                      },
                                    },
                                    target: "_blank",
                                  },
                                },
                                [
                                  client.type == "message"
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "auto" } },
                                        [
                                          _c("list-simple-icon", {
                                            attrs: {
                                              icon: "mdi-message-bulleted",
                                              label: "Email",
                                              color: "blue darken-3",
                                              size: 22,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  client.type == "letter"
                                    ? _c(
                                        "v-col",
                                        { attrs: { cols: "auto" } },
                                        [
                                          _c("list-simple-icon", {
                                            attrs: {
                                              icon: "mdi-email-send-outline",
                                              label: "Email",
                                              color: "blue darken-3",
                                              size: 22,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-list-item-icon"),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }