var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "365", "content-class": "rounded-xl" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g({ attrs: { icon: "" } }, on),
                    [_c("v-icon", [_vm._v("help")])],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _vm._v(" "),
          _c(
            "v-card",
            { staticClass: "rounded-xl", attrs: { loading: _vm.loading } },
            [
              _c("app-simple-toolbar", {
                attrs: { title: "Ayuda" },
                on: {
                  closeModal: function ($event) {
                    _vm.showDialog = false
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "v-list",
                { attrs: { dense: "", width: "100%" } },
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-avatar",
                        { attrs: { color: "grey lighten-4" } },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "green", size: "38" } },
                            [_vm._v("mdi-head-cog")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [_c("v-list-item-title", [_vm._v("Asistente")])],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-action",
                        [
                          _c("v-switch", {
                            attrs: {
                              "hide-details": "",
                              inset: "",
                              loading: _vm.loading,
                              color: "green",
                            },
                            model: {
                              value: _vm.showAsistente,
                              callback: function ($$v) {
                                _vm.showAsistente = $$v
                              },
                              expression: "showAsistente",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }