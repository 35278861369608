var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.user
    ? _c(
        "v-card",
        {
          staticClass: "rounded-lg",
          attrs: { dark: "", color: "rgba(0,0,0,0.4)" },
        },
        [
          _c(
            "v-card-title",
            { staticClass: "subtitle-2" },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    [
                      _vm._v(
                        _vm._s(_vm.user.name) +
                          " (" +
                          _vm._s(_vm.user.username) +
                          ") "
                      ),
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", loading: _vm.loading, small: "" },
                          on: {
                            click: function ($event) {
                              return _vm.list()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-refresh")])],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        { attrs: { icon: "", color: "success" } },
                        [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-avatar",
                        {
                          staticClass: "elevation-2",
                          attrs: { color: "blue", size: "70" },
                        },
                        [
                          _c("v-img", {
                            attrs: {
                              src: `/storage/photo/operator/${
                                _vm.user.photo || "nophoto.png"
                              }`,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "subtitle-2", attrs: { cols: "5" } },
                            [_vm._v("Rol")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "font-weight-light",
                              attrs: { cols: "7" },
                            },
                            [_vm._v(_vm._s(_vm.user.role.name))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "subtitle-2", attrs: { cols: "5" } },
                            [_vm._v("Grupo")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "font-weight-light",
                              attrs: { cols: "7" },
                            },
                            [_vm._v(_vm._s(_vm.user.group.name))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "subtitle-2", attrs: { cols: "5" } },
                            [_vm._v("Mesa")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "font-weight-light",
                              attrs: { cols: "7" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.user.table
                                    ? _vm.user.table.name
                                    : "sin mesa"
                                )
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "subtitle-2", attrs: { cols: "5" } },
                            [_vm._v("Gerente")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "font-weight-light",
                              attrs: { cols: "7" },
                            },
                            [
                              _vm._v(
                                _vm._s(
                                  _vm.user.table.manager
                                    ? _vm.user.table.manager.full_name
                                    : "Disponible"
                                )
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                { attrs: { "no-gutters": "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c("v-subheader", [_vm._v("Meta Dia")]),
                      _vm._v(" "),
                      _c(
                        "v-progress-circular",
                        {
                          attrs: {
                            rotate: -90,
                            size: 75,
                            width: 8,
                            value:
                              (_vm.user.presence_day_sum_bonus * 100) /
                              _vm.user.goal_day,
                            color: "blue",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "caption",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatNumber(
                                        _vm.user.presence_day_sum_bonus
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { staticClass: "title", attrs: { cols: "12" } },
                                [_vm._v(_vm._s(_vm.user.goal_day))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "v-subheader",
                        [_c("v-row", [_c("v-col", [_vm._v("Perdidas")])], 1)],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-progress-circular",
                        {
                          attrs: {
                            rotate: -90,
                            size: 75,
                            width: 8,
                            value: _vm.user.presence_month_sum_writeoff,
                            color: "red",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "caption",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "", color: "red" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "span",
                                                  _vm._g(
                                                    _vm._b(
                                                      {},
                                                      "span",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        parseInt(
                                                          _vm.user
                                                            .presence_day_sum_writeoff ||
                                                            0
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        3331312121
                                      ),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Perdidas Dia")]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { staticClass: "title", attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-tooltip",
                                    {
                                      attrs: { bottom: "", color: "red" },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "activator",
                                            fn: function ({ on, attrs }) {
                                              return [
                                                _c(
                                                  "span",
                                                  _vm._g(
                                                    _vm._b(
                                                      {},
                                                      "span",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        parseInt(
                                                          _vm.user
                                                            .presence_month_sum_writeoff ||
                                                            0
                                                        )
                                                      )
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        2927410037
                                      ),
                                    },
                                    [
                                      _vm._v(" "),
                                      _c("span", [_vm._v("Perdidas Mes")]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "text-center" },
                    [
                      _c(
                        "v-subheader",
                        [_c("v-row", [_c("v-col", [_vm._v("Meta Mes")])], 1)],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-progress-circular",
                        {
                          attrs: {
                            rotate: -90,
                            size: 75,
                            width: 8,
                            value:
                              (_vm.user.presence_month_sum_bonus * 100) /
                              _vm.user.goal_month,
                            color: "green",
                          },
                        },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c(
                                "v-col",
                                {
                                  staticClass: "caption",
                                  attrs: { cols: "12" },
                                },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.formatNumber(
                                        _vm.user.presence_month_sum_bonus
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { staticClass: "title", attrs: { cols: "12" } },
                                [_vm._v(_vm._s(_vm.user.goal_month))]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-row", [_c("v-col")], 1),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }