<template>

    <v-form ref="form" v-model="valid" lazy-validation>

    <v-card :loading="loading" flat>

        <v-card-text>

        <v-row>

                 
        <v-col cols="12" md="6">
            <v-text-field
                :rules="[rules.required]"
                v-model="form.name"
                label="Nombre"
                placeholder="Indique Nombre"
                dense
            ></v-text-field>
        </v-col>
                  
        <v-col cols="12" md="6">
            <v-text-field
                :rules="[rules.required]"
                v-model="form.amolatina_id"
                label="Amolatina Id"
                placeholder="Indique Amolatina Id"
                dense
            ></v-text-field>
        </v-col>
                  
        <v-col cols="12" md="6">
            <v-text-field
                :rules="[rules.required]"
                v-model="form.user"
                label="Correo"
                placeholder="Indique Correo"
                dense
            ></v-text-field>
        </v-col>
                  
        <v-col cols="12" md="6">
            <v-text-field
                :rules="[rules.required]"
                v-model="form.password"
                label="Password"
                placeholder="Indique Password"
                dense
            ></v-text-field>
        </v-col>

        <v-col cols="12" md="6">
            <v-menu
                v-model="pickers.register_at"
                :close-on-content-click="false"
                min-width="290px"
            >
                <template v-slot:activator="{ on }">
                    <v-text-field
                        v-model="dates.register_at"
                        :rules="[rules.required]"
                        label="Fecha Creación"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                        dense
                    ></v-text-field>
                </template>
                <v-date-picker 
                    v-model="form.register_at" 
                    @input="dates.register_at = formatPicker(form.register_at, 'register_at')">
                </v-date-picker>
            </v-menu>
        </v-col>
    
        <v-col cols="12" md="6">
            <v-select
            :items="selects.provider"
            item-text="name"
            item-value="id"
            v-model="form.provider_id"
            :rules="[rules.select]"
            label="Página"
            :loading="loading"
            dense
            ></v-select>
        </v-col>

        </v-row>

        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <form-buttons
                @update="update()"
                @store="store()"
                @clear="clear()"
                @cancel="cancel()"
                :action="action"
                :valid="valid"
                :loading="loading"
            ></form-buttons>
        </v-card-actions>

        <pre v-if="$App.debug">{{ $data }}</pre>

    </v-card>
    
    </v-form>

</template>

<script>

import AppForm from '@mixins/AppForm';

export default {
    mixins: [AppForm],
    data() {
        return {
            resource: 'agency',
            dates:
            {
                register_at: 	 null,
            },
            pickers:
            {
                register_at: 	 null,
            },
            form:
            {
                id: 	null,
				name: 	null,
				parent_id: 	null,
				user: 	null,
				password: 	null,
				amolatina_id: 	null,
				comments: 	null,
				status_id: 	null,
				user_id: 	null,
                register_at: null,
                provider_id: null,
            },
            selects:
            {
                provider: ['']
            },
            default: {
                parent_id: 0,
            }
        }
    },

    methods:
    {

    }
}
</script>

<style>
</style>