var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-expansion-panel-content",
    [
      _c(
        "v-subheader",
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", [_vm._v("Detalle")]),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c(
                    "v-icon",
                    { attrs: { size: "16", color: "green", left: "" } },
                    [_vm._v("mdi-cash-plus")]
                  ),
                  _vm._v(
                    "\n            " +
                      _vm._s(_vm.formatNumber(_vm.bonus || 0.0)) +
                      "\n         "
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c(
                    "v-icon",
                    {
                      staticClass: "ml-2",
                      attrs: { size: "16", color: "red", left: "" },
                    },
                    [_vm._v("mdi-cash-remove")]
                  ),
                  _vm._v(
                    "\n            " + _vm._s(_vm.writeoff || 0) + "\n         "
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.comissions, function (comission) {
        return _c(
          "v-row",
          { key: comission.comission_id },
          [
            _c(
              "v-col",
              { attrs: { cols: "auto" } },
              [
                _c(
                  "v-avatar",
                  {
                    staticClass: "elevation-3",
                    attrs: { color: "blue", size: 30 },
                  },
                  [
                    _c("v-img", {
                      attrs: {
                        src: `/storage/photo/client/${
                          comission.client
                            ? comission.client.photo || "nophoto"
                            : "nophoto"
                        }.jpg`,
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("v-col", [
              _vm._v(
                "\n            " +
                  _vm._s(
                    comission.client
                      ? comission.client.name || comission.client.amolatina_id
                      : comission.client_id
                  ) +
                  "\n        "
              ),
            ]),
            _vm._v(" "),
            _c("v-col", [
              _vm._v(
                "\n            " +
                  _vm._s(
                    comission.comission_at
                      ? _vm.UTCToLocalDate(comission.comission_at)
                      : "0000"
                  ) +
                  " \n        "
              ),
            ]),
            _vm._v(" "),
            _c(
              "v-col",
              { class: (comission.positive = 1) ? "green--text" : "red--text" },
              [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.formatNumber(comission.points)) +
                    "\n        "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "v-col",
              [
                comission.has_service
                  ? _c("list-simple-icon", {
                      attrs: {
                        size: 24,
                        icon: comission.has_service.icon,
                        label: comission.has_service.name,
                        color: comission.has_service.color,
                      },
                    })
                  : _c("list-simple-icon", {
                      attrs: {
                        size: 24,
                        icon: "mdi-comment-question",
                        label: comission.service,
                        color: "amber",
                      },
                    }),
              ],
              1
            ),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c(
        "v-overlay",
        { attrs: { opacity: 0.4, value: _vm.loading } },
        [
          _c("v-icon", { staticClass: "mdi-spin", attrs: { size: "50" } }, [
            _vm._v("mdi-loading"),
          ]),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }