var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "main-color", attrs: { dark: "" } },
    [
      _c(
        "v-card-title",
        { staticClass: "py-2 px-2" },
        [
          _c(
            "v-row",
            { staticClass: "subtitle-1", attrs: { dense: "" } },
            [
              _c("v-col", [_vm._v("Recalcular Progreso")]),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        loading: _vm.loading,
                        icon: "",
                        "x-small": "",
                        dark: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closeDialog")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close-circle")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        { staticClass: "custom-scroll" },
        [
          _c("v-date-picker", {
            staticClass: "rounded-lg",
            attrs: {
              "no-title": "",
              scrollable: "",
              "full-width": "",
              color: "indigo",
              elevation: "3",
              "first-day-of-week": 1,
              max: _vm.maxDay,
              light: "",
            },
            model: {
              value: _vm.day,
              callback: function ($$v) {
                _vm.day = $$v
              },
              expression: "day",
            },
          }),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              staticClass: "mt-2",
              attrs: {
                loading: _vm.loading,
                small: "",
                block: "",
                color: "orange",
                dark: "",
              },
              on: {
                click: function ($event) {
                  return _vm.confirmRebuild()
                },
              },
            },
            [_vm._v("Recalcular")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }