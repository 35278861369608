var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app-bar",
    {
      attrs: {
        app: "",
        color: "rgba(0, 0, 0, 0.3)",
        dark: "",
        flat: "",
        height: "45",
        "hide-on-scroll": "",
      },
    },
    [
      _c(
        "v-toolbar-title",
        {
          staticClass: "font-weight-bold",
          on: {
            click: function ($event) {
              return _vm.navigateToName("home")
            },
          },
        },
        [
          _c("div", { staticClass: "logo-titulo mx-3" }, [
            _vm._v(_vm._s(_vm.$App.title)),
          ]),
        ]
      ),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          attrs: { icon: "", small: "", color: "red" },
          on: { click: _vm.logout },
        },
        [_c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-export")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }