var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-list-item",
    { attrs: { value: _vm.client.id } },
    [
      _c(
        "v-list-item-avatar",
        { attrs: { color: "blue", size: "60" } },
        [
          _c("v-img", {
            attrs: {
              src: `https://api3.amolatina.com/users/${_vm.client.id}/photos/${
                _vm.client.thumbnail || "nophoto"
              }.215x180.thumb-fd`,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-list-item-content",
        [
          _c(
            "v-list-item-title",
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c(
                    "v-col",
                    { staticClass: "text-capitalize", attrs: { cols: "auto" } },
                    [
                      _vm._v(
                        "\n                    " +
                          _vm._s(_vm.client.name) +
                          ", "
                      ),
                      _c("span", { staticClass: "font-weight-black" }, [
                        _vm._v(_vm._s(_vm.client.birthday.age)),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            color: _vm.client.status == 1 ? "green" : "red",
                            size: "14",
                          },
                        },
                        [
                          _vm._v(
                            " \n                        " +
                              _vm._s(
                                _vm.client.status == 1
                                  ? "mdi-checkbox-blank-circle"
                                  : "mdi-checkbox-blank-circle-outline"
                              ) +
                              "\n                    "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { staticClass: "caption", attrs: { cols: "auto" } },
                    [
                      _vm._v(
                        "\n                   " + _vm._s(_vm.client.id) + " "
                      ),
                      _c(
                        "v-icon",
                        {
                          attrs: { size: "16", dark: "", color: "amber" },
                          on: {
                            click: function ($event) {
                              return _vm.copyText(_vm.client.id, "ID")
                            },
                          },
                        },
                        [_vm._v("mdi-content-copy")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-list-item-subtitle",
            { staticClass: "pt-2" },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-col", [
                    _vm._v(
                      "\n                    " +
                        _vm._s(_vm.client.location.city) +
                        " (" +
                        _vm._s(_vm.client.location.country) +
                        ")\n                "
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-list-item-icon",
        [_c("v-icon", { staticClass: "mt-6" }, [_vm._v("mdi-dots-vertical")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }