var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "rounded-lg",
      attrs: { dark: "", color: "rgba(0,0,0,0.4)" },
    },
    [
      _c(
        "v-subheader",
        [
          _c(
            "v-row",
            { staticClass: "title" },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("v-icon", { attrs: { left: "" } }, [
                    _vm._v("mdi-table-furniture"),
                  ]),
                  _vm._v("  Perfiles Mesa\n          "),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    { attrs: { icon: "", loading: _vm.loading } },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { small: "" },
                          on: {
                            click: function ($event) {
                              return _vm.list()
                            },
                          },
                        },
                        [_vm._v("mdi-reload")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-spacer"),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        { staticClass: "pt-0 accounts-container custom-scroll" },
        [
          _c(
            "v-list",
            {
              staticClass: "rounded-lg",
              attrs: {
                subheader: "",
                "two-line": "",
                dense: "",
                color: "rgba(0,0,0,0.4)",
              },
            },
            [
              _c(
                "v-list-item-group",
                { attrs: { color: "green" } },
                _vm._l(_vm.profiles, function (profile) {
                  return _c(
                    "v-list-item",
                    {
                      key: profile.id,
                      staticClass: "px-2",
                      attrs: { value: profile, inactive: "" },
                    },
                    [
                      _c(
                        "v-list-item-avatar",
                        { attrs: { color: "blue", size: "60" } },
                        [
                          _c("v-img", {
                            attrs: {
                              src: `/storage/photo/profile/${
                                profile.photo || "nophoto"
                              }.jpg`,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-title",
                            [
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "", color: "blue" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g(
                                                        _vm._b(
                                                          {},
                                                          "span",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            profile.presence
                                                              ? _vm.formatNumber(
                                                                  profile
                                                                    .presence
                                                                    .bonus
                                                                ) || 0
                                                              : 0
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v("Ganacias Sesion"),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "", color: "red" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      _vm._g(
                                                        _vm._b(
                                                          {},
                                                          "span",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      ),
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            profile.presence
                                                              ? parseInt(
                                                                  profile
                                                                    .presence
                                                                    .writeoff ||
                                                                    0
                                                                )
                                                              : 0
                                                          )
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v("Perdidas Sesion"),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-row",
                                { attrs: { "no-gutters": "" } },
                                [
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-progress-linear", {
                                        staticClass: "mb-2 mt-1",
                                        attrs: {
                                          value: profile.presence
                                            ? _vm.formatNumber(
                                                profile.presence.bonus
                                              ) || 0
                                            : 0,
                                          color: "blue",
                                          height: "8",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    [
                                      _c("v-progress-linear", {
                                        staticClass: "mb-2 mt-1",
                                        attrs: {
                                          value: profile.presence
                                            ? parseInt(
                                                profile.presence.writeoff || 0
                                              )
                                            : 0,
                                          color: "red",
                                          height: "8",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "pt-2" },
                            [
                              _c(
                                "v-row",
                                { attrs: { dense: "" } },
                                [
                                  _c("v-col", [_vm._v(_vm._s(profile.name))]),
                                  _vm._v(" "),
                                  profile.presence &&
                                  !_vm.myProfilesStarted.includes(profile.id)
                                    ? _c(
                                        "v-col",
                                        {
                                          attrs: { cols: "auto" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.confirmStop(profile)
                                            },
                                          },
                                        },
                                        [
                                          _c("list-simple-icon", {
                                            attrs: {
                                              icon: "mdi-lock-open-variant",
                                              label: "Liberar Perfil",
                                              color: "amber darken-3",
                                              size: 22,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm.myProfilesStarted.includes(profile.id)
                                    ? _c(
                                        "v-col",
                                        {
                                          attrs: { cols: "auto" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.showDetails(profile)
                                            },
                                          },
                                        },
                                        [
                                          _c("list-simple-icon", {
                                            attrs: {
                                              icon: "mdi-magnify",
                                              label: "Detalles Puntos",
                                              color: "blue darken-3",
                                              size: 22,
                                            },
                                          }),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            color: profile.presence
                                              ? "green"
                                              : "red",
                                            size: "20",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " \n                    " +
                                              _vm._s(
                                                profile.presence
                                                  ? "mdi-checkbox-blank-circle"
                                                  : "mdi-checkbox-blank-circle-outline"
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-icon",
                        { staticClass: "pt-4" },
                        [
                          profile.presence &&
                          _vm.myProfilesStarted.includes(profile.id)
                            ? _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-small": "",
                                    fab: "",
                                    color: "red",
                                    loading: _vm.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.stopPresence(profile)
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-stop")])],
                                1
                              )
                            : profile.presence &&
                              !_vm.myProfilesStarted.includes(profile.id)
                            ? _c(
                                "v-tooltip",
                                {
                                  attrs: { bottom: "", color: "red" },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "activator",
                                        fn: function ({ on, attrs }) {
                                          return [
                                            _c(
                                              "v-btn",
                                              {
                                                staticClass: "no-drop",
                                                attrs: { icon: "" },
                                              },
                                              [
                                                _c(
                                                  "v-icon",
                                                  _vm._g(
                                                    _vm._b(
                                                      {
                                                        attrs: {
                                                          color: "red",
                                                          size: "32",
                                                        },
                                                      },
                                                      "v-icon",
                                                      attrs,
                                                      false
                                                    ),
                                                    on
                                                  ),
                                                  [_vm._v("mdi-lock")]
                                                ),
                                              ],
                                              1
                                            ),
                                          ]
                                        },
                                      },
                                    ],
                                    null,
                                    true
                                  ),
                                },
                                [
                                  _vm._v(" "),
                                  _c(
                                    "span",
                                    [
                                      _c(
                                        "v-icon",
                                        { attrs: { size: "16", left: "" } },
                                        [_vm._v("mdi-account")]
                                      ),
                                      _vm._v(
                                        "   " +
                                          _vm._s(
                                            profile.presence.user.full_name
                                          )
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              )
                            : _c(
                                "v-btn",
                                {
                                  attrs: {
                                    "x-small": "",
                                    fab: "",
                                    color: "success",
                                    loading: _vm.loading,
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.startPresence(profile)
                                    },
                                  },
                                },
                                [_c("v-icon", [_vm._v("mdi-play")])],
                                1
                              ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("app-confirm", {
        attrs: { confirm: _vm.confirm, title: _vm.title, message: _vm.message },
        on: {
          closeConfirm: function ($event) {
            return _vm.confirmation($event)
          },
        },
      }),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", width: "90vw" },
          model: {
            value: _vm.dialogDetail,
            callback: function ($$v) {
              _vm.dialogDetail = $$v
            },
            expression: "dialogDetail",
          },
        },
        [
          _vm.dialogDetail
            ? _c("UserPrecenseList", {
                attrs: { profile: _vm.profile },
                on: {
                  closeDialog: function ($event) {
                    return _vm.closeDialog()
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }