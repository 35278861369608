<template>

   <v-card color="transparent" flat dark min-height="93vh">
    <v-card-text>
        <v-row dense>
            <v-col cols="auto">

                <v-card color="rgba(0,0,0,0.4)" width="20vw" class="pa-2">

                    <v-subheader>Regalos</v-subheader>

                <v-row no-gutters>
                    <v-col>
                        <v-select
                            v-model="agency"
                            label="Agencias"
                            item-text="name"
                            :items="agencies"
                            :loading="loading"
                            hide-details
                            outlined
                            filled
                            dense
                            return-object
                            @change="list()"
                            autofocus
                            class="mb-1"
                            :rules="[rules.required]"
                        ></v-select>
                    </v-col>

                    <v-col cols="12">
                        <PresentCalendar @onUpdateDate="setDay($event)"></PresentCalendar>
                    </v-col>

                </v-row>
                </v-card>

            </v-col>

            <v-col>
                <v-card color="rgba(0,0,0,0.4)" class="pa-2" max-height="92vh" mix-height="88vh">
                    <v-card-title class="pa-1">
                        <v-row no-gutters>
                            <v-col cols="auto">Regalos: {{ total }}</v-col>
                        </v-row>
                    </v-card-title>
                    <v-card-text class="comission-container custom-scroll">
                        <v-row dense v-for="present in presents" :key="present.id">
                            <v-col cols="12" >
                                <v-card color="rgba(0,0,0,0.4)" class="ma-1 rounded-lg">
                                    <v-row dense>
                                        <v-col cols="2">
                                            <v-row no-gutters >
                                                <v-col cols="12" class="caption text-center">{{present.sender_id}}</v-col>
                                                <v-col cols="12" class="text-center">
                                                    <v-row no-gutters justify="center" v-if="present">
                                                        <v-col cols="auto">
                                                            <v-avatar color="blue" class="elevation-3" :size="30">
                                                                <v-img :src="`https://api0.amolatina.com/users/${present.sender_id}/photos/${present.sender_thumbnail || 'nophoto'}.80x80.thumb-fd`" ></v-img>
                                                            </v-avatar>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                                <v-col cols="12" class="caption text-center">{{present.sender_name}}</v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col cols="auto" class="text-center" v-for="item in present.items" :key="item.id">
                                            <v-row no-gutters >
                                                <v-col cols="12" class="text-detail" :class="'white--text'" />
                                                <v-col cols="12">
                                                    <list-simple-icon  
                                                        :size="32"
                                                        icon="mdi-gift"
                                                        :label="item.group"
                                                        :color="present.delivered === null ? 'red' : 'green'"
                                                    > </list-simple-icon>
                                                </v-col>
                                                <v-col cols="12" class="text-detail" :class="'white--text'">
                                                    {{ formatNumber(item.points) }}$
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                        <v-col  cols="auto" class="text-center">
                                            <v-row no-gutters>
                                                <v-col cols="12" class="text-detail" :class="'white--text'" />
                                                <v-col cols="12">
                                                    <list-simple-icon  
                                                        :size="32"
                                                        icon="mdi-message"
                                                        :label="present.text"
                                                        color="blue"
                                                    > </list-simple-icon>
                                                </v-col>
                                                <v-col cols="12" class="text-detail" :class="'white--text'">
                                                    Mensaje
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                        <v-spacer></v-spacer>

                                        <v-col  cols="3" class="text-center">

                                            <v-row no-gutters>
                                                <v-col cols="12" class="caption text-center" :class="'white--text'">
                                                    Recibido: {{ new Date(present.timestamp).toLocaleString() }}
                                                </v-col>
                                                <v-col cols="12" v-if="present.delivered !== null" class="caption text-detail" :class="'green--text'">
                                                    Enviado: {{ new Date(present.delivered).toLocaleString() }}
                                                </v-col>
                                                <v-col cols="12" v-else class="caption text-detail" :class="'red--text'">
                                                    Fecha Límite: {{ new Date(present.deadline).toLocaleString() }}
                                                </v-col>
                                            </v-row>
                                            
                                        </v-col>
                                        <v-col cols="2">
                                            <v-row no-gutters>
                                                <v-col cols="12" class="caption text-center">{{present.recipient_id}}</v-col>
                                                <v-col cols="12" class="text-center">
                                                    <v-avatar color="grey" class="elevation-3" :size="30">
                                                        <v-img :src="`https://api0.amolatina.com/users/${present.recipient_id}/photos/${present.recipient_thumbnail || 'nophoto'}.80x80.thumb-fd`" ></v-img>
                                                    </v-avatar>
                                                    </v-col>
                                                <v-col cols="12" class="caption text-center">{{present.recipient_name}}</v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>


        <v-overlay  :opacity="0.4" :value="loading">
        <v-icon size="50" class="mdi-spin">mdi-loading</v-icon>
      </v-overlay>
        
    </v-card-text> 


       
   </v-card>
        
       
               

</template>

<script>
import AppData from '@mixins/AppData';
import PresentCalendar from './components/PresentCalendar'
export default {

    mixins:     [ AppData ],

    components: { 
        PresentCalendar
    },

    computed:{
        role()
        {
            return this.$store.getters['getRole']
        },

        agencies()
        {
            return  ([1,2].includes(this.role.id)) ? this.$store.getters['getAgencyManage'] : this.$store.getters['getAgency']
        },

    },

    data: () => ({
        presents: [],
        total:    0,
        url:      'agency/presents',
        agency:   null,
        //day:      new Date().toISOString().substr(0, 10),
        start_at: new Date().toISOString().substr(0, 10),
        end_at:   new Date().toISOString().substr(0, 10),
        filter:   []
    }),

    methods:
    {
        list() {

            if(this.agency)
            {
                let filters = this.setFilters()
                this.getResource(`${this.url}${filters}`).then(data =>{
                    this.setPresents(data.data)
                    this.total = data.data.total
                })
            }
        },

        setDay(days)
        {
            if(Array.isArray(days))
            {
                this.start_at = days[0]
                this.end_at   = days[1]
            } else {
                this.start_at = days
                this.end_at   = days
            }
            this.list()
        },

        setFilters()
        {   
            this.filter['agency']   = this.agency.amolatina_id 
            // this.filter['day']      = this.day 
            this.filter['start_at']  = this.start_at 
            this.filter['end_at']    = this.end_at 
            return this.buildQuery(this.filter);
        },
 
        buildQuery(filter)
        {
           let query = this.url.includes('?') ? '&' : '';

           for (const key in filter) {
               if(filter[key] != null) {
                    if( Array.isArray(filter[key]) ) {
                        for ( const value of filter[key] ) {
                            query += (query == '&') ?  `${key}[]=${value}` : `&${key}[]=${value}`
                        }
                    } else {
                        query += (query == '&') ?  `${key}=${filter[key]}` : `&${key}=${filter[key]}`
                    }
               }
           }
           return  ( query.includes('?')  || this.url.includes('?')) ? query : `?${query}`
        },

        remove(item)
        {
            this.filters = this.filters.filter( f => f != item.value) 
        },

        setPresents(data){
            console.log(data, typeof data.items)
            this.presents = data.items.map(el => { 
                return {
                    id: el.id,
                    recipient_id: el["recipient-id"],
                    sender_id:  el["sender-id"],
                    recipient_name: data.users.find(element => element.id === el["recipient-id"]).name,
                    sender_name: data.users.find(element => element.id === el["sender-id"]).name,
                    recipient_thumbnail: data.users.find(element => element.id === el["recipient-id"]).thumbnail ?? null,
                    sender_thumbnail: data.users.find(element => element.id === el["sender-id"]).thumbnail ?? null,
                    timestamp: el.timestamp ?? null,
                    delivered: el.delivered ?? null,
                    deadline: el.deadline ?? null,
                    items: el.items ?? [],
                    text: el.text ?? ' '
                } 
            })
        }
    }
}

//'comission?type=1&positive=true'
</script>

<style>
.comission-container {
    max-height: 74vh;
    overflow-y: auto;
}
</style>