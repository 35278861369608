var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { fullscreen: "", scrollable: "" },
      model: {
        value: _vm.modal,
        callback: function ($$v) {
          _vm.modal = $$v
        },
        expression: "modal",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "rounded-xl", attrs: { flat: "", height: "85vh" } },
        [
          _c(
            "v-toolbar",
            {
              attrs: {
                dark: "",
                flat: "",
                dense: "",
                color: _vm.HeadColor ? _vm.HeadColor : _vm.$App.theme.headModal,
              },
            },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-1",
                  attrs: { icon: "", "x-small": "", dark: "" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.$emit("closeModal")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close-circle")])],
                1
              ),
              _vm._v(" "),
              _c("v-toolbar-title", [_vm._v(_vm._s(_vm.title))]),
              _vm._v(" "),
              _c("v-spacer"),
              _vm._v(" "),
              _vm.showMenu
                ? _c(
                    "v-btn",
                    { attrs: { icon: "" } },
                    [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "py-1 px-1" },
            [_vm._t("default")],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }