var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "rounded-lg",
      attrs: { dark: "", color: "rgba(0,0,0,0.4)" },
    },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-avatar",
                        { attrs: { size: "70" } },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "grey", size: "70" } },
                            [_vm._v("mdi-account-circle")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "title-section" },
                            [_vm._v(" " + _vm._s(_vm.user.name))]
                          ),
                          _vm._v(" "),
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.role.name)),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-icon",
                        [
                          _c("item-menu", {
                            attrs: {
                              menus: _vm.gralMenu,
                              iconColor: "white",
                              btnColor: "transparent",
                              item: _vm.agency,
                            },
                            on: {
                              onItemMenu: function ($event) {
                                return _vm.onItemMenu($event)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                [
                  _c("v-select", {
                    attrs: {
                      label: "Agencias",
                      "item-text": "name",
                      items: _vm.agencies,
                      loading: _vm.loading,
                      "hide-details": "",
                      outlined: "",
                      filled: "",
                      dense: "",
                      "return-object": "",
                    },
                    model: {
                      value: _vm.agency,
                      callback: function ($$v) {
                        _vm.agency = $$v
                      },
                      expression: "agency",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("item-menu", {
                    attrs: {
                      menus: _vm.agencyMenu,
                      iconColor: "white",
                      btnColor: "transparent",
                      item: _vm.agency,
                      "icon-color": "primary",
                    },
                    on: {
                      onItemMenu: function ($event) {
                        return _vm.onItemMenu($event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-subheader",
            [
              _c(
                "v-row",
                [
                  _c("v-col", [_vm._v("Ganancias")]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: { small: "" },
                          on: {
                            click: function ($event) {
                              return _vm.updateCommision($event)
                            },
                          },
                        },
                        [_vm._v("mdi-home-search-outline")]
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-icon",
                        {
                          attrs: {
                            small: "",
                            disabled: _vm.progress.length < 1,
                          },
                          on: {
                            click: function ($event) {
                              return _vm.updateTotalsCommision()
                            },
                          },
                        },
                        [_vm._v("mdi-reload")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.progress, function (progres) {
            return _c(
              "v-row",
              { key: progres.id, attrs: { dense: "" } },
              [
                _c("v-col", { staticClass: "caption" }, [
                  _vm._v("\n          " + _vm._s(progres.name) + "\n        "),
                ]),
                _vm._v(" "),
                _c(
                  "v-col",
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "", color: "blue" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-progress-linear",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            height: "15",
                                            value: _vm.getPercent(
                                              progres.day.points,
                                              progres.goal_day
                                            ),
                                            indeterminate: _vm.loading,
                                          },
                                        },
                                        "v-progress-linear",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.formatNumber(
                                              progres.day.points
                                            ) || 0
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Meta Dia " + _vm._s(progres.goal_day)),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "v-col",
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "", color: "green" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-progress-linear",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          attrs: {
                                            color: "green",
                                            height: "15",
                                            value: _vm.getPercent(
                                              progres.month.points,
                                              progres.goal_month
                                            ),
                                            indeterminate: _vm.loading,
                                          },
                                        },
                                        "v-progress-linear",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _vm._v(
                                        " \n            " +
                                          _vm._s(
                                            _vm.formatNumber(
                                              progres.month.points
                                            ) || 0
                                          ) +
                                          "\n          "
                                      ),
                                    ]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c("span", { staticClass: "font-weight-bold" }, [
                          _vm._v("Meta Mes " + _vm._s(progres.goal_month)),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c("v-col", { staticClass: "pa-0", attrs: { cols: "auto" } }, [
                  _c("div", { staticClass: "fill-total" }),
                ]),
              ],
              1
            )
          }),
          _vm._v(" "),
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c("v-col", { staticClass: "caption" }, [
                _vm._v("\n         Total\n        "),
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c(
                    "v-progress-linear",
                    {
                      attrs: {
                        height: "15",
                        color: "blue darken-1",
                        value: _vm.getPercent(_vm.totals.day.points, 2000),
                        indeterminate: _vm.loading,
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(_vm.formatNumber(_vm.totals.day.points) || 0) +
                          "\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c(
                    "v-progress-linear",
                    {
                      attrs: {
                        color: "green darken-1",
                        height: "15",
                        value: _vm.getPercent(_vm.totals.month.points, 60000),
                        indeterminate: _vm.loading,
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.formatNumber(_vm.totals.month.points) || 0
                          ) +
                          "\n          "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { staticClass: "pa-0", attrs: { cols: "auto" } },
                _vm._l(_vm.goalType, function (goal) {
                  return _c(
                    "v-tooltip",
                    {
                      key: goal.id,
                      attrs: { bottom: "", color: goal.color },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-progress-circular",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "ml-1 pa-0",
                                        attrs: {
                                          value: _vm.getPercent(
                                            _vm.totals.month.points,
                                            goal.amount
                                          ),
                                          size: 18,
                                          color: goal.color,
                                          width: "6",
                                        },
                                      },
                                      "v-progress-circular",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _vm.getPercent(
                                      _vm.totals.month.points,
                                      goal.amount
                                    ) >= 100
                                      ? _c(
                                          "v-icon",
                                          { attrs: { color: "success" } },
                                          [_vm._v("mdi-check-circle-outline")]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _vm._v(" "),
                      _c(
                        "span",
                        { staticClass: "indigo--text font-weight-bold" },
                        [
                          _vm._v(
                            _vm._s(goal.name) +
                              " " +
                              _vm._s(
                                _vm.formatNumber(_vm.totals.month.points)
                              ) +
                              " / " +
                              _vm._s(_vm.formatNumber(goal.amount)) +
                              " (" +
                              _vm._s(
                                _vm.formatNumber(
                                  _vm.getPercent(
                                    _vm.totals.month.points,
                                    goal.amount
                                  )
                                )
                              ) +
                              "%)"
                          ),
                        ]
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", width: "98vw" },
          model: {
            value: _vm.tableDetailDialog,
            callback: function ($$v) {
              _vm.tableDetailDialog = $$v
            },
            expression: "tableDetailDialog",
          },
        },
        [
          _vm.tableDetailDialog
            ? _c("TableDetail", {
                on: {
                  closeDialog: function ($event) {
                    return _vm.closeDialog($event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", persistent: "", width: "280" },
          model: {
            value: _vm.rebuildPresenceDialog,
            callback: function ($$v) {
              _vm.rebuildPresenceDialog = $$v
            },
            expression: "rebuildPresenceDialog",
          },
        },
        [
          _vm.rebuildPresenceDialog
            ? _c("Rebuilpresence", {
                on: {
                  closeDialog: function ($event) {
                    return _vm.closeDialog($event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", persistent: "", width: "400" },
          model: {
            value: _vm.agencyGoalDialog,
            callback: function ($$v) {
              _vm.agencyGoalDialog = $$v
            },
            expression: "agencyGoalDialog",
          },
        },
        [
          _vm.agencyGoalDialog
            ? _c("AgencyGoal", {
                attrs: { agency: _vm.agency },
                on: {
                  closeDialog: function ($event) {
                    return _vm.closeDialog($event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", persistent: "", width: "400" },
          model: {
            value: _vm.agenciesGoalDialog,
            callback: function ($$v) {
              _vm.agenciesGoalDialog = $$v
            },
            expression: "agenciesGoalDialog",
          },
        },
        [
          _vm.agenciesGoalDialog
            ? _c("AppGoalType", {
                on: {
                  closeDialog: function ($event) {
                    return _vm.closeDialog($event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { scrollable: "", persistent: "", fullscreen: "" },
          model: {
            value: _vm.reportPayDialog,
            callback: function ($$v) {
              _vm.reportPayDialog = $$v
            },
            expression: "reportPayDialog",
          },
        },
        [
          _vm.reportPayDialog
            ? _c("AppReportPay", {
                on: {
                  closeDialog: function ($event) {
                    return _vm.closeDialog($event)
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }