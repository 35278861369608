var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-snackbar",
    {
      attrs: {
        timeout: _vm.timeoutMsj,
        color: _vm.colorMsj,
        "multi-line": false,
      },
      scopedSlots: _vm._u([
        {
          key: "action",
          fn: function ({ attrs }) {
            return [
              _c(
                "v-btn",
                _vm._b(
                  {
                    attrs: { dark: "", text: "" },
                    nativeOn: {
                      click: function ($event) {
                        return _vm.closeMsj()
                      },
                    },
                  },
                  "v-btn",
                  attrs,
                  false
                ),
                [_c("v-icon", [_vm._v("close")])],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.showMsj,
        callback: function ($$v) {
          _vm.showMsj = $$v
        },
        expression: "showMsj",
      },
    },
    [
      _vm._v(
        "\n        " +
          _vm._s(_vm.txtMsj) +
          "\n        " +
          _vm._s(_vm.subTxtMsj) +
          "\n        "
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }