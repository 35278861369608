var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "rounded-lg",
      attrs: { dark: "", color: "rgba(0,0,0,0.4)", "min-height": "91vh" },
    },
    [
      _c(
        "v-card-title",
        { staticClass: "py-1 px-3" },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "offset-y": "",
                        "close-on-content-click": false,
                        "max-width": "300px",
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on, attrs }) {
                            return [
                              _c(
                                "v-btn",
                                _vm._g(
                                  _vm._b(
                                    {
                                      attrs: {
                                        small: "",
                                        icon: "",
                                        color: "primary",
                                        dark: "",
                                      },
                                    },
                                    "v-btn",
                                    attrs,
                                    false
                                  ),
                                  on
                                ),
                                [_c("v-icon", [_vm._v("mdi-account-search")])],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                      model: {
                        value: _vm.searchMenu,
                        callback: function ($$v) {
                          _vm.searchMenu = $$v
                        },
                        expression: "searchMenu",
                      },
                    },
                    [
                      _vm._v(" "),
                      _c("SearchBox", {
                        on: {
                          onSearch: function ($event) {
                            return _vm.searhClient($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                {
                  staticClass: "subtitle-2 pt-3",
                  on: {
                    click: function ($event) {
                      _vm.searchMenu = true
                    },
                  },
                },
                [_vm._v("\n          " + _vm._s(_vm.criteria) + "\n        ")]
              ),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c("item-menu", {
                    attrs: {
                      menus: _vm.itemsMenu,
                      iconColor: "white",
                      btnColor: "transparent",
                      item: {},
                    },
                    on: {
                      onItemMenu: function ($event) {
                        return _vm.onItemMenu($event)
                      },
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        { staticClass: "pt-0 clients-container custom-scroll" },
        [
          _c(
            "v-list",
            {
              staticClass: "rounded-lg",
              attrs: { subheader: "", dense: "", color: "rgba(0,0,0,0.4)" },
            },
            [
              _c(
                "v-list-item-group",
                {
                  attrs: { multiple: "", color: "green" },
                  model: {
                    value: _vm.clientsSelected,
                    callback: function ($$v) {
                      _vm.clientsSelected = $$v
                    },
                    expression: "clientsSelected",
                  },
                },
                _vm._l(_vm.clients, function (client) {
                  return _c("ClientCard", {
                    key: client.id,
                    attrs: { client: client },
                  })
                }),
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-overlay",
            {
              attrs: {
                color: "rounded",
                absolute: "",
                opacity: 0.4,
                value: _vm.loading,
              },
            },
            [
              _c(
                "v-icon",
                { staticClass: "mdi-spin", attrs: { dark: "", size: "40" } },
                [_vm._v("mdi-loading")]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }