var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        { attrs: { dark: "" } },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "", justify: "center" } },
            [
              _c(
                "v-window",
                {
                  staticClass: "windows-container",
                  attrs: { continuous: "", "show-arrows": "" },
                  model: {
                    value: _vm.dialogVideo,
                    callback: function ($$v) {
                      _vm.dialogVideo = $$v
                    },
                    expression: "dialogVideo",
                  },
                },
                _vm._l(_vm.videos, function (video) {
                  return _c(
                    "v-window-item",
                    {
                      key: video.id,
                      staticClass: "windows-container",
                      attrs: { eager: "" },
                    },
                    [
                      _c(
                        "div",
                        { staticClass: "youtube-container" },
                        [
                          video.id == _vm.dialogVideo
                            ? _c("iframe", {
                                staticClass: "rounded-lg responsive-iframe",
                                attrs: {
                                  src: `${video.src}?&enablejsapi=1&loop=1&rel=0&showinfo=0`,
                                  frameborder: "0",
                                  allow:
                                    "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture",
                                  allowfullscreen: "",
                                },
                                on: {
                                  load: function ($event) {
                                    _vm.loading = false
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "v-btn",
                            {
                              staticClass: "float-right mr-3 mt-2",
                              attrs: { fab: "", "x-small": "" },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit("closeDialog")
                                },
                              },
                            },
                            [
                              _c("v-icon", { attrs: { color: "white" } }, [
                                _vm._v("mdi-close"),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }