var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { scrollable: "", fullscreen: "", persistent: "" },
      model: {
        value: _vm.show,
        callback: function ($$v) {
          _vm.show = $$v
        },
        expression: "show",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "report-container", attrs: { flat: "" } },
        [
          _c(
            "v-card-title",
            { staticClass: "pa-0" },
            [
              _c("app-simple-toolbar", {
                attrs: { title: _vm.title },
                on: {
                  closeModal: function ($event) {
                    return _vm.$emit("onCloseReport")
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-form",
            {
              ref: "form",
              attrs: { "lazy-validation": "" },
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                "v-toolbar",
                { staticClass: "elevation-0 grey lighten-5" },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: "10" } },
                        [
                          _c("v-select", {
                            attrs: {
                              items: _vm.campos,
                              "item-text": "name",
                              "item-value": "value",
                              rules: [_vm.rules.mutiple],
                              label: "Columnas para Mostrar",
                              autocomplete: "",
                              chips: "",
                              "deletable-chips": "",
                              multiple: "",
                              filled: "",
                              clearable: "",
                              "single-line": "",
                              dense: "",
                              "hide-details": "",
                              rounded: "",
                            },
                            model: {
                              value: _vm.form.campos,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "campos", $$v)
                              },
                              expression: "form.campos",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "my-1", attrs: { cols: "auto" } },
                        [
                          _c("app-button", {
                            attrs: {
                              color: "warning",
                              label: "Reiniciar",
                              icon: "mdi-restore",
                              depressed: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.clear()
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("app-button", {
                            attrs: {
                              color: "blue",
                              label: "Mostrar Datos",
                              icon: "mdi-table-eye",
                              depressed: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getReporte()
                              },
                            },
                          }),
                          _vm._v(" "),
                          _c("app-button", {
                            attrs: {
                              color: "success",
                              label: "Exportar a Excel",
                              icon: "mdi-file-excel-box",
                              depressed: "",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.getReporteExcel()
                              },
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        { staticClass: "my-1", attrs: { cols: "auto" } },
                        [
                          _c(
                            "v-badge",
                            {
                              attrs: {
                                left: "",
                                color: "red",
                                value: _vm.hasFilter(),
                                overlap: "",
                                dot: "",
                                bordered: "",
                              },
                            },
                            [
                              _c("app-button", {
                                attrs: {
                                  color: "indigo",
                                  label: "Filtrar Datos",
                                  icon: "filter_list",
                                  depressed: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.showMenu($event, "filter")
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-badge",
                            {
                              attrs: {
                                left: "",
                                color: "red",
                                value: _vm.hasSearch(),
                                overlap: "",
                                dot: "",
                                bordered: "",
                              },
                            },
                            [
                              _c("app-button", {
                                attrs: {
                                  color: "info",
                                  label: "Buscar",
                                  icon: "search",
                                  depressed: "",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.showMenu($event, "search")
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-click": false,
                        "close-on-content-click": false,
                        "position-x": _vm.position.x,
                        "position-y": _vm.position.y,
                        "min-width": "30vw",
                        absolute: "",
                      },
                      model: {
                        value: _vm.filter,
                        callback: function ($$v) {
                          _vm.filter = $$v
                        },
                        expression: "filter",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: { color: "red" },
                                  on: {
                                    click: function ($event) {
                                      _vm.filter = false
                                    },
                                  },
                                },
                                [_vm._v("mdi-close-circle-outline")]
                              ),
                              _vm._v(" "),
                              _vm._l(_vm.filtros, function (filtro, index) {
                                return _c("v-select", {
                                  key: index,
                                  attrs: {
                                    items: filtro.items,
                                    "item-text": "name",
                                    "item-value": "value",
                                    label: filtro.label,
                                    autocomplete: "",
                                    "hide-details": "",
                                    multiple: "",
                                    clearable: "",
                                  },
                                  model: {
                                    value:
                                      _vm.form.filtros[index][filtro.fieldId],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.form.filtros[index],
                                        filtro.fieldId,
                                        $$v
                                      )
                                    },
                                    expression:
                                      "form.filtros[index][filtro.fieldId]",
                                  },
                                })
                              }),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-menu",
                    {
                      attrs: {
                        "close-on-click": false,
                        "close-on-content-click": false,
                        "position-x": _vm.position.x,
                        "position-y": _vm.position.y,
                        "min-width": "30vw",
                        absolute: "",
                      },
                      model: {
                        value: _vm.search,
                        callback: function ($$v) {
                          _vm.search = $$v
                        },
                        expression: "search",
                      },
                    },
                    [
                      _c(
                        "v-card",
                        [
                          _c(
                            "v-card-text",
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: { color: "red" },
                                  on: {
                                    click: function ($event) {
                                      _vm.search = false
                                    },
                                  },
                                },
                                [_vm._v("mdi-close-circle-outline")]
                              ),
                              _vm._v(" "),
                              _c("v-text-field", {
                                attrs: {
                                  "append-icon": "search",
                                  label: "Buscar",
                                  "hide-details": "",
                                  clearable: "",
                                },
                                model: {
                                  value: _vm.searching,
                                  callback: function ($$v) {
                                    _vm.searching = $$v
                                  },
                                  expression: "searching",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _vm.items
                ? [
                    _c("report-data", {
                      attrs: {
                        items: _vm.items,
                        headers: _vm.headers,
                        search: _vm.searching,
                      },
                    }),
                  ]
                : !_vm.items && _vm.loading == false
                ? [
                    _c(
                      "v-alert",
                      {
                        staticClass: "mt-3",
                        attrs: {
                          type: "warning",
                          value: true,
                          prominent: "",
                          outlined: "",
                          border: "left",
                        },
                      },
                      [
                        _vm._v(
                          '\n                Seleccione las columnas y haga click en "mostrar datos"  '
                        ),
                        _c(
                          "v-icon",
                          { staticClass: "ml-1", attrs: { color: "blue" } },
                          [_vm._v("mdi-table-eye")]
                        ),
                      ],
                      1
                    ),
                  ]
                : _vm._e(),
              _vm._v(" "),
              _vm.$App.debug
                ? _c("pre", [_vm._v(_vm._s(_vm.$data))])
                : _vm._e(),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "v-overlay",
            {
              attrs: {
                absolute: "",
                opacity: 0.3,
                value: _vm.loading,
                "z-index": 10,
              },
            },
            [
              _c("v-icon", { staticClass: "mdi-spin", attrs: { size: "40" } }, [
                _vm._v("mdi-loading"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }