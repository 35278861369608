var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "v-dialog",
        {
          attrs: { width: "365", "content-class": "rounded-xl" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-btn",
                    _vm._g({ attrs: { icon: "" } }, on),
                    [_c("v-icon", [_vm._v("account_circle")])],
                    1
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _vm._v(" "),
          _c(
            "v-card",
            { staticClass: "rounded-xl", attrs: { loading: _vm.loading } },
            [
              _c(
                "v-card-title",
                { staticClass: "indigo white--text" },
                [
                  _vm._v("\n            Usuario\n            "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", "x-small": "", dark: "" },
                      on: {
                        click: function ($event) {
                          _vm.showDialog = false
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close-circle")])],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list",
                { attrs: { nav: "" } },
                [
                  _c(
                    "v-list-item",
                    { staticClass: "grey lighten-3" },
                    [
                      _c("app-foto-cuenta", {
                        staticClass: "mr-2",
                        attrs: {
                          origenId: _vm.user.id,
                          maxItems: 1,
                          tipoFoto: 4,
                          foto: _vm.user.foto ? _vm.user.foto : null,
                          aspectRatio: 32 / 43,
                        },
                        on: {
                          updateImage: function ($event) {
                            return _vm.setFoto($event)
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.user.nb_usuario)),
                          ]),
                          _vm._v(" "),
                          _c("v-list-item-subtitle", [
                            _vm._v(_vm._s(_vm.user.tx_email)),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-action",
                        [
                          _c("item-menu", {
                            attrs: {
                              menus: _vm.itemsMenu,
                              iconColor: "grey",
                              btnColor: "white",
                              item: _vm.user,
                            },
                            on: {
                              onItemMenu: function ($event) {
                                return _vm.onItemMenu($event)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-list-item-group",
                    [
                      _c(
                        "v-chip",
                        {
                          staticClass: "mb-1",
                          attrs: {
                            color: "green lighten-2",
                            close: "",
                            dark: "",
                            "close-icon": _vm.profile.tx_icono,
                          },
                        },
                        [_vm._v(" Perfil: " + _vm._s(_vm.profile.nb_perfil))]
                      ),
                      _vm._v(" "),
                      _c(
                        "v-radio-group",
                        {
                          attrs: {
                            row: "",
                            "hide-details": "",
                            label: "Perfiles: ",
                          },
                          model: {
                            value: _vm.profile,
                            callback: function ($$v) {
                              _vm.profile = $$v
                            },
                            expression: "profile",
                          },
                        },
                        _vm._l(_vm.profiles, function (profile) {
                          return _c("v-radio", {
                            key: profile.id,
                            staticClass: "ml-3",
                            attrs: {
                              "off-icon": profile.tx_icono,
                              "on-icon": profile.tx_icono,
                              "prepend-icon": profile.tx_icono,
                              value: profile,
                              color: "success",
                            },
                          })
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-divider"),
              _vm._v(" "),
              _c(
                "v-card-actions",
                [
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        color: "error",
                        text: "",
                        small: "",
                        loading: _vm.loading,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.logout()
                        },
                      },
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-location-exit")]),
                      _vm._v("\n                Cerrar Sesion\n            "),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500", "content-class": "rounded-xl" },
          model: {
            value: _vm.dialogUpdateAccount,
            callback: function ($$v) {
              _vm.dialogUpdateAccount = $$v
            },
            expression: "dialogUpdateAccount",
          },
        },
        [
          _vm.dialogUpdateAccount
            ? _c("app-account", {
                on: {
                  closeDialog: function ($event) {
                    return _vm.closeDialog($event, "dialogUpdateAccount")
                  },
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }