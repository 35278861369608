var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "main-color",
      attrs: { loading: _vm.loading, flat: "", dark: "" },
    },
    [
      _c(
        "v-card-title",
        { staticClass: "subtitle-2" },
        [_c("v-row", [_c("v-col", [_vm._v("Reporte Maestro")])], 1)],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        { staticClass: "custom-scroll row" },
        [
          _c(
            "v-col",
            { attrs: { cols: "12", md: "2" } },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-select", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          filled: "",
                          label: "Tipo Reporte",
                          items: _vm.reports,
                          rules: [_vm.rules.required],
                          "hide-details": "",
                          "return-object": "",
                        },
                        model: {
                          value: _vm.form.reportType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "reportType", $$v)
                          },
                          expression: "form.reportType",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("PresenceCalendar", {
                        on: {
                          onUpdateDate: function ($event) {
                            return _vm.setDay($event)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "info" },
                          on: {
                            click: function ($event) {
                              return _vm.getReport()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-clipboard-text-outline"),
                          ]),
                          _vm._v(" Ver"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "12", md: "10" } },
            [
              _vm.items.length > 0
                ? _c("v-text-field", {
                    attrs: {
                      "append-icon": "search",
                      label: "Buscar",
                      "hide-details": "",
                      clearable: "",
                      dense: "",
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.items.length > 0
                ? _c("v-data-table", {
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.items,
                      search: _vm.search,
                      "item-key": "id",
                      loading: _vm.loading,
                      width: "100%",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "tr",
                                _vm._l(_vm.headers, function (header, index) {
                                  return _c(
                                    "td",
                                    {
                                      key: index,
                                      class: item[header.value].classes,
                                    },
                                    [
                                      _vm._v(
                                        "\n                            " +
                                          _vm._s(item[header.value].value) +
                                          "\n                        "
                                      ),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1446771829
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.$App.debug ? _c("pre", [_vm._v(_vm._s(_vm.$data))]) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }