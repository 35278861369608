var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-tooltip",
    {
      attrs: { bottom: "", color: "sucess" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    class: _vm.innerClass,
                    attrs: {
                      icon: "",
                      dark: "",
                      "x-small": "",
                      loading: _vm.loading,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.insItem()
                      },
                    },
                  },
                  on
                ),
                [
                  _c(
                    "v-icon",
                    { attrs: { dark: "", size: "32", color: "green" } },
                    [_vm._v("mdi-plus-circle-outline")]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [_vm._v(" "), _c("span", [_vm._v(_vm._s(_vm.label))])]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }