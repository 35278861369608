var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      attrs: { color: "transparent", flat: "", dark: "", "min-height": "93vh" },
    },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "pa-2",
                      attrs: { color: "rgba(0,0,0,0.4)", width: "20vw" },
                    },
                    [
                      _c("v-subheader", [_vm._v("Regalos")]),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-select", {
                                staticClass: "mb-1",
                                attrs: {
                                  label: "Agencias",
                                  "item-text": "name",
                                  items: _vm.agencies,
                                  loading: _vm.loading,
                                  "hide-details": "",
                                  outlined: "",
                                  filled: "",
                                  dense: "",
                                  "return-object": "",
                                  autofocus: "",
                                  rules: [_vm.rules.required],
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.list()
                                  },
                                },
                                model: {
                                  value: _vm.agency,
                                  callback: function ($$v) {
                                    _vm.agency = $$v
                                  },
                                  expression: "agency",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("PresentCalendar", {
                                on: {
                                  onUpdateDate: function ($event) {
                                    return _vm.setDay($event)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "pa-2",
                      attrs: {
                        color: "rgba(0,0,0,0.4)",
                        "max-height": "92vh",
                        "mix-height": "88vh",
                      },
                    },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "pa-1" },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c("v-col", { attrs: { cols: "auto" } }, [
                                _vm._v("Regalos: " + _vm._s(_vm.total)),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        { staticClass: "comission-container custom-scroll" },
                        _vm._l(_vm.presents, function (present) {
                          return _c(
                            "v-row",
                            { key: present.id, attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c(
                                    "v-card",
                                    {
                                      staticClass: "ma-1 rounded-lg",
                                      attrs: { color: "rgba(0,0,0,0.4)" },
                                    },
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "2" } },
                                            [
                                              _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "caption text-center",
                                                      attrs: { cols: "12" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          present.sender_id
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                      attrs: { cols: "12" },
                                                    },
                                                    [
                                                      present
                                                        ? _c(
                                                            "v-row",
                                                            {
                                                              attrs: {
                                                                "no-gutters":
                                                                  "",
                                                                justify:
                                                                  "center",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "auto",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-avatar",
                                                                    {
                                                                      staticClass:
                                                                        "elevation-3",
                                                                      attrs: {
                                                                        color:
                                                                          "blue",
                                                                        size: 30,
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "v-img",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              src: `https://api0.amolatina.com/users/${
                                                                                present.sender_id
                                                                              }/photos/${
                                                                                present.sender_thumbnail ||
                                                                                "nophoto"
                                                                              }.80x80.thumb-fd`,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "caption text-center",
                                                      attrs: { cols: "12" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          present.sender_name
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _vm._l(
                                            present.items,
                                            function (item) {
                                              return _c(
                                                "v-col",
                                                {
                                                  key: item.id,
                                                  staticClass: "text-center",
                                                  attrs: { cols: "auto" },
                                                },
                                                [
                                                  _c(
                                                    "v-row",
                                                    {
                                                      attrs: {
                                                        "no-gutters": "",
                                                      },
                                                    },
                                                    [
                                                      _c("v-col", {
                                                        staticClass:
                                                          "text-detail",
                                                        class: "white--text",
                                                        attrs: { cols: "12" },
                                                      }),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          attrs: { cols: "12" },
                                                        },
                                                        [
                                                          _c(
                                                            "list-simple-icon",
                                                            {
                                                              attrs: {
                                                                size: 32,
                                                                icon: "mdi-gift",
                                                                label:
                                                                  item.group,
                                                                color:
                                                                  present.delivered ===
                                                                  null
                                                                    ? "red"
                                                                    : "green",
                                                              },
                                                            }
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "text-detail",
                                                          class: "white--text",
                                                          attrs: { cols: "12" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                 " +
                                                              _vm._s(
                                                                _vm.formatNumber(
                                                                  item.points
                                                                )
                                                              ) +
                                                              "$\n                                             "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            }
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "text-center",
                                              attrs: { cols: "auto" },
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c("v-col", {
                                                    staticClass: "text-detail",
                                                    class: "white--text",
                                                    attrs: { cols: "12" },
                                                  }),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c("list-simple-icon", {
                                                        attrs: {
                                                          size: 32,
                                                          icon: "mdi-message",
                                                          label: present.text,
                                                          color: "blue",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "text-detail",
                                                      class: "white--text",
                                                      attrs: { cols: "12" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                 Mensaje\n                                             "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "text-center",
                                              attrs: { cols: "3" },
                                            },
                                            [
                                              _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "caption text-center",
                                                      class: "white--text",
                                                      attrs: { cols: "12" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                 Recibido: " +
                                                          _vm._s(
                                                            new Date(
                                                              present.timestamp
                                                            ).toLocaleString()
                                                          ) +
                                                          "\n                                             "
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  present.delivered !== null
                                                    ? _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "caption text-detail",
                                                          class: "green--text",
                                                          attrs: { cols: "12" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                 Enviado: " +
                                                              _vm._s(
                                                                new Date(
                                                                  present.delivered
                                                                ).toLocaleString()
                                                              ) +
                                                              "\n                                             "
                                                          ),
                                                        ]
                                                      )
                                                    : _c(
                                                        "v-col",
                                                        {
                                                          staticClass:
                                                            "caption text-detail",
                                                          class: "red--text",
                                                          attrs: { cols: "12" },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                 Fecha Límite: " +
                                                              _vm._s(
                                                                new Date(
                                                                  present.deadline
                                                                ).toLocaleString()
                                                              ) +
                                                              "\n                                             "
                                                          ),
                                                        ]
                                                      ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "2" } },
                                            [
                                              _c(
                                                "v-row",
                                                { attrs: { "no-gutters": "" } },
                                                [
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "caption text-center",
                                                      attrs: { cols: "12" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          present.recipient_id
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "text-center",
                                                      attrs: { cols: "12" },
                                                    },
                                                    [
                                                      _c(
                                                        "v-avatar",
                                                        {
                                                          staticClass:
                                                            "elevation-3",
                                                          attrs: {
                                                            color: "grey",
                                                            size: 30,
                                                          },
                                                        },
                                                        [
                                                          _c("v-img", {
                                                            attrs: {
                                                              src: `https://api0.amolatina.com/users/${
                                                                present.recipient_id
                                                              }/photos/${
                                                                present.recipient_thumbnail ||
                                                                "nophoto"
                                                              }.80x80.thumb-fd`,
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "v-col",
                                                    {
                                                      staticClass:
                                                        "caption text-center",
                                                      attrs: { cols: "12" },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          present.recipient_name
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        2
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-overlay",
            { attrs: { opacity: 0.4, value: _vm.loading } },
            [
              _c("v-icon", { staticClass: "mdi-spin", attrs: { size: "50" } }, [
                _vm._v("mdi-loading"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }