var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      attrs: { color: "transparent", flat: "", dark: "", "min-height": "93vh" },
    },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "pa-2",
                      attrs: { color: "rgba(0,0,0,0.4)", width: "20vw" },
                    },
                    [
                      _c(
                        "v-subheader",
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c("v-col", [
                                _vm._v(
                                  "\n                             Comisiones\n                         "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        small: "",
                                        icon: "",
                                        color: "success",
                                        loading: _vm.loading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.list()
                                        },
                                      },
                                    },
                                    [_c("v-icon", [_vm._v("mdi-reload")])],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            [
                              _vm.isManager
                                ? _c("v-select", {
                                    attrs: {
                                      "prepend-inner-icon":
                                        "mdi-table-furniture",
                                      label: "Turno",
                                      "item-text": "name",
                                      items: _vm.turns,
                                      loading: _vm.loading,
                                      "hide-details": "",
                                      outlined: "",
                                      filled: "",
                                      dense: "",
                                      "return-object": "",
                                      autofocus: "",
                                      rules: [_vm.rules.required],
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.list()
                                      },
                                    },
                                    model: {
                                      value: _vm.turn,
                                      callback: function ($$v) {
                                        _vm.turn = $$v
                                      },
                                      expression: "turn",
                                    },
                                  })
                                : _c("v-select", {
                                    attrs: {
                                      "prepend-inner-icon":
                                        "mdi-table-furniture",
                                      label: "Mesas",
                                      "item-text": "name",
                                      items: _vm.tables,
                                      loading: _vm.loading,
                                      "hide-details": "",
                                      outlined: "",
                                      filled: "",
                                      dense: "",
                                      "return-object": "",
                                      autofocus: "",
                                      rules: [_vm.rules.required],
                                    },
                                    on: {
                                      change: function ($event) {
                                        return _vm.list()
                                      },
                                    },
                                    model: {
                                      value: _vm.table,
                                      callback: function ($$v) {
                                        _vm.table = $$v
                                      },
                                      expression: "table",
                                    },
                                  }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "12" } },
                            [
                              _c("PresenceCalendar", {
                                on: {
                                  onUpdateDate: function ($event) {
                                    return _vm.setDay($event)
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "pa-2",
                      attrs: {
                        color: "rgba(0,0,0,0.4)",
                        "max-height": "92vh",
                        "mix-height": "88vh",
                      },
                    },
                    [
                      _c(
                        "v-card-title",
                        { staticClass: "pa-1" },
                        [
                          _c(
                            "v-row",
                            { attrs: { "no-gutters": "" } },
                            [
                              _c("v-col", [
                                _vm.isManager
                                  ? _c("span", [
                                      _vm._v(
                                        "Turno " +
                                          _vm._s(
                                            _vm.turn
                                              ? _vm.turn.name
                                              : "Seleccione Turno"
                                          )
                                      ),
                                    ])
                                  : _c("span", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.table
                                            ? _vm.table.name
                                            : "Seleccione Mesa"
                                        )
                                      ),
                                    ]),
                              ]),
                              _vm._v(" "),
                              !_vm.isManager
                                ? _c(
                                    "v-col",
                                    [
                                      _c(
                                        "v-radio-group",
                                        {
                                          staticClass: "mt-0",
                                          attrs: {
                                            row: "",
                                            dense: "",
                                            "hide-details": "",
                                            "prepend-icon": "mdi-close",
                                          },
                                          on: {
                                            "click:prepend": function ($event) {
                                              _vm.turn = null
                                            },
                                          },
                                          model: {
                                            value: _vm.turnFilter,
                                            callback: function ($$v) {
                                              _vm.turnFilter = $$v
                                            },
                                            expression: "turnFilter",
                                          },
                                        },
                                        [
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Dia",
                                              value: "1",
                                              color: "yellow",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Tarde",
                                              value: "2",
                                              color: "orange",
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c("v-radio", {
                                            attrs: {
                                              label: "Noche",
                                              value: "3",
                                              color: "purple",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.table
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          attrs: {
                                            size: "20",
                                            color: "green",
                                            left: "",
                                          },
                                        },
                                        [_vm._v("mdi-cash-plus")]
                                      ),
                                      _vm._v(
                                        "\n                           " +
                                          _vm._s(
                                            _vm.totalBonusTable(_vm.precense) ||
                                              0.0
                                          ) +
                                          "\n                       "
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _vm.table
                                ? _c(
                                    "v-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      _c(
                                        "v-icon",
                                        {
                                          staticClass: "ml-2",
                                          attrs: {
                                            size: "20",
                                            color: "red",
                                            left: "",
                                          },
                                        },
                                        [_vm._v("mdi-cash-remove")]
                                      ),
                                      _vm._v(
                                        "\n                           " +
                                          _vm._s(
                                            _vm.totalWriteOffTable(
                                              _vm.precense
                                            ) || 0
                                          ) +
                                          "\n                       "
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        { staticClass: "comission-container custom-scroll" },
                        [
                          _c(
                            "v-expansion-panels",
                            _vm._l(_vm.precense, function (user) {
                              return _c(
                                "v-expansion-panel",
                                { key: user.id },
                                [
                                  _c(
                                    "v-expansion-panel-header",
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "auto" } },
                                            [
                                              _c(
                                                "v-avatar",
                                                {
                                                  staticClass: "elevation-3",
                                                  attrs: {
                                                    color: "grey",
                                                    size: 30,
                                                  },
                                                },
                                                [
                                                  _c("v-img", {
                                                    attrs: {
                                                      src: `/storage/photo/operator/${
                                                        user.photo || "nophoto"
                                                      }`,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "4" } },
                                            [
                                              _vm._v(
                                                "\n                               " +
                                                  _vm._s(user.full_name) +
                                                  "\n                             "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pr-1",
                                              attrs: { cols: "auto" },
                                            },
                                            [
                                              user.work_time
                                                ? _c(
                                                    "v-badge",
                                                    {
                                                      attrs: {
                                                        "offset-x": "15",
                                                        "offset-y": "12",
                                                        color:
                                                          "rgba(0,0,0,0.15)",
                                                        content: user.work_time,
                                                      },
                                                    },
                                                    [
                                                      user.turn
                                                        ? _c(
                                                            "list-simple-icon",
                                                            {
                                                              attrs: {
                                                                icon: user.turn
                                                                  .icon,
                                                                label: `${user.turn.name} ${user.work_time} H`,
                                                                color:
                                                                  user.turn
                                                                    .color,
                                                              },
                                                            }
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            {
                                              staticClass: "pr-1",
                                              attrs: { cols: "auto" },
                                            },
                                            [
                                              user.table
                                                ? _c(
                                                    "v-badge",
                                                    {
                                                      attrs: {
                                                        "offset-x": "10",
                                                        "offset-y": "12",
                                                        color:
                                                          "rgba(0,0,0,0.15)",
                                                        content: user.table.id,
                                                      },
                                                    },
                                                    [
                                                      user.table
                                                        ? _c(
                                                            "list-simple-icon",
                                                            {
                                                              attrs: {
                                                                icon: "mdi-table-furniture",
                                                                label:
                                                                  user.table
                                                                    .name,
                                                                color: "amber",
                                                              },
                                                            }
                                                          )
                                                        : _vm._e(),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-spacer"),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "auto" } },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  attrs: {
                                                    size: "20",
                                                    color: "green",
                                                    left: "",
                                                  },
                                                },
                                                [_vm._v("mdi-cash-plus")]
                                              ),
                                              _vm._v(
                                                "\n                               " +
                                                  _vm._s(
                                                    _vm.formatNumber(
                                                      user.presence_sum_bonus ||
                                                        0.0
                                                    )
                                                  ) +
                                                  "\n                             "
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "v-col",
                                            { attrs: { cols: "auto" } },
                                            [
                                              _c(
                                                "v-icon",
                                                {
                                                  staticClass: "ml-2",
                                                  attrs: {
                                                    size: "20",
                                                    color: "red",
                                                    left: "",
                                                  },
                                                },
                                                [_vm._v("mdi-cash-remove")]
                                              ),
                                              _vm._v(
                                                "\n                               " +
                                                  _vm._s(
                                                    parseInt(
                                                      user.presence_sum_writeoff ||
                                                        0
                                                    )
                                                  ) +
                                                  "\n                             "
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-expansion-panel-content",
                                    [
                                      _c(
                                        "v-expansion-panels",
                                        _vm._l(
                                          user.profile_precense,
                                          function (profile) {
                                            return _c(
                                              "v-expansion-panel",
                                              {
                                                key: profile.id,
                                                staticClass:
                                                  "blue-grey darken-4",
                                              },
                                              [
                                                _c(
                                                  "v-expansion-panel-header",
                                                  [
                                                    _c(
                                                      "v-row",
                                                      { attrs: { dense: "" } },
                                                      [
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "auto",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-avatar",
                                                              {
                                                                staticClass:
                                                                  "elevation-3",
                                                                attrs: {
                                                                  color: "grey",
                                                                  size: 30,
                                                                },
                                                              },
                                                              [
                                                                _c("v-img", {
                                                                  attrs: {
                                                                    src: `/storage/photo/profile/${
                                                                      profile.photo ||
                                                                      "nophoto"
                                                                    }.jpg`,
                                                                  },
                                                                }),
                                                              ],
                                                              1
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-col",
                                                          [
                                                            _vm._v(
                                                              "\n                                         " +
                                                                _vm._s(
                                                                  profile.name
                                                                ) +
                                                                " \n                                         "
                                                            ),
                                                            _vm.isAssigned(
                                                              user.profile,
                                                              profile
                                                            )
                                                              ? _c(
                                                                  "list-simple-icon",
                                                                  {
                                                                    attrs: {
                                                                      icon: "mdi-check-circle-outline",
                                                                      label:
                                                                        "Asignado",
                                                                      color:
                                                                        "green",
                                                                      size: 16,
                                                                    },
                                                                  }
                                                                )
                                                              : _c(
                                                                  "list-simple-icon",
                                                                  {
                                                                    attrs: {
                                                                      icon: "mdi-alert-circle-outline",
                                                                      label:
                                                                        "No asignado",
                                                                      color:
                                                                        "amber",
                                                                      size: 16,
                                                                    },
                                                                  }
                                                                ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "auto",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  size: "20",
                                                                  color:
                                                                    "green",
                                                                  left: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-cash-plus"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              "\n                                         " +
                                                                _vm._s(
                                                                  _vm.formatNumber(
                                                                    profile.sumBonus ||
                                                                      0.0
                                                                  )
                                                                ) +
                                                                "\n                                     "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(" "),
                                                        _c(
                                                          "v-col",
                                                          {
                                                            attrs: {
                                                              cols: "auto",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                staticClass:
                                                                  "ml-2",
                                                                attrs: {
                                                                  size: "20",
                                                                  color: "red",
                                                                  left: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-cash-remove"
                                                                ),
                                                              ]
                                                            ),
                                                            _vm._v(
                                                              "\n                                        " +
                                                                _vm._s(
                                                                  parseInt(
                                                                    profile.countWriteoff ||
                                                                      0
                                                                  )
                                                                ) +
                                                                "\n                                     "
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                                _vm._v(" "),
                                                _c(
                                                  "v-expansion-panel-content",
                                                  [
                                                    _c(
                                                      "v-expansion-panels",
                                                      _vm._l(
                                                        profile.presence,
                                                        function (presence) {
                                                          return _c(
                                                            "v-expansion-panel",
                                                            {
                                                              key: presence.id,
                                                            },
                                                            [
                                                              _c(
                                                                "v-expansion-panel-header",
                                                                [
                                                                  _c(
                                                                    "v-row",
                                                                    [
                                                                      _c(
                                                                        "v-col",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                         " +
                                                                              _vm._s(
                                                                                _vm.UTCToLocalDate(
                                                                                  presence.start_at
                                                                                )
                                                                              ) +
                                                                              " \n                                                     "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-col",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                         " +
                                                                              _vm._s(
                                                                                _vm.UTCToLocalDate(
                                                                                  presence.end_at
                                                                                ) ||
                                                                                  "activo"
                                                                              ) +
                                                                              "\n                                                     "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-col",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                         " +
                                                                              _vm._s(
                                                                                _vm.formatNumber(
                                                                                  presence.bonus ||
                                                                                    0
                                                                                )
                                                                              ) +
                                                                              "\n                                                     "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                      _vm._v(
                                                                        " "
                                                                      ),
                                                                      _c(
                                                                        "v-col",
                                                                        [
                                                                          _vm._v(
                                                                            "\n                                                         " +
                                                                              _vm._s(
                                                                                parseInt(
                                                                                  presence.writeoff ||
                                                                                    0
                                                                                )
                                                                              ) +
                                                                              "\n                                                     "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "v-expansion-panel-content",
                                                                [
                                                                  _c(
                                                                    "ComissionPresence",
                                                                    {
                                                                      attrs: {
                                                                        presence:
                                                                          presence,
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          )
                                                        }
                                                      ),
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-overlay",
            { attrs: { opacity: 0.4, value: _vm.loading } },
            [
              _c("v-icon", { staticClass: "mdi-spin", attrs: { size: "50" } }, [
                _vm._v("mdi-loading"),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }