<template>
  <v-container fluid>
    <v-row v-if="!loading">
      <v-col cols="4" v-for="agencies in dataAgencies" :key="agencies.name">
        <v-card dark class="rounded-lg" color="rgba(0,0,0,0.4)" :loading="loading">
          <v-card-title class="text-h5">{{ agencies.name }}</v-card-title>
          <v-card-text class="pt-0 accounts-container custom-scroll">
            <v-list subheader two-line dense color="rgba(0,0,0,0.4)" class="rounded-lg">
              <v-list-item v-for="profile in agencies.profiles" :key="profile.id">
                <v-list-item-avatar color="blue" size="60">
                  <v-tooltip bottom color="blue">
                    <template v-slot:activator="{ on, attrs }">
                      <v-img v-on="on" v-bind="attrs"
                        :src="`/storage/photo/profile/${profile.thumbnail || 'nophoto'}.jpg`"></v-img>
                    </template>
                    <span>{{ profile['user-id'] }}</span>
                  </v-tooltip>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <v-row no-gutters>
                      <v-col>
                        {{ profile['first-name'] }}
                      </v-col>
                      <v-col>
                        {{ profile['user-id'] }}
                      </v-col>
                      <v-col :class="profile['has-mingle'] ? 'text-green' : 'text-red'">
                        {{ profile['has-mingle'] ? 'Mingle Activo' : 'Mingle Inactivo' }}
                      </v-col>
                    </v-row>
                  </v-list-item-title>
                  <v-list-item-subtitle class="pt-2">
                    <v-row>
                      <v-col :class="profile.online ? 'text-green' : 'text-red'">
                        {{ profile.online ? 'En Línea' : formatDate(profile.offline) }}
                      </v-col>
                      <v-col :class="profile['has-chat'] ? 'text-green' : 'text-red'">
                        {{ profile['has-chat'] ? 'Está chateando...' : 'Sin chats activos.' }}
                      </v-col>
                      <v-col :class="profile['has-introductory'] ? 'text-green' : 'text-red'">

                      </v-col>
                    </v-row>
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>


<script>
import AppData from '@mixins/AppData';
export default {

  mixins: [AppData],

  created() {
    this.list()
    this.reload()
  },

  data: () => ({
    dataAgencies: [],
    isReload: null,
    loading: true,
  }),

  formatDate(date) {
    return (new Date(date).toLocaleString('en-GB', {
      hour:
        'numeric', minute:
        'numeric', hour12: true
    }))
  },

  methods: {

    list() {
      this.getResource('profile/online').then(data => {
        this.dataAgencies = data
        this.loading = false
      })
    },

    reload() {
      clearInterval(this.isReload)
      this.isReload = setInterval(() => {
        this.list();
      }, 30000)
    },
  }
}
</script>

<style>
.text-green {
  color: green;
}

.text-red {
  color: red;
}
</style>