var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "main-color", attrs: { dark: "", flat: "" } },
    [
      _c(
        "v-subheader",
        { staticClass: "py-0" },
        [
          _c(
            "v-row",
            { attrs: { "no-gutters": "" } },
            [
              _c("v-col", { staticClass: "title" }, [_vm._v("Mesas")]),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _vm.$route.name != "table-detail"
                    ? _c(
                        "v-btn",
                        {
                          attrs: { icon: "", dark: "" },
                          on: {
                            click: function ($event) {
                              return _vm.$emit("closeDialog", false)
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-close")])],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        { staticClass: "custom-scroll" },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            _vm._l(_vm.tables, function (table) {
              return _c(
                "v-col",
                { key: table.id },
                [
                  _c(
                    "v-card",
                    { attrs: { color: "rgba(0,0,0,0.4)", flat: "" } },
                    [
                      _c("v-subheader", { staticClass: "py-1" }, [
                        _vm._v(
                          "\n          " + _vm._s(table.name) + "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        { staticClass: "py-0 px-2" },
                        _vm._l(table.table_turn, function (tableTurn) {
                          return _c(
                            "v-card",
                            {
                              key: tableTurn.id,
                              staticClass: "mb-1",
                              attrs: { color: "rgba(0,0,0,0.4)" },
                            },
                            [
                              _c(
                                "v-subheader",
                                { staticClass: "tablename" },
                                [
                                  _c(
                                    "v-row",
                                    [
                                      _c("v-col", [
                                        _vm._v(_vm._s(tableTurn.turn.name)),
                                      ]),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        [
                                          _c(
                                            "v-icon",
                                            {
                                              attrs: {
                                                small: "",
                                                left: "",
                                                color: "green",
                                              },
                                            },
                                            [_vm._v("mdi-shield-account")]
                                          ),
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                tableTurn.coordinator
                                                  ? tableTurn.coordinator
                                                      .full_name
                                                  : "Disponible"
                                              )
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _vm._l(tableTurn.operator, function (operator) {
                                return _c(
                                  "v-col",
                                  { key: operator.id, attrs: { cols: "12" } },
                                  [
                                    _c(
                                      "v-row",
                                      { attrs: { dense: "" } },
                                      [
                                        _c(
                                          "v-col",
                                          { attrs: { cols: "6" } },
                                          [
                                            _c(
                                              "v-icon",
                                              {
                                                attrs: {
                                                  small: "",
                                                  left: "",
                                                  color: "amber",
                                                },
                                              },
                                              [_vm._v("mdi-account")]
                                            ),
                                            _vm._v(
                                              "   " + _vm._s(operator.full_name)
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c("v-col", { attrs: { cols: "6" } }, [
                                          _vm._v(
                                            "Perfiles " +
                                              _vm._s(operator.profile.length)
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _vm._l(
                                          operator.profile,
                                          function (profile) {
                                            return _c(
                                              "v-col",
                                              {
                                                key: profile.id,
                                                class: _vm.active
                                                  ? "green"
                                                  : "",
                                                attrs: { cols: "auto" },
                                              },
                                              [
                                                _c(
                                                  "draggable",
                                                  {
                                                    staticClass:
                                                      "list-group move",
                                                    attrs: { group: "people" },
                                                    on: {
                                                      change: _vm.log,
                                                      start: _vm.enableLanding,
                                                      end: _vm.disableLanding,
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "v-chip",
                                                      {
                                                        attrs: {
                                                          color: "primary",
                                                          "x-small": "",
                                                        },
                                                      },
                                                      [
                                                        _c(
                                                          "v-avatar",
                                                          [
                                                            _c(
                                                              "v-icon",
                                                              {
                                                                attrs: {
                                                                  size: "18",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-account-outline"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                        _vm._v(
                                                          "\n                          " +
                                                            _vm._s(
                                                              profile.name
                                                            ) +
                                                            "\n                        "
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            )
                                          }
                                        ),
                                      ],
                                      2
                                    ),
                                  ],
                                  1
                                )
                              }),
                            ],
                            2
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }