import { render, staticRenderFns } from "./AppOperatos.vue?vue&type=template&id=52119fb9&"
import script from "./AppOperatos.vue?vue&type=script&lang=js&"
export * from "./AppOperatos.vue?vue&type=script&lang=js&"
import style0 from "./AppOperatos.vue?vue&type=style&index=0&id=52119fb9&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/eugenio/www/socialmedia-soporte/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('52119fb9')) {
      api.createRecord('52119fb9', component.options)
    } else {
      api.reload('52119fb9', component.options)
    }
    module.hot.accept("./AppOperatos.vue?vue&type=template&id=52119fb9&", function () {
      api.rerender('52119fb9', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/views/boards/accountManager/components/AppOperatos.vue"
export default component.exports