var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: { width: "500", "content-class": "rounded-xl", scrollable: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g({ attrs: { icon: "" } }, on),
                [
                  _c(
                    "v-badge",
                    {
                      attrs: {
                        color: "orange",
                        content: _vm.totalNotificationes,
                        overlap: "",
                        dark: "",
                        value: _vm.totalNotificationes > 0,
                      },
                    },
                    [_c("v-icon", [_vm._v("notification_important")])],
                    1
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.showDialog,
        callback: function ($$v) {
          _vm.showDialog = $$v
        },
        expression: "showDialog",
      },
    },
    [
      _vm._v(" "),
      _c(
        "v-card",
        {
          staticClass: "rounded-xl",
          attrs: { loading: _vm.loading, height: "410" },
        },
        [
          _c(
            "v-card-title",
            { staticClass: "pa-0" },
            [
              _c("app-simple-toolbar", {
                attrs: { title: "Notificaciones" },
                on: {
                  closeModal: function ($event) {
                    _vm.showDialog = false
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            { staticClass: "pa-0" },
            [
              _c(
                "v-toolbar",
                { attrs: { color: "grey lighten-3", flat: "", dense: "" } },
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-1",
                      attrs: {
                        depressed: "",
                        dark: "",
                        fab: "",
                        "x-small": "",
                        color: "indigo",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getUnreads()
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { size: "20" } }, [
                        _vm._v("mdi-sync"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-1",
                      attrs: {
                        depressed: "",
                        dark: "",
                        fab: "",
                        "x-small": "",
                        color: "green",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.getReads()
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { size: "20" } }, [
                        _vm._v("mdi-bell-check"),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("v-spacer"),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mx-1",
                      attrs: {
                        depressed: "",
                        dark: "",
                        fab: "",
                        "x-small": "",
                        color: "amber",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.markReadAll()
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-check-all")])],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-list",
                { staticClass: "mt-n2", attrs: { dense: "", width: "100%" } },
                [
                  _c(
                    "v-list-item-group",
                    {
                      attrs: { flat: "" },
                      model: {
                        value: _vm.notificacion,
                        callback: function ($$v) {
                          _vm.notificacion = $$v
                        },
                        expression: "notificacion",
                      },
                    },
                    _vm._l(_vm.notificaciones, function (notificacion, idx) {
                      return _c(
                        "v-list-item",
                        { key: idx, attrs: { value: notificacion } },
                        [
                          _c(
                            "v-list-item-avatar",
                            {
                              attrs: {
                                color: notificacion.tipo_notificacion.tx_color,
                              },
                            },
                            [
                              _c("v-icon", {
                                attrs: { dark: "", size: "30" },
                                domProps: {
                                  textContent: _vm._s(
                                    notificacion.tipo_notificacion.tx_icono
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-content",
                            [
                              _c("v-list-item-title", {
                                domProps: {
                                  textContent: _vm._s(notificacion.tx_mensaje),
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-list-item-avatar",
                            { attrs: { color: "grey lighten-5" } },
                            [
                              _c("v-icon", {
                                attrs: {
                                  dark: "",
                                  size: "25",
                                  color: notificacion.fe_lectura
                                    ? "green"
                                    : "red",
                                },
                                domProps: {
                                  textContent: _vm._s(
                                    notificacion.fe_lectura
                                      ? "mdi-bell-check"
                                      : "mdi-bell-alert"
                                  ),
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "500", "content-class": "rounded-xl" },
          model: {
            value: _vm.selected,
            callback: function ($$v) {
              _vm.selected = $$v
            },
            expression: "selected",
          },
        },
        [
          _vm.notificacion
            ? _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    { staticClass: "pa-0" },
                    [
                      _c(
                        "v-toolbar",
                        {
                          staticClass: "rounded-t-xl",
                          attrs: {
                            dense: "",
                            flat: "",
                            color: _vm.notificacion.tipo_notificacion.tx_color,
                            dark: "",
                          },
                        },
                        [
                          _c(
                            "v-col",
                            [
                              _c("v-icon", {
                                staticClass: "mr-4",
                                attrs: { dark: "" },
                                domProps: {
                                  textContent: _vm._s(
                                    _vm.notificacion.tipo_notificacion.tx_icono
                                  ),
                                },
                              }),
                              _vm._v(
                                "\n                        " +
                                  _vm._s(
                                    _vm.notificacion.tipo_notificacion
                                      .nb_tipo_notificacion
                                  ) +
                                  "\n                    "
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-icon",
                                {
                                  attrs: { dark: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.selected = false
                                    },
                                  },
                                },
                                [_vm._v("mdi-close-circle-outline")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-card-text",
                    { staticClass: "pt-3" },
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { attrs: { cols: "auto" } },
                            [
                              _c(
                                "v-icon",
                                {
                                  staticClass: "mr-1",
                                  attrs: { color: "indigo" },
                                },
                                [_vm._v("mdi-calendar")]
                              ),
                              _vm._v(
                                _vm._s(
                                  _vm._f("formatDate")(
                                    _vm.notificacion.fe_notificacion
                                  )
                                ) + "\n                    "
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "subtitle-2", attrs: { cols: "3" } },
                            [_vm._v("Mensaje:")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "body-2", attrs: { cols: "8" } },
                            [_vm._v(_vm._s(_vm.notificacion.tx_mensaje))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }