var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-card",
        { attrs: { loading: _vm.loading, flat: "" } },
        [
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c(
                        "v-menu",
                        {
                          attrs: {
                            "close-on-content-click": false,
                            "min-width": "290px",
                          },
                          scopedSlots: _vm._u([
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    "v-text-field",
                                    _vm._g(
                                      {
                                        attrs: {
                                          rules: [_vm.rules.fecha],
                                          label: "Fecha",
                                          "prepend-icon": "event",
                                          readonly: "",
                                          dense: "",
                                        },
                                        model: {
                                          value: _vm.dates.day,
                                          callback: function ($$v) {
                                            _vm.$set(_vm.dates, "day", $$v)
                                          },
                                          expression: "dates.day",
                                        },
                                      },
                                      on
                                    )
                                  ),
                                ]
                              },
                            },
                          ]),
                          model: {
                            value: _vm.pickers.day,
                            callback: function ($$v) {
                              _vm.$set(_vm.pickers, "day", $$v)
                            },
                            expression: "pickers.day",
                          },
                        },
                        [
                          _vm._v(" "),
                          _c("v-date-picker", {
                            on: {
                              input: function ($event) {
                                _vm.dates.day = _vm.formatPicker(
                                  _vm.form.day,
                                  "day"
                                )
                              },
                            },
                            model: {
                              value: _vm.form.day,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "day", $$v)
                              },
                              expression: "form.day",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-select", {
                        attrs: {
                          rules: [_vm.rules.select],
                          items: _vm.selects.absenceType,
                          "item-text": "name",
                          "item-value": "id",
                          label: "Tipo Falta",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.absence_type_id,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "absence_type_id", $$v)
                          },
                          expression: "form.absence_type_id",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12", md: "6" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          rules: [_vm.rules.required],
                          label: "Descripcion",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.comments,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "comments", $$v)
                          },
                          expression: "form.comments",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c("form-buttons", {
                attrs: {
                  action: _vm.action,
                  valid: _vm.valid,
                  loading: _vm.loading,
                },
                on: {
                  update: function ($event) {
                    return _vm.update()
                  },
                  store: function ($event) {
                    return _vm.store()
                  },
                  clear: function ($event) {
                    return _vm.clear()
                  },
                  cancel: function ($event) {
                    return _vm.cancel()
                  },
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _vm.$App.debug ? _c("pre", [_vm._v(_vm._s(_vm.$data))]) : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }