var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-tooltip",
        {
          attrs: { bottom: "", color: _vm.color },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "v-icon",
                    _vm._g(
                      {
                        staticClass: "pointer",
                        attrs: { color: _vm.color, size: _vm.size },
                        domProps: { textContent: _vm._s(_vm.icon) },
                        on: {
                          click: function ($event) {
                            return _vm.$emit("click", $event)
                          },
                        },
                      },
                      on
                    )
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _c("span", { domProps: { textContent: _vm._s(_vm.label) } }),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }