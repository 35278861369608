var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      !_vm.loading
        ? _c(
            "v-row",
            _vm._l(_vm.dataAgencies, function (agencies) {
              return _c(
                "v-col",
                { key: agencies.name, attrs: { cols: "4" } },
                [
                  _c(
                    "v-card",
                    {
                      staticClass: "rounded-lg",
                      attrs: {
                        dark: "",
                        color: "rgba(0,0,0,0.4)",
                        loading: _vm.loading,
                      },
                    },
                    [
                      _c("v-card-title", { staticClass: "text-h5" }, [
                        _vm._v(_vm._s(agencies.name)),
                      ]),
                      _vm._v(" "),
                      _c(
                        "v-card-text",
                        {
                          staticClass: "pt-0 accounts-container custom-scroll",
                        },
                        [
                          _c(
                            "v-list",
                            {
                              staticClass: "rounded-lg",
                              attrs: {
                                subheader: "",
                                "two-line": "",
                                dense: "",
                                color: "rgba(0,0,0,0.4)",
                              },
                            },
                            _vm._l(agencies.profiles, function (profile) {
                              return _c(
                                "v-list-item",
                                { key: profile.id },
                                [
                                  _c(
                                    "v-list-item-avatar",
                                    { attrs: { color: "blue", size: "60" } },
                                    [
                                      _c(
                                        "v-tooltip",
                                        {
                                          attrs: { bottom: "", color: "blue" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on, attrs }) {
                                                  return [
                                                    _c(
                                                      "v-img",
                                                      _vm._g(
                                                        _vm._b(
                                                          {
                                                            attrs: {
                                                              src: `/storage/photo/profile/${
                                                                profile.thumbnail ||
                                                                "nophoto"
                                                              }.jpg`,
                                                            },
                                                          },
                                                          "v-img",
                                                          attrs,
                                                          false
                                                        ),
                                                        on
                                                      )
                                                    ),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _vm._v(" "),
                                          _c("span", [
                                            _vm._v(_vm._s(profile["user-id"])),
                                          ]),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-list-item-content",
                                    [
                                      _c(
                                        "v-list-item-title",
                                        [
                                          _c(
                                            "v-row",
                                            { attrs: { "no-gutters": "" } },
                                            [
                                              _c("v-col", [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      profile["first-name"]
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c("v-col", [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(profile["user-id"]) +
                                                    "\n                    "
                                                ),
                                              ]),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  class: profile["has-mingle"]
                                                    ? "text-green"
                                                    : "text-red",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        profile["has-mingle"]
                                                          ? "Mingle Activo"
                                                          : "Mingle Inactivo"
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-list-item-subtitle",
                                        { staticClass: "pt-2" },
                                        [
                                          _c(
                                            "v-row",
                                            [
                                              _c(
                                                "v-col",
                                                {
                                                  class: profile.online
                                                    ? "text-green"
                                                    : "text-red",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        profile.online
                                                          ? "En Línea"
                                                          : _vm.formatDate(
                                                              profile.offline
                                                            )
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "v-col",
                                                {
                                                  class: profile["has-chat"]
                                                    ? "text-green"
                                                    : "text-red",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                      " +
                                                      _vm._s(
                                                        profile["has-chat"]
                                                          ? "Está chateando..."
                                                          : "Sin chats activos."
                                                      ) +
                                                      "\n                    "
                                                  ),
                                                ]
                                              ),
                                              _vm._v(" "),
                                              _c("v-col", {
                                                class: profile[
                                                  "has-introductory"
                                                ]
                                                  ? "text-green"
                                                  : "text-red",
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }