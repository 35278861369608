import { render, staticRenderFns } from "./AppReportData.vue?vue&type=template&id=76c4c90f&"
import script from "./AppReportData.vue?vue&type=script&lang=js&"
export * from "./AppReportData.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/eugenio/www/socialmedia-soporte/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('76c4c90f')) {
      api.createRecord('76c4c90f', component.options)
    } else {
      api.reload('76c4c90f', component.options)
    }
    module.hot.accept("./AppReportData.vue?vue&type=template&id=76c4c90f&", function () {
      api.rerender('76c4c90f', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "resources/js/components/report/AppReportData.vue"
export default component.exports