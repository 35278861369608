var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-color" },
    [
      _c(
        "v-app-bar",
        {
          attrs: {
            app: "",
            color: "rgba(0, 0, 0, 0.2)",
            dark: "",
            flat: "",
            "hide-on-scroll": "",
            height: "45",
          },
        },
        [
          _c(
            "v-toolbar-title",
            {
              staticClass: "font-weight-bold",
              on: {
                click: function ($event) {
                  return _vm.navigateToName("welcome")
                },
              },
            },
            [
              _c("div", { staticClass: "logo-titulo mx-3" }, [
                _vm._v(_vm._s(_vm.$App.title)),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                depressed: "",
                small: "",
                rounded: "",
                color: "success",
              },
              on: {
                click: function ($event) {
                  return _vm.navigateToName("login")
                },
              },
            },
            [
              _c("v-icon", { attrs: { left: "" } }, [_vm._v("mdi-account")]),
              _vm._v("\n        Login\n      "),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-main",
        { staticClass: "fill-height" },
        [
          _c(
            "transition",
            { attrs: { name: "fade", mode: "out-in" } },
            [_c("router-view")],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("app-message"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }