var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.dialog,
        scrollable: "",
        persistent: "",
        "max-width": "500px",
        transition: "dialog-transition",
        "content-class": "rounded-xl",
      },
    },
    [
      _c(
        "v-card",
        { staticClass: "rounded-xl" },
        [
          _c(
            "v-toolbar",
            { attrs: { dark: "", flat: "", color: "red" } },
            [
              _c(
                "v-btn",
                {
                  staticClass: "mx-1",
                  attrs: { icon: "", "x-small": "", dark: "" },
                  nativeOn: {
                    click: function ($event) {
                      return _vm.$emit("deleteCancel")
                    },
                  },
                },
                [_c("v-icon", [_vm._v("mdi-close-circle")])],
                1
              ),
              _vm._v(" "),
              _c("v-toolbar-title", [_vm._v("Atencion")]),
              _vm._v(" "),
              _c("v-spacer"),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-card-text", { staticClass: "pt-6" }, [
            _c(
              "p",
              { staticClass: "text-subtitle-1" },
              [
                _c("v-icon", {
                  staticClass: "mr-2",
                  attrs: { color: "amber", size: "30" },
                  domProps: { textContent: _vm._s(_vm.icon) },
                }),
                _vm._v(
                  "  \n              " + _vm._s(_vm.message) + "\n            "
                ),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "v-card-actions",
            [
              _c("v-spacer"),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "warning mx-1",
                                attrs: {
                                  fab: "",
                                  "x-small": "",
                                  loadin: _vm.loading,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("deleteCancel")
                                  },
                                },
                              },
                              on
                            ),
                            [_c("v-icon", [_vm._v("reply")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_vm._v(" "), _c("span", [_vm._v("Volver")])]
              ),
              _vm._v(" "),
              _c(
                "v-tooltip",
                {
                  attrs: { bottom: "" },
                  scopedSlots: _vm._u([
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                staticClass: "error mx-1",
                                attrs: {
                                  fab: "",
                                  small: "",
                                  loadin: _vm.loading,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.$emit("deleteItem")
                                  },
                                },
                              },
                              on
                            ),
                            [_c("v-icon", [_vm._v("delete")])],
                            1
                          ),
                        ]
                      },
                    },
                  ]),
                },
                [_vm._v(" "), _c("span", [_vm._v("Eliminar")])]
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }