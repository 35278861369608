var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      !_vm.removeCancel
        ? _c(
            "v-tooltip",
            {
              attrs: { top: "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              class: _vm.$App.theme.button.cancel + " ml-2",
                              attrs: {
                                fab: "",
                                "x-small": "",
                                loading: _vm.loading,
                              },
                              on: { click: _vm.cancel },
                            },
                            on
                          ),
                          [_c("v-icon", [_vm._v("reply")])],
                          1
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                3157547160
              ),
            },
            [_vm._v(" "), _c("span", [_vm._v("Cancelar")])]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.action == "upd"
        ? [
            _c(
              "v-tooltip",
              {
                attrs: { top: "" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "activator",
                      fn: function ({ on }) {
                        return [
                          _c(
                            "v-btn",
                            _vm._g(
                              {
                                class: _vm.$App.theme.button.update,
                                attrs: {
                                  fab: "",
                                  small: "",
                                  disabled: !_vm.valid,
                                  loading: _vm.loading,
                                },
                                on: { click: _vm.update },
                              },
                              on
                            ),
                            [_c("v-icon", [_vm._v("edit")])],
                            1
                          ),
                        ]
                      },
                    },
                  ],
                  null,
                  false,
                  1266197447
                ),
              },
              [_vm._v(" "), _c("span", [_vm._v("Editar")])]
            ),
          ]
        : [
            _c(
              "v-tooltip",
              {
                attrs: { top: "" },
                scopedSlots: _vm._u([
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          "v-btn",
                          _vm._g(
                            {
                              attrs: {
                                fab: "",
                                small: "",
                                disabled: !_vm.valid,
                                loading: _vm.loading,
                                color: _vm.$App.theme.button.insert,
                              },
                              on: { click: _vm.store },
                            },
                            on
                          ),
                          [_c("v-icon", [_vm._v("save_alt")])],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              },
              [_vm._v(" "), _c("span", [_vm._v("Guardar")])]
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }