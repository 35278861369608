var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "main-color",
      attrs: { loading: _vm.loading, flat: "", dark: "" },
    },
    [
      _c(
        "v-card-title",
        { staticClass: "subtitle-2" },
        [_c("v-row", [_c("v-col", [_vm._v("Reporte de Facturación")])], 1)],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        { staticClass: "custom-scroll row" },
        [
          _c(
            "v-col",
            { attrs: { cols: "auto", md: "2" } },
            [
              _c(
                "v-form",
                {
                  ref: "form",
                  attrs: { "lazy-validation": "" },
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-select", {
                        attrs: {
                          dense: "",
                          outlined: "",
                          filled: "",
                          label: "Tipo Reporte",
                          items: _vm.reports,
                          rules: [_vm.rules.required],
                          "hide-details": "",
                          "return-object": "",
                        },
                        on: { change: _vm.changeReport },
                        model: {
                          value: _vm.form.reportType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "reportType", $$v)
                          },
                          expression: "form.reportType",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.hidden
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c("PresenceCalendar", {
                            on: {
                              onUpdateDate: function ($event) {
                                return _vm.setDay($event)
                              },
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.hidden
                    ? _c("v-col", { attrs: { cols: "12" } }, [
                        _vm._v(" Mes Principal "),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.hidden
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "menu",
                              attrs: {
                                "close-on-content-click": false,
                                "return-value": _vm.form.date,
                                transition: "scale-transition",
                                "offset-y": "",
                                width: "180px",
                                height: "100px",
                                "min-width": "auto",
                              },
                              on: {
                                "update:returnValue": function ($event) {
                                  return _vm.$set(_vm.form, "date", $event)
                                },
                                "update:return-value": function ($event) {
                                  return _vm.$set(_vm.form, "date", $event)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  label: "Mes",
                                                  "prepend-icon":
                                                    "mdi-calendar",
                                                  rules: [_vm.rules.required],
                                                  readonly: "",
                                                  dense: "",
                                                  outlined: "",
                                                  filled: "",
                                                },
                                                model: {
                                                  value: _vm.form.date,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "date",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.date",
                                                },
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1736312839
                              ),
                              model: {
                                value: _vm.menu,
                                callback: function ($$v) {
                                  _vm.menu = $$v
                                },
                                expression: "menu",
                              },
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-date-picker",
                                {
                                  attrs: {
                                    type: "month",
                                    "no-title": "",
                                    scrollable: "",
                                  },
                                  model: {
                                    value: _vm.form.date,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "date", $$v)
                                    },
                                    expression: "form.date",
                                  },
                                },
                                [
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        text: "",
                                        small: "",
                                        color: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.menu = false
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Cancel\n                        "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        text: "",
                                        small: "",
                                        color: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.menu.save(
                                            _vm.form.date
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            OK\n                        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.hidden
                    ? _c("v-col", { attrs: { cols: "12" } }, [
                        _vm._v(" comparar con Mes Anterior"),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  !_vm.hidden
                    ? _c(
                        "v-col",
                        { attrs: { cols: "12" } },
                        [
                          _c(
                            "v-menu",
                            {
                              ref: "menu2",
                              attrs: {
                                "close-on-content-click": false,
                                "return-value": _vm.form.date2,
                                transition: "scale-transition",
                                "offset-y": "",
                                width: "180px",
                                height: "100px",
                                "min-width": "auto",
                              },
                              on: {
                                "update:returnValue": function ($event) {
                                  return _vm.$set(_vm.form, "date2", $event)
                                },
                                "update:return-value": function ($event) {
                                  return _vm.$set(_vm.form, "date2", $event)
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "activator",
                                    fn: function ({ on, attrs }) {
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                attrs: {
                                                  label: "Mes",
                                                  "prepend-icon":
                                                    "mdi-calendar",
                                                  readonly: "",
                                                  dense: "",
                                                  outlined: "",
                                                  filled: "",
                                                },
                                                model: {
                                                  value: _vm.form.date2,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.form,
                                                      "date2",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "form.date2",
                                                },
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3208561698
                              ),
                              model: {
                                value: _vm.menu2,
                                callback: function ($$v) {
                                  _vm.menu2 = $$v
                                },
                                expression: "menu2",
                              },
                            },
                            [
                              _vm._v(" "),
                              _c(
                                "v-date-picker",
                                {
                                  attrs: {
                                    type: "month",
                                    "no-title": "",
                                    scrollable: "",
                                  },
                                  model: {
                                    value: _vm.form.date2,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "date2", $$v)
                                    },
                                    expression: "form.date2",
                                  },
                                },
                                [
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        text: "",
                                        small: "",
                                        color: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.menu2 = false
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            Cancel\n                        "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        text: "",
                                        small: "",
                                        color: "primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$refs.menu2.save(
                                            _vm.form.date2
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                            OK\n                        "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { color: "info" },
                          on: {
                            click: function ($event) {
                              return _vm.getReport()
                            },
                          },
                        },
                        [
                          _c("v-icon", { attrs: { left: "" } }, [
                            _vm._v("mdi-clipboard-text-outline"),
                          ]),
                          _vm._v(" Ver"),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-col",
            { attrs: { cols: "auto", md: "10" } },
            [
              _c("v-text-field", {
                attrs: {
                  "append-icon": "search",
                  label: "Buscar",
                  "hide-details": "",
                  clearable: "",
                  dense: "",
                },
                model: {
                  value: _vm.search,
                  callback: function ($$v) {
                    _vm.search = $$v
                  },
                  expression: "search",
                },
              }),
              _vm._v(" "),
              _vm.items.length > 0
                ? _c("v-data-table", {
                    attrs: {
                      headers: _vm.headers,
                      items: _vm.items,
                      search: _vm.search,
                      "item-key": "id",
                      loading: _vm.loading,
                      "items-per-page": _vm.size,
                      "sort-by": _vm.sortBy,
                      "sort-desc": _vm.sortDesc,
                      width: "100%",
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "item",
                          fn: function ({ item }) {
                            return [
                              _c(
                                "tr",
                                _vm._l(_vm.headers, function (header, index) {
                                  return _c(
                                    "td",
                                    { key: index, staticClass: "text-xs-left" },
                                    [
                                      _vm.form.reportType.value ==
                                      "agency-totals"
                                        ? _c("div", [
                                            _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  item[header.value]?.split(
                                                    "&"
                                                  )[0]
                                                )
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            item[header.value]?.split("&")[1]
                                              ? _c(
                                                  "span",
                                                  { staticClass: "red--text" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        item[
                                                          header.value
                                                        ]?.split("&")[1]
                                                      )
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        : _vm._e(),
                                      _vm._v(" "),
                                      _vm.form.reportType.value !=
                                      "agency-totals"
                                        ? _c("div", [
                                            _c(
                                              "span",
                                              {
                                                class:
                                                  header.value === "rate" &&
                                                  item[header.value] <= 95
                                                    ? "red--text"
                                                    : "",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                                    " +
                                                    _vm._s(item[header.value]) +
                                                    "\n                                "
                                                ),
                                              ]
                                            ),
                                          ])
                                        : _vm._e(),
                                    ]
                                  )
                                }),
                                0
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      1263365412
                    ),
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.$App.debug ? _c("pre", [_vm._v(_vm._s(_vm.$data))]) : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }