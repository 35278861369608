var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    { staticClass: "back" },
    [
      _c(
        "v-content",
        [
          _c(
            "v-container",
            { attrs: { fluid: "", "fill-height": "" } },
            [
              _c(
                "v-layout",
                { attrs: { "align-center": "", "justify-center": "" } },
                [
                  _c(
                    "v-flex",
                    { attrs: { xs12: "", sm6: "" } },
                    [
                      _c(
                        "v-form",
                        {
                          ref: "form",
                          attrs: { "lazy-validation": "" },
                          model: {
                            value: _vm.valid,
                            callback: function ($$v) {
                              _vm.valid = $$v
                            },
                            expression: "valid",
                          },
                        },
                        [
                          _c(
                            "v-card",
                            { staticClass: "elevation-12" },
                            [
                              _c(
                                "v-card-text",
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      "prepend-icon": "person",
                                      label: "Usuario",
                                      type: "text",
                                      rules: _vm.rules.required,
                                    },
                                    model: {
                                      value: _vm.form.nb_usuario,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "nb_usuario", $$v)
                                      },
                                      expression: "form.nb_usuario",
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c("v-text-field", {
                                    attrs: {
                                      "prepend-icon": "lock",
                                      "append-icon": _vm.mostrar
                                        ? "visibility_off"
                                        : "visibility",
                                      type: _vm.mostrar ? "text" : "password",
                                      label: "Contraseña",
                                      rules: _vm.rules.password,
                                    },
                                    on: {
                                      "click:append": function ($event) {
                                        _vm.mostrar = !_vm.mostrar
                                      },
                                    },
                                    model: {
                                      value: _vm.form.password,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "password", $$v)
                                      },
                                      expression: "form.password",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-card-actions",
                                [
                                  _c("v-spacer"),
                                  _vm._v(" "),
                                  _c(
                                    "v-btn",
                                    {
                                      attrs: {
                                        dark: "",
                                        color: "red",
                                        loading: _vm.loading,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.login()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                                        Ingresar \n                                    "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c("v-spacer"),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("app-message"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }