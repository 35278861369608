var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { attrs: { flat: "", loading: _vm.loading } },
    [
      _c(
        "v-card-text",
        { staticClass: "pt-4" },
        [
          _c(
            "v-form",
            {
              ref: "form",
              model: {
                value: _vm.valid,
                callback: function ($$v) {
                  _vm.valid = $$v
                },
                expression: "valid",
              },
            },
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          color: "cyan darken-3",
                          "prepend-icon": "mdi-email",
                          label: "Correo Actual",
                          type: "text",
                          readonly: "",
                          dense: "",
                        },
                        model: {
                          value: _vm.form.tx_email,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "tx_email", $$v)
                          },
                          expression: "form.tx_email",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "12" } },
                    [
                      _c("v-text-field", {
                        attrs: {
                          color: "cyan darken-3",
                          "prepend-icon": "mdi-email-edit",
                          label: "Nuevo Correo",
                          type: "text",
                          rules: _vm.rules.email,
                          dense: "",
                        },
                        model: {
                          value: _vm.form.tx_new_email,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "tx_new_email", $$v)
                          },
                          expression: "form.tx_new_email",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-actions",
        [
          _c("v-spacer"),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                fab: "",
                small: "",
                dark: "",
                loading: _vm.loading,
                color: _vm.$App.theme.button.cancel,
              },
              on: {
                click: function ($event) {
                  return _vm.cancel()
                },
              },
            },
            [_c("v-icon", [_vm._v("mdi-restore")])],
            1
          ),
          _vm._v(" "),
          _c(
            "v-btn",
            {
              attrs: {
                fab: "",
                small: "",
                dark: "",
                disabled: !_vm.valid,
                loading: _vm.loading,
                color: _vm.$App.theme.button.insert,
              },
              on: {
                click: function ($event) {
                  return _vm.update()
                },
              },
            },
            [_c("v-icon", [_vm._v("save_alt")])],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }