var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "rounded-lg",
      attrs: { dark: "", color: "rgba(0,0,0,0.4)" },
    },
    [
      _c(
        "v-card-title",
        { attrs: { "primary-title": "" } },
        [
          _c(
            "v-tabs",
            {
              attrs: { "background-color": "transparent" },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            [
              _c("v-tab", [_vm._v("Reportes")]),
              _vm._v(" "),
              _c("v-tab", [_vm._v("Configuraciones")]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-tabs-items",
        {
          staticClass: "transparent",
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "v-tab-item",
            [
              _c(
                "v-card",
                {
                  staticClass: "rounded-lg",
                  attrs: { dark: "", color: "rgba(0,0,0,0)" },
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        _vm._l(_vm.reports, function (report, index) {
                          return _c(
                            "v-col",
                            { key: index, attrs: { cols: "4" } },
                            [
                              _c(
                                "v-card",
                                {
                                  directives: [
                                    { name: "ripple", rawName: "v-ripple" },
                                  ],
                                  attrs: { color: report.color },
                                  on: {
                                    click: function ($event) {
                                      return _vm.navigateToName(report.url)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { size: "34" } },
                                                [_vm._v(_vm._s(report.icon))]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-col", { staticClass: "pt-4" }, [
                                            _vm._v(_vm._s(report.title)),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-tab-item",
            [
              _c(
                "v-card",
                {
                  staticClass: "rounded-lg",
                  attrs: { dark: "", color: "rgba(0,0,0,0)" },
                },
                [
                  _c(
                    "v-card-text",
                    [
                      _c(
                        "v-row",
                        { attrs: { dense: "" } },
                        _vm._l(_vm.configs, function (config, index) {
                          return _c(
                            "v-col",
                            { key: index, attrs: { cols: "4" } },
                            [
                              _c(
                                "v-card",
                                {
                                  directives: [
                                    { name: "ripple", rawName: "v-ripple" },
                                  ],
                                  attrs: { color: config.color },
                                  on: {
                                    click: function ($event) {
                                      return _vm.navigateToName(config.url)
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "v-card-text",
                                    [
                                      _c(
                                        "v-row",
                                        { attrs: { dense: "" } },
                                        [
                                          _c(
                                            "v-col",
                                            [
                                              _c(
                                                "v-icon",
                                                { attrs: { size: "34" } },
                                                [_vm._v(_vm._s(config.icon))]
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c("v-col", { staticClass: "pt-4" }, [
                                            _vm._v(_vm._s(config.title)),
                                          ]),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }