var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.profile
    ? _c(
        "v-card",
        {
          staticClass: "rounded-lg",
          attrs: { dark: "", color: "rgba(0,0,0,0.4)", "min-height": "91vh" },
        },
        [
          _c(
            "v-card-title",
            { attrs: { "primary-title": "" } },
            [
              _c(
                "v-row",
                { attrs: { dense: "" } },
                [
                  _c("v-col", [_vm._v(_vm._s(_vm.profile.name))]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        { attrs: { icon: "", color: "success" } },
                        [_c("v-icon", [_vm._v("mdi-dots-vertical")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-avatar",
                        {
                          staticClass: "elevation-2",
                          attrs: { color: "blue", size: "100" },
                        },
                        [_c("v-img", { attrs: { src: this.photo } })],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    [
                      _c(
                        "v-row",
                        { attrs: { "no-gutters": "" } },
                        [
                          _c(
                            "v-col",
                            { staticClass: "subtitle-2", attrs: { cols: "5" } },
                            [_vm._v("Edad")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "font-weight-light",
                              attrs: { cols: "7" },
                            },
                            [_vm._v(_vm._s(_vm.profile.age))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "subtitle-2", attrs: { cols: "5" } },
                            [_vm._v("Cumpleaños")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "font-weight-light",
                              attrs: { cols: "7" },
                            },
                            [_vm._v(_vm._s(_vm.profile.birthday))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "subtitle-2", attrs: { cols: "5" } },
                            [_vm._v("Ciudad")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "font-weight-light",
                              attrs: { cols: "7" },
                            },
                            [_vm._v(_vm._s(_vm.profile.city))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "subtitle-2", attrs: { cols: "5" } },
                            [_vm._v("País")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "font-weight-light",
                              attrs: { cols: "7" },
                            },
                            [_vm._v(_vm._s(_vm.profile.country))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "subtitle-2", attrs: { cols: "5" } },
                            [_vm._v("Género")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "font-weight-light",
                              attrs: { cols: "7" },
                            },
                            [_vm._v(_vm._s(_vm.profile.gender))]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            { staticClass: "subtitle-2", attrs: { cols: "5" } },
                            [_vm._v("Amolatina ID")]
                          ),
                          _vm._v(" "),
                          _c(
                            "v-col",
                            {
                              staticClass: "font-weight-light",
                              attrs: { cols: "7" },
                            },
                            [_vm._v(_vm._s(_vm.profile.amolatina_id))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    { staticClass: "subtitle-2", attrs: { cols: "5" } },
                    [_vm._v("Comentarios")]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c(
                    "v-col",
                    {
                      staticClass: "font-weight-light pre-line",
                      attrs: { cols: "7" },
                    },
                    [_vm._v(_vm._s(_vm.profile.comments))]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "v-row",
                [
                  _c("v-textarea", {
                    attrs: {
                      variant: "filled",
                      label: "Comentarios",
                      "auto-grow": "",
                    },
                    model: {
                      value: _vm.annotations,
                      callback: function ($$v) {
                        _vm.annotations = $$v
                      },
                      expression: "annotations",
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "v-btn",
                    {
                      attrs: {
                        density: "compact",
                        disabled: this.annotations == "",
                      },
                      on: { click: _vm.updateComments },
                    },
                    [
                      _c(
                        "v-icon",
                        { attrs: { dark: "", size: "32", color: "green" } },
                        [_vm._v("mdi-plus-circle-outline")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }