<template>
  <v-card dark class="rounded-lg" color="rgba(0,0,0,0.4)">
    <v-card-title primary-title>
      DashBoard
    </v-card-title>
    <v-card-text>
      <v-row dense>
        <v-col>
          <v-card color="blue" v-ripple @click="navigateToName('client')">
            <v-card-text>
              <v-row dense>
                <v-col>
                  <v-icon size="34">mdi-account-cash</v-icon>
                </v-col>
                <v-col class="pt-4">Clientes</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-card color="purple" v-ripple @click="navigateToName('comission')">
            <v-card-text>
              <v-row dense>
                <v-col>
                  <v-icon size="34">mdi-sack</v-icon>
                </v-col>
                <v-col class="pt-4">Facturas</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>

        <v-col>
          <v-card color="red" v-ripple @click="navigateToName('user-presence')">
            <v-card-text>
              <v-row dense>
                <v-col>
                  <v-icon size="34">mdi-face-agent</v-icon>
                </v-col>
                <v-col class="pt-4">Progreso</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col cols="4">
          <v-card color="pink" v-ripple @click="navigateToName('online')">
            <v-card-text>
              <v-row dense>
                <v-col>
                  <v-icon size="34">mdi-eye-outline</v-icon>
                </v-col>
                <v-col class="pt-4">Online</v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
     
    </v-card-text>
  </v-card>
</template>

<script>
export default {
 
}
</script>

<style>

</style>