<template>
  <v-card dark class="rounded-lg " color="rgba(0,0,0,0.4)">
    <v-subheader>
        <v-row class="title">
            <v-col cols="auto" >
              <v-icon left>mdi-account-multiple-outline</v-icon>  Mis Contactos - Chats Recientes
            </v-col>
            <v-col cols="auto" > <v-btn icon :loading="loading"><v-icon  small @click="list()">mdi-reload</v-icon> </v-btn></v-col>
        </v-row>
        

    </v-subheader>
    <v-card-text class="pt-0 accounts-container custom-scroll">
      
      <v-list subheader two-line dense color="rgba(0,0,0,0.4)" class="rounded-lg"> 

        <v-list-item v-for="client in clients" :key="client['event-id']" :value="client" :color="client.fresh > 0 ? 'red':'blue'" >
          <v-list-item-avatar color="blue" size="60">
            <v-img :src="`https://api.amolatina.com/users/${client['user-id']}/photos/${client['user-details'].thumbnail}.190x180.thumb-fd`" ></v-img> 
          </v-list-item-avatar>
          <v-list-item-content>
              <v-list-item-title>
              <v-row no-gutters>
                <v-col>
                  {{client['user-details'].name}}
                  <v-badge v-if="client.fresh > 0" :content="client.fresh" inline color="error">
                    <v-icon :icon="client.type == 'message' ? 'mdi-message-bulleted' : 'mdi-email-send-outline'" :size="22"></v-icon>
                  </v-badge>
                </v-col>
              </v-row>
              <v-row no-gutters>
                 <v-col :class="client.fresh > 0 ? '' : 'caption'">{{client.payload.text}}</v-col>
              </v-row>
              
              </v-list-item-title>
              <v-list-item-subtitle class="pt-2">
                <v-row dense>

                  <v-col>ID: {{client['user-id']}}</v-col>

                  <router-link :to="{ name: 'client-chat', params: { client_id: client['user-id'], profile_id: profile_id  }}" target="_blank">
                    <v-col cols="auto" v-if="client.type == 'message'">
                      <list-simple-icon icon="mdi-message-bulleted" label="Email" color="blue darken-3" :size="22"></list-simple-icon>
                    </v-col>

                    <v-col cols="auto" v-if="client.type == 'letter'">
                      <list-simple-icon icon="mdi-email-send-outline" label="Email" color="blue darken-3" :size="22"></list-simple-icon>
                    </v-col>
                  </router-link>

                </v-row>
              
              </v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-icon>
                
            </v-list-item-icon>
        </v-list-item>

      </v-list>
      
    </v-card-text>

  </v-card>
</template>

<script>
import AppData from '@mixins/AppData';
export default {

  mixins: [AppData],

  created() {
    this.profile_id = this.$route.params.profile_id
    this.list()
    this.reload()
  },

  data: () => ({
    clients: [],
    isReload: null,
    profile_id: 0,
  }),

  methods: {

    list() {
      this.getResource(`profile/${this.$route.params.profile_id}/contact/events`).then( data => {
        this.clients = data
      })
    },

    reload()
    {
      clearInterval(this.isReload)
      this.isReload = setInterval( () => {
          this.list();
      }, 30000 )
    },

/* 
    setProfile(profile)
    {
      this.$store.commit('setProfile', profile)
    },

    setPresence()
    {
      if(this.myProfilesStarted.length > 0)
      {
        this.stopPresence();
      } else  {
        this.startPresence();
      } 

    },

    startPresence()
    {
      if(this.profilesAvailable.length < 1 )
      {
        this.showError('No existen perfiles Disponibles')
        this.list()
        return
      }
      this.form.profiles_id = this.profilesAvailable 
      this.storeResource('userPresence', this.form)
      .then(data => {
        this.showMessage(data.msj)
      })
      .finally( () => 
      {
        this.list()
      });
    },

    stopPresence()
    {
      if(this.myProfilesStarted.length < 1 )
      {
        this.showError('No posees perfiles iniciados')
        this.list()
        return
      }
      this.updateResource('userPresence/stop', this.form)
      .then(data => {
        this.showMessage(data.msj)
      })
      .finally( () => 
      {
        this.list()
      });
    },

    setActives()
    {
      this.profilesStarted   = []
      this.profilesAvailable = []
      this.myProfilesStarted = []
      
      this.profiles.forEach(profile => {
        if(profile.presence){
          this.profilesStarted.push(profile.id)
          if(profile.presence.user.id == this.userId)
          {
            this.myProfilesStarted.push(profile.id)
          }
        }else{
          this.profilesAvailable.push(profile.id)
        }
      }, this);
    },

    showDetails(profile)
    {
      this.profile = profile
      this.dialogDetail = true
    },

    closeDialog()
    {
      this.profile = null
      this.dialogDetail = false
    },

    login(profile_id) {
      
    } */

  }
}
</script>

<style>

</style>