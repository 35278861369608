var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-toolbar",
    {
      attrs: {
        color: _vm.backgroundColor,
        dark: _vm.dark,
        flat: "",
        dense: _vm.dense,
      },
    },
    [
      _c("v-toolbar-title", {
        class: _vm.textColor,
        domProps: { textContent: _vm._s(_vm.title) },
      }),
      _vm._v(" "),
      _c("v-spacer"),
      _vm._v(" "),
      _c(
        "v-btn",
        {
          staticClass: "mr-2",
          class: _vm.textColor,
          attrs: { icon: "", "x-small": "" },
          on: {
            click: function ($event) {
              return _vm.closeModal()
            },
          },
        },
        [_c("v-icon", [_vm._v("mdi-close-circle")])],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }