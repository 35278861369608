var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    {
      staticClass: "rounded-lg",
      attrs: { dark: "", color: "rgba(0,0,0,0.4)" },
    },
    [
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-list-item",
                    [
                      _c(
                        "v-list-item-avatar",
                        { attrs: { size: "70" } },
                        [
                          _c(
                            "v-icon",
                            { attrs: { color: "grey", size: "70" } },
                            [_vm._v("mdi-account-circle")]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-content",
                        [
                          _c(
                            "v-list-item-subtitle",
                            { staticClass: "title-section" },
                            [_vm._v(" " + _vm._s(_vm.user.name))]
                          ),
                          _vm._v(" "),
                          _c("v-list-item-title", [
                            _vm._v(_vm._s(_vm.role.name)),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-list-item-icon",
                        [
                          _c("item-menu", {
                            attrs: {
                              menus: _vm.menus,
                              iconColor: "white",
                              btnColor: "transparent",
                              item: { id: 1 },
                            },
                            on: {
                              onItemMenu: function ($event) {
                                return _vm.onItemMenu($event)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }