var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    { staticClass: "main", attrs: { flat: "", dark: "", height: "90vh" } },
    [
      _c(
        "v-card-title",
        { staticClass: "px-3 py-1 subtitle-1" },
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c("v-col", [
                _vm._v("Nuevos Clientes (" + _vm._s(_vm.type) + ") "),
              ]),
              _vm._v(" "),
              _c(
                "v-col",
                { attrs: { cols: "auto" } },
                [
                  _c(
                    "v-btn",
                    {
                      attrs: { icon: "", "x-small": "", dark: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("closeDialog")
                        },
                      },
                    },
                    [_c("v-icon", [_vm._v("mdi-close-circle")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "v-card-text",
        [
          _c(
            "v-row",
            { attrs: { dense: "" } },
            [
              _c(
                "v-col",
                [
                  _c("v-text-field", {
                    attrs: {
                      "append-icon": "search",
                      label: "Buscar",
                      "hide-details": "",
                      clearable: "",
                      dense: "",
                    },
                    model: {
                      value: _vm.search,
                      callback: function ($$v) {
                        _vm.search = $$v
                      },
                      expression: "search",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("v-data-table", {
            attrs: {
              headers: _vm.headers,
              items: _vm.items,
              search: _vm.search,
              "item-key": "id",
              loading: _vm.loading,
              "sort-by": "",
              dense: "",
            },
            scopedSlots: _vm._u([
              {
                key: "item",
                fn: function ({ item }) {
                  return [
                    _c("tr", [
                      _c(
                        "td",
                        [
                          _c(
                            "v-row",
                            {
                              attrs: {
                                "no-gutters": "",
                                justify: "center",
                                align: "center",
                              },
                            },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "auto" } },
                                [
                                  _c(
                                    "v-badge",
                                    {
                                      attrs: {
                                        "offset-x": "20",
                                        "offset-y": "20",
                                        overlap: "",
                                        color: "amber",
                                        value: item.crown > 0,
                                        icon: "mdi-crown",
                                      },
                                    },
                                    [
                                      _c(
                                        "v-avatar",
                                        {
                                          staticClass: "mr-3",
                                          attrs: { color: "blue", size: 30 },
                                        },
                                        [
                                          _c("v-img", {
                                            attrs: {
                                              src: `/storage/photo/client/${
                                                item.photo || "nophoto"
                                              }.jpg`,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                [
                                  _c(
                                    "v-row",
                                    { attrs: { "no-gutters": "" } },
                                    [
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "caption",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(item.name) +
                                              " " +
                                              _vm._s(
                                                item.age
                                                  ? `(${item.age})`
                                                  : null
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "v-col",
                                        {
                                          staticClass: "caption",
                                          attrs: { cols: "12" },
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(item.amolatina_id) + " "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          _c(
                            "v-avatar",
                            {
                              staticClass: "elevation-3",
                              attrs: { color: "blue", size: 30 },
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  src: `/storage/photo/profile/${
                                    item.comission_contacted.profile
                                      ? item.comission_contacted.profile.photo
                                      : "nophoto" || "nophoto"
                                  }.jpg`,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(
                            "\n                          " +
                              _vm._s(
                                item.comission_contacted.profile
                                  ? item.comission_contacted.profile.name
                                  : null
                              ) +
                              "\n                        "
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        [
                          item.comission_contacted.has_service
                            ? _c("list-simple-icon", {
                                attrs: {
                                  size: 24,
                                  icon: item.comission_contacted.has_service
                                    .icon,
                                  label:
                                    item.comission_contacted.has_service.name,
                                  color:
                                    item.comission_contacted.has_service.color,
                                },
                              })
                            : _c("list-simple-icon", {
                                attrs: {
                                  size: 24,
                                  icon: "mdi-comment-question",
                                  label: _vm.comission.service,
                                  color: "amber",
                                },
                              }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("td", [_vm._v(_vm._s(_vm.formatNumber(item.points)))]),
                      _vm._v(" "),
                      _c("td", [
                        _vm._v(_vm._s(_vm.formatDateString(item.contacted_at))),
                      ]),
                    ]),
                  ]
                },
              },
            ]),
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }