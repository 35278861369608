var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-form",
    {
      ref: "form",
      attrs: { "lazy-validation": "" },
      model: {
        value: _vm.valid,
        callback: function ($$v) {
          _vm.valid = $$v
        },
        expression: "valid",
      },
    },
    [
      _c(
        "v-card",
        {
          staticClass: "main-color",
          attrs: { loading: _vm.loading, flat: "", dark: "" },
        },
        [
          _c(
            "v-card-title",
            { staticClass: "subtitle-2" },
            [
              _c(
                "v-row",
                [
                  _c("v-col", [_vm._v("Perfil")]),
                  _vm._v(" "),
                  _c(
                    "v-col",
                    { attrs: { cols: "auto" } },
                    [
                      _c(
                        "v-btn",
                        {
                          attrs: { icon: "", "x-small": "", dark: "" },
                          on: {
                            click: function ($event) {
                              return _vm.cancel()
                            },
                          },
                        },
                        [_c("v-icon", [_vm._v("mdi-close-circle")])],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "v-card-text",
            [
              _c(
                "v-row",
                { staticClass: "mt-1", attrs: { dense: "" } },
                [
                  _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        [
                          _c(
                            "v-row",
                            { attrs: { dense: "" } },
                            [
                              _c(
                                "v-col",
                                { attrs: { cols: "12" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      label: "Perfil",
                                      dense: "",
                                      filled: "",
                                      outlined: "",
                                      "hide-details": "",
                                      readonly: "",
                                      "prepend-icon": "mdi-account",
                                    },
                                    model: {
                                      value: _vm.form.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.form, "name", $$v)
                                      },
                                      expression: "form.name",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "4" } },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      dense: "",
                                      outlined: "",
                                      filled: "",
                                      label: "Tipo Comisión",
                                      items: _vm.arrangementTypes,
                                      rules: [
                                        _vm.rules.required,
                                        _vm.rules.select,
                                      ],
                                      "item-value": "id",
                                      "item-text": "symbol",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value:
                                        _vm.form.active_profile_arrangement
                                          .arrangement_type_id,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.active_profile_arrangement,
                                          "arrangement_type_id",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.active_profile_arrangement.arrangement_type_id",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "v-col",
                                { attrs: { cols: "8" } },
                                [
                                  _c("v-text-field", {
                                    attrs: {
                                      rules: [
                                        _vm.rules.required,
                                        _vm.rules.digits,
                                      ],
                                      label: "Comisión",
                                      dense: "",
                                      filled: "",
                                      outlined: "",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value:
                                        _vm.form.active_profile_arrangement
                                          .value,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.form.active_profile_arrangement,
                                          "value",
                                          $$v
                                        )
                                      },
                                      expression:
                                        "form.active_profile_arrangement.value",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "v-col",
                        {
                          staticClass: "mt-4 text-center",
                          attrs: { cols: "auto" },
                        },
                        [
                          _c(
                            "v-avatar",
                            {
                              staticClass: "ml-1 rounded-circle",
                              attrs: {
                                width: "100",
                                height: "100",
                                tile: "",
                                color: "blue",
                              },
                            },
                            [
                              _c("v-img", {
                                attrs: {
                                  src: `/storage/photo/profile/${
                                    _vm.form.photo || "nophoto"
                                  }.jpg`,
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.form.active_profile_arrangement.id != null
                            ? _c("form-buttons", {
                                attrs: {
                                  action: "upd",
                                  valid: _vm.valid,
                                  loading: _vm.loading,
                                },
                                on: {
                                  update: function ($event) {
                                    return _vm.update()
                                  },
                                  cancel: function ($event) {
                                    return _vm.cancel()
                                  },
                                },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.form.active_profile_arrangement.id == null
                            ? _c("form-buttons", {
                                attrs: {
                                  action: "ins",
                                  valid: _vm.valid,
                                  loading: _vm.loading,
                                },
                                on: {
                                  store: function ($event) {
                                    return _vm.store()
                                  },
                                  cancel: function ($event) {
                                    return _vm.cancel()
                                  },
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.$App.debug ? _c("pre", [_vm._v(_vm._s(_vm.$data))]) : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }