var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-card",
    [
      _c(
        "v-card-title",
        { staticClass: "pa-0" },
        [
          _c("app-simple-toolbar", {
            attrs: {
              title: "Google Meet",
              backgroundColor: "red",
              dense: "",
              dark: "",
            },
            on: {
              closeModal: function ($event) {
                return _vm.$emit("closeDialog")
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c("v-card-text", { staticClass: "px-0" }, [
        _c("div", { staticClass: "enlace-container" }, [
          _c("iframe", {
            staticClass: "page-iframe",
            attrs: {
              src: "https://meet.google.com/",
              frameborder: "0",
              width: "100%",
              allowfullscreen: "",
              target: "_parent",
            },
            on: {
              load: function ($event) {
                _vm.loading = false
              },
            },
          }),
        ]),
      ]),
      _vm._v(" "),
      _c(
        "v-overlay",
        {
          attrs: {
            absolute: "",
            opacity: 0.3,
            value: _vm.loading,
            "z-index": 10,
          },
        },
        [
          _c(
            "v-icon",
            { staticClass: "mdi-spin", attrs: { size: "40", color: "white" } },
            [_vm._v("mdi-loading")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }