var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: { bottom: "", left: "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "v-btn",
                _vm._g(
                  {
                    attrs: {
                      "x-small": _vm.small,
                      small: !_vm.small,
                      color: _vm.btnColor,
                      fab: "",
                      depressed: "",
                    },
                  },
                  on
                ),
                [
                  _c("v-icon", { attrs: { color: _vm.iconColor } }, [
                    _vm._v("mdi-printer"),
                  ]),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        "v-list",
        { attrs: { dense: "", nav: "" } },
        _vm._l(_vm.reports, function (report, i) {
          return _c(
            "v-list-item",
            {
              key: i,
              attrs: { dense: "", tile: "" },
              on: {
                click: function ($event) {
                  return _vm.$emit("onReport", {
                    table: report.table,
                    title: report.title,
                  })
                },
              },
            },
            [
              _c(
                "v-list-item-action",
                [
                  _c(
                    "v-btn",
                    {
                      staticClass: "grey lighten-4",
                      attrs: { icon: "" },
                      on: {
                        click: function ($event) {
                          return _vm.$emit("onReport", {
                            table: report.table,
                            title: report.title,
                          })
                        },
                      },
                    },
                    [
                      _c("v-icon", { attrs: { color: "indigo" } }, [
                        _vm._v("mdi-printer"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("v-list-item-title", [
                _vm._v(
                  "\n                " + _vm._s(report.title) + "\n            "
                ),
              ]),
            ],
            1
          )
        }),
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }