var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-progress-circular",
    {
      attrs: {
        size: _vm.size,
        width: _vm.width,
        value: _vm.percent,
        color: _vm.timerColor,
        indeterminate: _vm.loading,
      },
    },
    [
      _c("div", [
        _c("span", { staticClass: "title-1" }, [
          _vm._v(_vm._s(_vm.prettyTime)),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }