var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "audio-container" },
    [
      _c("app-simple-toolbar", {
        attrs: {
          title: _vm.audio.name,
          backgroundColor: "primary",
          dense: "",
          dark: "",
        },
        on: {
          closeModal: function ($event) {
            return _vm.$emit("closeDialog")
          },
        },
      }),
      _vm._v(" "),
      _c(
        "v-alert",
        {
          staticClass: "rounded-xl mb-1",
          attrs: {
            color: "primary",
            value: true,
            prominent: "",
            icon: _vm.audioIcon,
            dark: "",
          },
        },
        [
          _c(
            "audio",
            {
              staticClass: "mt-2 mx-auto",
              attrs: { controls: "", autoplay: "" },
            },
            [
              _c("source", {
                attrs: { src: _vm.audio.src, type: "audio/mp3" },
              }),
              _vm._v(
                "\n            Tu navegador no soporta este formato de audio\n        "
              ),
            ]
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }